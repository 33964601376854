import React, { memo, useEffect } from "react";
import "./index.scss";
import { useGetProjects } from "../../hooks/projects";
// import HostSpot from "./HostSpot";
import { useLocation } from "react-router-dom";
// import Floor from "./Floor";
import TagHead from "../../components/TagHead";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getRelateInfo } from "../../actions/relate-information";
import RelateInfo from "../Location/RelateInfo";
import ProjectInfo from "./ProjectInfo";
import Footer from "../../components/Footer";
import ReactFullpage from "@fullpage/react-fullpage";
import ScrollToTop from "../../components/common/ScrollToTop";

import {
  REACT_FULLPAGE_RESPONSIVE_WIDTH,
  REACT_FULLPAGE_KEY,
} from "../../constants";
import { setPageInfo } from "../../actions/page";
import { PageType } from "../../enums/index";
import { useGetPageInfo } from "../../hooks/page";

const Appartment = (props) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [project, loading] = useGetProjects();
  const [page, pageLoading] = useGetPageInfo(PageType.Apartment);

  const dispatch = useDispatch();
  const windownWidth = window.innerWidth;

  if (dispatch) {
    dispatch(getRelateInfo("5,2,3"));
  }

  useEffect(() => {
    if (!pageLoading) {
      dispatch(setPageInfo(page));
    }
  }, [page, pageLoading]);

  const onLeave = (origin, destination, direction) => {
    const { item } = destination;
    const originItem = origin.item;
    originItem.classList.remove("show-info");
    item.classList.add("show-info");
  };
  return (
    <div className="apartment">
      <TagHead
        title={
          location.pathname.includes("apartment")
            ? t("header.info-apartment")
            : t("header.info-office")
        }
      />
      {windownWidth > REACT_FULLPAGE_RESPONSIVE_WIDTH ? (
        <ReactFullpage
          licenseKey={REACT_FULLPAGE_KEY}
          scrollingSpeed={500}
          lockAnchors={true}
          responsiveWidth={REACT_FULLPAGE_RESPONSIVE_WIDTH}
          scrollHorizontally={true}
          arrowNavigation={true} // use arrow keys
          navigation={false} // use dots navigatio
          touchNavigation={false}
          loopTop={false}
          scrollBar={false} // use the browser default scrollbar
          onLeave={onLeave}
          // afterLoad={afterLoad}
          render={({ state, fullpageApi }) => {
            return (
              <ReactFullpage.Wrapper>
                <ProjectInfo project={project} />
                <RelateInfo
                  customClass="location location-home"
                  showPhone={false}
                  showLine={true}
                />
              </ReactFullpage.Wrapper>
            );
          }}
        />
      ) : (
        <>
          <ScrollToTop />
          <ProjectInfo project={project} />
          <RelateInfo customClass="margin-common" />
        </>
      )}
      <Footer />
    </div>
  );
};

export default memo(Appartment);
