import requester from "../requester";
import { GET_HOME_VIDEO, GET_HOME_ART_BOUTIQUE, GET_HOME_TRADEMARK, GET_HOME_CULTURE, GET_HOME_SCALE } from "../../constants";

const Home = {
  getVideo: () => requester.get(GET_HOME_VIDEO),
  getArtBoutique: () => requester.get(GET_HOME_ART_BOUTIQUE),
  getTradeMark: () => requester.get(GET_HOME_TRADEMARK),
  getCulture: () => requester.get(GET_HOME_CULTURE),
  getScale: () => requester.get(GET_HOME_SCALE),
};

export default Home;
