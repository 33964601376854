import React, { useState } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useGetDesignCompare } from "../../hooks/design";

const BeerSlider = () => {
  const [data, loading] = useGetDesignCompare();
  const [valueSlide, setvalueSlide] = useState(50);
  const cxClassBeer = valueSlide !== 0 ? "beer-ready" : "";
  const changeSlide = (e) => {
    setvalueSlide(e.target.value);
  };
  const { t } = useTranslation();
  if (loading) return <div className="page-loading">{t("loading")}</div>;

  return (
    <div
      id="beer-slider"
      className={cx("beer-slider", cxClassBeer)}
      data-beer-label={t("design.bear_label1")}
    >
      <img src={data.image_real} alt="" />
      <div
        className={"beer-reveal"}
        style={{ width: `${valueSlide}%` }}
        data-beer-label={t("design.bear_label2")}
      >
        <img src={data.image_3d} alt="" />
      </div>
      <input
        type="range"
        className="beer-range"
        aria-valuemin={0}
        aria-valuemax={100}
        onChange={changeSlide}
        aria-valuenow={valueSlide}
        defaultValue={valueSlide}
        min={0}
        max={100}
      />
      <span className="beer-handle" style={{ left: `${valueSlide}%` }}></span>
    </div>
  );
};

export default BeerSlider;
