import React from "react";
import Sidebar from "./Menu";

const Layout = ({ children }) => {
  return (
    <div className="layout_app">
      <Sidebar />
      <div className="layout_app-content">{children}</div>
    </div>
  );
};

export default Layout;
