import { TYPE } from "../actions/amenity";

const defaultState = {
  indexAmenity: 0,
  data: {
    amenity: {},
  },
};

const amenity = (state = defaultState, action) => {
  switch (action.type) {
    case TYPE.SET_INDEX_AMENITY: {
      return { ...state, indexAmenity: action.payload };
    }
    case TYPE.SET_SIZE_AMENITY_LIST: {
      return { ...state, sizeList: action.payload };
    }
    case TYPE.SET_AMENITY_DATA: {
      return { ...state, data: action.payload };
    }
    default:
      return { ...state };
  }
};

export default amenity;
