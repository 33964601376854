import React from "react";
import star from "../../../assets/images/star.svg";
import { Col } from 'react-bootstrap'

const CardProduct = ({ product }) => {
  const { 
    // id,
    image, 
    // favorited,
     floor, info } = product;
  return (
    <Col xl={3} lg={4} md={6} sm={12}>
    <div className="card">
      <img className="card-img-top" src={image} alt="product_view" />
      <div className="card-body">
        <div className="card-body-info">
            <img src={star} alt="" />
          <div className="card-body-info-content">
            <p>{floor}</p>

            <ul>
              {info &&
                info.map((x, index) => (
                  <li key={index.toString()}>
                    <strong>{x.amout}</strong> {x.name}
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <div className="card-body-btn">
          <a href={"/compare/detail_product"} className="card-body-btn-view_detail">
            View detail
          </a>
        </div>
      </div>
    </div>
    </Col>
  );
};

export default CardProduct;
