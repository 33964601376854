import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
// import { TweenMax } from "gsap";
// import { SectionsContainer, Section } from "react-fullpage";
import { useTranslation } from "react-i18next";
import LocationOverview from "./LocationOverview";
import LocationIntroduce from "./LocationIntroduce";
import TagHead from "../../components/TagHead";
// import PhoneFixed from "../../components/PhoneFixed";
import { useDispatch } from "react-redux";
import { getRelateInfo } from "../../actions/relate-information";
import RelateInfo from "./RelateInfo";
import Footer from "../../components/Footer";
import { setPageInfo } from "../../actions/page";
import { PageType } from "../../enums/index";
import { useGetPageInfo } from "../../hooks/page";
import ScrollToTop from "../../components/common/ScrollToTop";
import ReactFullpage from "@fullpage/react-fullpage";
import {
  REACT_FULLPAGE_KEY,
  REACT_FULLPAGE_RESPONSIVE_WIDTH,
} from "../../constants";
import { translateVertical } from "../../utils/helper";

const Location = () => {
  const location = useLocation();
  let fullPageRef = useRef();
  const dispatch = useDispatch();
  const [page, pageLoading] = useGetPageInfo(PageType.Location);
  if (dispatch) {
    dispatch(
      getRelateInfo(location.pathname.includes("apartment") ? "3,4,5" : "11,12,9")
    );
  }
  useEffect(() => {
    if (!pageLoading) {
      dispatch(setPageInfo(page));
    }
  }, [page, pageLoading]);
  const { t } = useTranslation();
  const isDesktop = window.innerWidth > 1024;

  const onLeave = (origin, destination, direction) => {
    let { item } = destination;
    let originItem = origin.item;

    if (origin.index > 0 && originItem.classList.contains("changing")) {
      return false;
    }

    // Clear style of old section
    item.classList.add("changing");
    originItem.classList.add("show-text", "changing");
    originItem.style.removeProperty("z-index");
    originItem.classList.remove("show-info");
    item.classList.add("show-info");
    // Add style style for destination section
    item.style.zIndex = 10;
    if (direction == "down") {
      item.style.transform = `translate(0, -100%) translate3d(0px, 0px, 0px)`;
    }

    translateVertical(function (value) {
      let trans = direction === "down" ? 100 - value : -(100 - value);
      item.style.transform = `translate(0, ${trans}%) translate3d(0px, 0px, 0px)`;

      if (value === 100) {
        item.style.transform = "matrix(1, 0, 0, 1, 0, 0)";
        item.classList.remove("changing");
        originItem.classList.remove("show-text", "changing");
        item.classList.add("show-text");
        setTimeout(() => {
          originItem.style.transform =
            "translate(0%, 100%) matrix(1, 0, 0, 1, 0, 0)";
        }, 100);
      }
    });
    originItem.classList.remove("show-info");
    item.classList.add("show-info");

    // animate section
    // if(!item.classList.contains('animated')){
    //   let nodes = item.querySelectorAll('.animate');
    //   item.classList.add('animated');

    //   Array.from(nodes).forEach((node) => {
    //     let animate = node.getAttribute('data-animate');
    //     animate = animate ? animate.split(' ') : [];
    //     node.classList.add(...animate);
    //   })
    // }
  };

  const onAfterLoad = (origin, destination, direction) => {
    const { item } = destination;
    item.classList.add("show-info");
  };

  return (
    <div className="wrapper-component location">
      <ScrollToTop />
      <TagHead title={t("header.location")} />
      {isDesktop && (
        <div className="desktop-screen">
          <ReactFullpage
            licenseKey={REACT_FULLPAGE_KEY}
            scrollingSpeed={500}
            onLeave={onLeave}
            afterLoad={onAfterLoad}
            responsiveWidth={REACT_FULLPAGE_RESPONSIVE_WIDTH}
            render={({ state, fullpageApi }) => {
              fullPageRef.current = fullpageApi;
              return (
                <ReactFullpage.Wrapper>
                  <section className="section animated">
                    <LocationIntroduce />
                  </section>
                  <section className="section">
                    <LocationOverview />
                  </section>
                  <section className="section">
                    <RelateInfo
                      customClass="location location-home"
                      showPhone={false}
                      showLine={true}
                    />
                  </section>
                </ReactFullpage.Wrapper>
              );
            }}
          />
        </div>
      )}

      <div className="mobile-desktop">
        <LocationIntroduce />
        <LocationOverview />
        <RelateInfo />
        <Footer />
      </div>
    </div>
  );
};

export default Location;
