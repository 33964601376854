import { useState, useEffect } from "react";

export const useFetch = (actionCreator, param = null) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (actionCreator) {
      setLoading(true);
      actionCreator(param)
        .then((res) => {
          setData(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [actionCreator, param]);

  return [data, loading];
};
