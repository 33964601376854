import requester from "../requester";
import {
  GET_INTRODUCTION_APARTMENT,
  GET_INTRODUCTION_OFFICE,
} from "../../constants";

const Intro = {
  getIntroOffice: () => requester.get(GET_INTRODUCTION_OFFICE),
  getIntroApartment: () => requester.get(GET_INTRODUCTION_APARTMENT),
};

export default Intro;
