import React, { useState } from "react";
import { useGetDesignList } from "../hooks/design";

const Navigation = () => {
  const [isShowNav, setIsShowNav] = useState(false);
  const [designList] = useGetDesignList();
  return (
    <div id="myMenu" className="navigation d-flex flex-column align-items-end">
      {designList &&
        designList
          .filter((ele) => ele.id !== 2)
          .map((item, index) => (
            <a
              data-menuanchor={item.id}
              className={`navigation-anchor ${index == 0 ? "active" : ""}`}
              href={"#" + item.id}
              key={index}
            >
              <span>{item.name}</span>
            </a>
          ))}
    </div>
  );
};

export default Navigation;
