import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const ShowMore = (props) => {
  let { maxHeight } = props;
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  let style = {
    ...(maxHeight ? { maxHeight: `${maxHeight}px` } : {}),
  };

  const hanldeText = (e) => {
    setShow(!show);
  };

  return (
    <div
      className={`show-more ${show && "show-text"}`}
      style={{ ...(show ? {} : style) }}
    >
      {props.children}

      {maxHeight && (
        <span className="show-more-link" onClick={hanldeText}>
          {show ? (
            <span>
              {t("showmore.hide")} <span className="lnr lnr-chevron-up"></span>
            </span>
          ) : (
            <span>
              {t("showmore.show")}{" "}
              <span className="lnr lnr-chevron-down"></span>
            </span>
          )}
        </span>
      )}
    </div>
  );
};

export default ShowMore;
