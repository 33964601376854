import React, { useRef, useCallback, useEffect } from "react";
import HotSpotTop from "../../assets/images/hotspot_top_body.png";
import { useTranslation } from "react-i18next";
import withViewPort from "../../hoocs/withViewPort";
import { REACT_IPAD_RESPONSIVE_WIDTH } from "../../constants";
import { nl2ToBr, addListTagToParagraph } from "../../utils/helper";

const HotSpot = ({ data, container }) => {
  const { t } = useTranslation();
  const hotSpotRef = useRef();
  const windownWidth = window.innerWidth;

  const onClickHotSpot = (e) => {
    if (windownWidth > REACT_IPAD_RESPONSIVE_WIDTH) {
      return false;
    }

    let element = e.target;
    element.classList.add("show-info");
    document.querySelector(".backdrop").classList.add("show");
    document.getElementById("root").classList.add("popup-open");
  };

  const handleClick = useCallback(
    (e) => {
      const { target } = e;

      if (windownWidth > REACT_IPAD_RESPONSIVE_WIDTH) {
        return false;
      }

      if (hotSpotRef.current && !hotSpotRef.current.contains(target)) {
        hotSpotRef.current.classList.remove("show-info");
      }
    },
    [hotSpotRef, data]
  );

  const handleClickBackdrop = (e) => {
    document.querySelector(".backdrop").classList.remove("show");
    document.getElementById("root").classList.remove("popup-open");
  };

  useEffect(() => {
    if (windownWidth <= REACT_IPAD_RESPONSIVE_WIDTH) {
      document.addEventListener("click", handleClick);
      document.querySelector(".backdrop") &&
        document
          .querySelector(".backdrop")
          .addEventListener("click", handleClickBackdrop);
    }

    return () => {
      if (windownWidth <= REACT_IPAD_RESPONSIVE_WIDTH) {
        document.removeEventListener("click", handleClick);
        document.querySelector(".backdrop") &&
          document
            .querySelector(".backdrop")
            .removeEventListener("click", handleClickBackdrop);
      }
    };
  }, [handleClick]);

  const onShowHotSpot = (e) => {
    if (windownWidth <= REACT_IPAD_RESPONSIVE_WIDTH) {
      return false;
    }

    let parentWidth = container.offsetWidth;
    let parentHeight = container.offsetHeight;

    let infoBox = e.target.querySelector(".hotSpot-info");
    if (infoBox) {
      let hotSpot = e.target;
      let top = hotSpot.offsetTop;
      let left = hotSpot.offsetLeft;
      let infoBoxTop = infoBox.offsetHeight / 2;
      let infoBoxLeft = hotSpot.offsetWidth;
      let infoLeft = left + hotSpot.offsetWidth + 50;

      if (infoLeft + infoBox.offsetWidth >= parentWidth) {
        infoBoxLeft = -infoBox.offsetWidth;
        hotSpot.classList.add("flip-right");
      }

      if (infoBoxTop > top) {
        infoBoxTop = top / 2;
      }

      if (parentHeight - top < infoBoxTop) {
        infoBoxTop = infoBox.offsetHeight - hotSpot.offsetHeight;
        hotSpot.classList.add("flip-bottom");
      }

      infoBox.style.left = `${infoBoxLeft}px`;
      infoBox.style.top = `-${infoBoxTop}px`;
    }
  };

  const onHideHotSpot = (e) => {
    let hotSpot = e.target;
    hotSpot.classList.remove("flip-right");
    hotSpot.classList.remove("flip-bottom");
  };

  return (
    <React.Fragment>
      <div
        className="hotSpot"
        data-id={data.id}
        ref={hotSpotRef}
        style={{ top: `${data.y}px`, left: `${data.x}px` }}
        onMouseEnter={onShowHotSpot}
        onMouseLeave={onHideHotSpot}
        onClick={onClickHotSpot}
      >
        <span className="divider"></span>
        <div className="hotSpot-info">
          <div className="hotSpot-info-wrapper">
            <div className="hotSpot-info-body">
              <img src={HotSpotTop} alt="" className="icon-top" />
              <h5>{data.title}</h5>
              <img src={data.image} className="logo" />
              <div className="line"></div>
              <div className="description"
                dangerouslySetInnerHTML={{
                  __html: addListTagToParagraph(data.description),
                }}
              ></div>
              <div className="text-center">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={data.link}
                  className="hotSpot-info-body-link"
                >
                  {t("design.material.linkInfo")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withViewPort(HotSpot, ".design", ".hotSpot");
