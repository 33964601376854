import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AmenitiesHeader from "./AmenitiesHeader";
import AmenitiesImage from "./AmenitiesImage";
import { useGetAmenities } from "../../hooks/amenities";
import _ from "lodash";
import RelateInfo from "../Location/RelateInfo";
import { useDispatch, useSelector } from "react-redux";
import { getRelateInfo } from "../../actions/relate-information";
import TagHead from "../../components/TagHead";
// import PhoneFixed from "../../components/PhoneFixed";
import { useTranslation } from "react-i18next";
import AmenitiesMobile from "./AmenitiesMobile";
// import { getAmenityData } from "../../actions/amenity";
import { setPageInfo } from "../../actions/page";
import { PageType } from "../../enums/index";
import { useGetPageInfo } from "../../hooks/page";

const Amenities = (props) => {
  const location = useLocation();
  const [amenities] = useGetAmenities(props.location.pathname);
  const amenityIndex = useSelector((state) => state.amenity.indexAmenity);
  const size = useSelector((state) => state.amenity.sizeList);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isDesktop = window.innerWidth > 768;
  const [page, pageLoading] = useGetPageInfo(PageType.Amenity);

  if (dispatch) {
    dispatch(
      getRelateInfo(location.pathname.includes("apartment") ? "1,2,3" : "12,10,11")
    );
    // dispatch(getAmenityData(props.location.pathname));
  }

  useEffect(() => {
    if (!pageLoading) {
      dispatch(setPageInfo(page));
    }
  }, [page, pageLoading]);

  const onScrolling = (e) => {
    if (e.nativeEvent.wheelDelta === 0) {
      return;
    }
    const info = document.querySelector(".amenity-contact");
    info.classList.remove("show-info");
    if (e.nativeEvent.wheelDelta > 0) {
      document.getElementsByClassName("div1")[0].style.marginTop = "0";
    } else {
      if (amenityIndex >= size) {
        info.classList.add("show-info");
        document.getElementsByClassName("div1")[0].style.marginTop = "-100vh";
      }
    }
  };

  return (
    <div className="amenities-page" onWheel={onScrolling}>
      <div className="flex-column d-flex h-100 div1 amenities-content">
        <AmenitiesHeader
          data={{
            title: _.get(amenities, "page.title"),
            desc: _.get(amenities, "page.description"),
          }}
        />
        {isDesktop && (
          <div className="desktop-screen">
            <div className="amenities-wrapper flex-column d-flex">
              <AmenitiesImage data={{ amenity: _.get(amenities, "amenity") }} />
            </div>
          </div>
        )}
        {!isDesktop && (
          <div className="mobile-screen">
            <AmenitiesMobile amenity={amenities} />
          </div>
        )}
      </div>
      <div className="desktop-screen">
        <TagHead title={t("header.amenities")} />
        <RelateInfo
          customClass="margin-common amenity-contact"
          showPhone={false}
          showLine={true}
        />
      </div>
    </div>
  );
};

export default Amenities;
