import React, { useEffect, useState } from "react";

import FormContact from "../../components/FormContact";
import TagHead from "../../components/TagHead";
import TopHeader from "../../components/TopHeader";
import ContactInfomation from "./ContactInfomation";
import { useGetContactInfo } from "../../hooks/contact";
import { useTranslation } from "react-i18next";
import ScrollToTop from "../../components/common/ScrollToTop";
import { setPageInfo } from "../../actions/page";
import { PageType } from "../../enums/index";
import { useGetPageInfo } from "../../hooks/page";
import { useDispatch } from "react-redux";
import { REACT_FULLPAGE_RESPONSIVE_WIDTH } from "../../constants";
const Contact = () => {
  const { t } = useTranslation();
  const [contactInfo] = useGetContactInfo();
  const dispatch = useDispatch();
  let contactInfoData = {
    address: "",
    hotline: "",
    email: "",
    description: "",
  };

  const [page, pageLoading] = useGetPageInfo(PageType.Contact);
  const windownWidth = window.innerWidth;

  if (contactInfo) {
    contactInfoData = contactInfo["info"];
    contactInfoData["description"] = contactInfo["description"];
  }

  useEffect(() => {
    if (!pageLoading) {
      dispatch(setPageInfo(page));
    }
  }, [page, pageLoading]);
  return (
    <div className="contact-page-container position-relative">
      <div className="contact_page">
        {/* Element Fixed */}
        <TagHead title={t("header.contact")} />
        {/* <div className="pr-3">
          <TopHeader showPhone={false} customClass="container mr-0" />
        </div> */}
        {/* Content  */}
        {windownWidth >= REACT_FULLPAGE_RESPONSIVE_WIDTH ? (
          <div className="contact_page--content show-info">
            <ContactInfomation data={contactInfoData} />
            <FormContact haveContent />
          </div>
        ) : (
          <div className="contact_page--content">
            <FormContact haveContent />
            <ContactInfomation data={contactInfoData} />
          </div>
        )}
      </div>
      <div className="footer-bottom">{t("footer.copy")}</div>
      <ScrollToTop />
    </div>
  );
};
export default Contact;
