import React, { useEffect } from "react";
import {useLocation} from 'react-router-dom';
// import bg from "../../assets/images/desgin/bg.png";
import { useGetDesignIntroduce } from "../../hooks/design";
// import icon from "../../assets/images/desgin/group-22.svg";
// import bgContent from "../../assets/images/desgin/bg_intro_content.png";
import PerfectScrollbar from "react-perfect-scrollbar";
import TopHeader from "../../components/TopHeader";
// import { nl2ToBr } from "../../utils/helper";
import { setDesignInfo } from "../../actions/designs";
import { useDispatch } from "react-redux";

const Introduce = ({active}) => {
  const location = useLocation();
  const [data, loading] = useGetDesignIntroduce(location.pathname);
  const dispatch = useDispatch();
  useEffect(() => {
    if (active) {
      dispatch(setDesignInfo(data));
    }
  }, [data, loading, active]);
  return (
    <PerfectScrollbar className="introduce section h-100 d-flex align-items-center">
      {data && loading ? (
        <div className="page-loading">...</div>
      ) : (
        <div
          className="introduce-container"
          style={{ backgroundImage: `url(${data && data.image})` }}
        >
          <TopHeader showPhone={false} customClass="intro-contact" />
          <div className="introduce-item d-flex flex-column ">
            {/* <div
              className="introduce-content "
              style={{ backgroundImage: `url(${bgContent})` }}
            >
              <div
                className="title position-relative animate__animated animate__fadeInLeft"
                style={{ "--animate-duration": `1s` }}
              >
                <div className="introduce-title position-relative d-flex">
                  <img src={icon} alt="" />
                  <h3
                    dangerouslySetInnerHTML={{ __html: nl2ToBr(data.title) }}
                  />
                </div>
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: data.description }}
                className="introduce-desc animate__animated animate__fadeInLeft"
                style={{ "--animate-duration": `1s` }}
              />

              <div className="line"></div>
              <div className="introduce-logo">
                {data.logo &&
                  data.logo.map((item, index) => (
                    <div
                      key={index}
                      className="animate__animated animate__fadeInLeft"
                      style={{ "--animate-duration": `${index / 2 + 0.5}s` }}
                    >
                      <img src={item.image} alt={item.description} />
                    </div>
                  ))}
              </div>
            </div> */}
          </div>
        </div>
      )}
    </PerfectScrollbar>
  );
};

export default Introduce;
