import React from "react";
import TopHeader from "../../components/TopHeader";
import { nl2ToBr } from "../../utils/helper";
const AmenitiesHeader = ({ data }) => {
  const { title, desc } = data;

  return (
    <div className="header w-100">
      <div className="header-content w-100 h-100">
        <div className="d-flex align-items-center flex-column position-relative ml-4 mr-4">
          <h2 className="size-20 color-putty font-weight-bold text-center font-iCielGamine-bold mt-5 tile text-upcase animate__animated animate__fadeInUp animate__slow">
            {title}
          </h2>
          <hr className="mt-0 mb-3" />
          <p
            className="content-text size-15 text-center mb-4 color-common animate__animated animate__fadeInUp animate__slower"
            dangerouslySetInnerHTML={{ __html: nl2ToBr(desc) }}
          ></p>
          <div className="position-absolute w-100 justify-content-end container mt-4">
            <TopHeader showPhone />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AmenitiesHeader;
