import React from "react";
import { useSelector } from "react-redux";

import icon from "../../assets/images/desgin/group-22.svg";
import bgContent from "../../assets/images/desgin/bg_intro_content.png";
import { nl2ToBr, showTitle } from "../../utils/helper";
import Slider from "react-slick";
const DesignInfo = () => {
  const data = useSelector((state) => state.design.data);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    // centerPadding: "0px",
    slidesToScroll: 3,
    arrows: false,
    autoplay: true
  };
  return (
    <>
      {data && (
        <div
          className="introduce-content"
          style={{ backgroundImage: `url(${bgContent})` }}
        >
          <div className="title position-relative  show-border">
            <div className="introduce-title position-relative d-flex show-title">
              <img src={icon} alt="" />
              <h3
                dangerouslySetInnerHTML={{
                  __html: nl2ToBr(data.title),
                }}
              />
            </div>
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: data.description }}
            className="introduce-desc show-description"
          />
          {data.logo && (
            <>
              <div className="line show-line"></div>
              <div className="introduce-logo show-intro-logo">
              <Slider {...settings} className="position-relative">
                {data.logo.map((item, index) => (
                  <div key={index}>
                    <img src={item.image} alt={item.description} />
                  </div>
                ))}
                </Slider>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default DesignInfo;
