import React from "react";
import { Link } from "react-router-dom";

import { StarIcon } from "../../components/common/availability/Icons";

const CompareItem = (props) => {
  const { col, step, productData, handleFavourite, handleToCompare } = props;
  const { id, image, favourited, floor, info, acreage } = productData;
  const clickStarFavourite = () => {
    handleFavourite(id);
  };
  const clickCompare = () => {
    handleToCompare(step + 1, productData);
  };
  return (
    <>
      <div
        className={col ? "left-choose-list-item col" : "left-choose-list-item"}
        onClick={clickCompare}
      >
        <img className="left-choose-list-item-img" src={image} alt="product" />
        <div className="left-choose-list-item-main">
          <div className="left-choose-list-item-main-text">
            <p className="icon">
              <StarIcon
                onClick={clickStarFavourite}
                nameClass={favourited ? "favourited" : ""}
              />
              <span>{floor}</span>
            </p>
            {info &&
              info.map((x, index) => (
                <p key={index.toString()} className="text">
                  {x.amount} <span>{x.name}</span>
                </p>
              ))}
            {acreage && (
              <p className="text">
                {acreage.value} <span> {acreage.unit}</span>
              </p>
            )}
          </div>
          <div className="left-choose-list-item-main-button">
            <Link to="availability">
              <button>AVAILABLE UNITS</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompareItem;
