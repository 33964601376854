import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import IconClock from "../../assets/images/iconArrows/ic_time.svg";
import IconArrow from "../../assets/images/iconArrows/ic_arrow_next.svg";
import { formatTime } from "../../utils/functions";
import { P } from "@fullpage/react-fullpage";

const CATEGORY = "category";

const NewsBlock = ({ data, type }) => {
  const {
    id,
    title,
    description,
    created_at,
    category,
    thumbnail,
    name,
    image,
    external_link,
  } = data;
  const { t } = useTranslation();
  const history = useHistory();
  const isCategoryBlock = type === CATEGORY;
  let targetBlank = "";
  // const linkToDetail = isCategoryBlock ? `/news/${id}` : `/news/detail/${id}`;
  let linkToDetail = `/news/${id}`;
  if (isCategoryBlock) {
    linkToDetail = `/apartment/news/${id}`;
  } else if (external_link) {
    linkToDetail = external_link;
    targetBlank = "_blank";
  } else {
    linkToDetail = `/apartment/news/detail/${id}`;
  }

  const redirectDetailPage = () => {
    if (targetBlank) {
      window.open(linkToDetail, "_blank");
    } else {
      history.push(linkToDetail);
    }
  };
  return (
    <div
      className={`news_block ${isCategoryBlock ? "category" : ""}`}
      onClick={redirectDetailPage}
    >
      <div className="news_block--image">
        <img src={isCategoryBlock ? image : thumbnail} alt="img" />
      </div>
      <div className="news_block--tag row"></div>
      <div className="news_block--content">
        <div className={`news_block--content-head`}>
          <h5>{isCategoryBlock ? name : category.name}</h5>

          <h3>{title}</h3>
          {!isCategoryBlock && <div className="time_news">{created_at} </div>}
        </div>
        <div className="news_block--content-body">
          <span className={`news-description`}>{description}</span>
        </div>
        <div className="news_block--content-footer">
          {targetBlank ? (
            <a href="">
              {t("common.seeDetail")} <img src={IconArrow} alt="img" />
            </a>
          ) : (
            <Link to={linkToDetail} target={targetBlank}>
              {t("common.seeDetail")} <img src={IconArrow} alt="img" />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsBlock;
