import React from "react";
import _ from "lodash";
import Slider from "react-slick";
import { nl2ToBr } from "../../utils/helper";
import { addListTagToParagraph } from "../../utils/helper";

const SliderAmenities = ({ data }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    centerPadding: "50px",
    slidesToScroll: 1,
    arrows: false,
    autoplay: true
  };

  return (
    <Slider {...settings} className="position-relative">
      {_.map(data, (item, iItem) => (
        <div className="slider-item position-relative">
          <div className=" w-100 img">
            <img
              className="amentity-image"
              alt="amentities images"
              effect="blur"
              src={_.get(item, "image")}
            />
          </div>
          <div className="image-description-wrapper position-absolute w-100 h-100 d-flex justify-content-end align-items-end">
            {_.get(item, "description") ? (
              <div
                className="image-description position-relative"
                dangerouslySetInnerHTML={{
                  __html: addListTagToParagraph(
                    _.get(item, "description")
                  ),
                }}
              ></div>
            ) : (
              ""
            )}
          </div>
        </div>
      ))
      }
    </Slider >
  );
};

export default SliderAmenities;
