import React, { useCallback, createRef, useEffect } from "react";
import { initPostionPoint } from "../../utils/functions";
import { getRandomNumber } from "../../utils/helper";
import { nl2ToBr } from "../../utils/helper";
import mainImg  from "../../assets/images/logo_epics.png";
const NotesMaps = ({ data, icon }) => {
  const container = document.getElementById("maps_overview");
  let hotSpot;
  if (container) {
    hotSpot = initPostionPoint(data, container);
  }
  let noteRef = createRef(null);

  const getStyleNoteMaps = (position) => {
    return position
      ? { top: `${position.y - 15}px`, left: `${position.x - 10}px` }
      : {};
  };

  const setPoint = useCallback(() => {
    if (container && noteRef.current) {
      hotSpot = initPostionPoint(data, container);
      noteRef.current.style.left = `${hotSpot.x - 10}px`;
      noteRef.current.style.top = `${hotSpot.y - 15}px`;
    }
  }, [hotSpot, noteRef, data, container]);

  useEffect(() => {
    setPoint();
    window.addEventListener("resize", setPoint);

    return () => window.removeEventListener("resize", setPoint);
  }, [setPoint]);
  let customClass= '';
  if(data.main) {
    icon = mainImg;
    customClass = 'note-maps-main'
  }
  return (
    <div ref={noteRef} className={`note-maps points ${customClass}`}
         style={{ ...getStyleNoteMaps, '--animate-duration': `${getRandomNumber(1.2500, 2.5000)}s`, animationDelay: '500ms'}}>
        <img src={icon} alt="img" className={`map-icon main-icon-${data.main}`}/>
      <p
        dangerouslySetInnerHTML={{ __html: nl2ToBr(data.name) }}
        className={`text-align-${data.text_align}`}
      ></p>
    </div>
  );
};

export default NotesMaps;
