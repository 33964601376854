import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactFullpage from "@fullpage/react-fullpage";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  REACT_FULLPAGE_KEY,
  REACT_FULLPAGE_RESPONSIVE_WIDTH,
} from "../../constants";
import { translateVertical } from "../../utils/helper";
import SectionDescription from "./SectionDescription";
import bg_mock from "../../assets/images/mock-intro.jpeg";
import bg_logo from "../../assets/images/epic_2.png";
import "./SectionInformation.scss";
import PageInformation from "../Information";
import RelateInfo from "../Location/RelateInfo";
import { useGetPageInfo } from "../../hooks/page";
import { getRelateInfo } from "../../actions/relate-information";
import { PageType } from "../../enums/index";
import { setPageInfo } from "../../actions/page";
import Footer from "../../components/Footer";
import ScrollToTop from "../../components/common/ScrollToTop";

const PageIntro = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [page, pageLoading] = useGetPageInfo(PageType.Home);
  if (dispatch) {
    dispatch(
      getRelateInfo(
        location.pathname.includes("apartment") ? "2,3,6" : "10,11,6"
      )
    );
  }
  useEffect(() => {
    if (!pageLoading) {
      dispatch(setPageInfo(page));
    }
  }, [page, pageLoading]);

  const onLoadSlide = (origin, destination, direction) => {
    const { item } = destination;
    item.classList.add("show-info");
  };
  const onLeaveSlide = (origin, destination, direction) => {
    let { item, index } = destination;
    let originItem = origin.item;

    if (originItem.classList.contains("changing")) {
      return false;
    }

    // Clear style of old section
    item.classList.add("changing");
    originItem.classList.add("show-text", "changing");
    originItem.style.removeProperty("z-index");

    // Add style style for destination section
    item.style.zIndex = 10;

    translateVertical(function (value) {
      originItem.classList.remove("show-info");
      if (value === 100) {
        item.style.transform = "matrix(1, 0, 0, 1, 0, 0)";
        item.classList.remove("changing");
        originItem.classList.remove("show-text", "changing");
        item.classList.add("show-text");
        item.classList.add("show-info");
      }
    });
  };

  return (
    <>
      {location.pathname.includes("office") && (
        <div className="page-intro-wrapper">
          <ScrollToTop />
          {window.innerWidth >= 992 && (
            <ReactFullpage
              licenseKey={REACT_FULLPAGE_KEY}
              scrollingSpeed={1000}
              afterLoad={onLoadSlide}
              onLeave={onLeaveSlide}
              // menu="#myMenu"
              // lockAnchors={true}
              arrowNavigation={true} // use arrow keys
              navigation={false} // use dots navigatio
              loopTop={false}
              scrollBar={false}
              responsiveWidth={REACT_FULLPAGE_RESPONSIVE_WIDTH}
              render={() => {
                return (
                  <ReactFullpage.Wrapper>
                    <div className="section">
                      <div className="page-information">
                        <div className="page-information__text">
                          <p className="home-index show-sub-title mt-0">
                            <span>01</span>
                            <span className="text-devider"></span>
                            <span>{t("office.heading")}</span>
                          </p>
                          <h4
                            className="show-title"
                            dangerouslySetInnerHTML={{
                              __html: t("office.title"),
                            }}
                          />
                          <div>
                            <div className="">
                              <div className=" block-devider show-line mr-lg-3"></div>
                            </div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: t(
                                  "home.project.nameIntroBlockCommerce",
                                  {
                                    commerce: "B",
                                  }
                                ),
                              }}
                            />
                            <div className="row mt-3 show-scale-2">
                              <div
                                className="col-5 col-md-6 col-lg-5 home-project-detail-item"
                                dangerouslySetInnerHTML={{
                                  __html: t("home.project.totalOffice", {
                                    num: 245,
                                  }),
                                }}
                              ></div>
                              <div className="col-7 col-md-6 pl-md-0 pl-lg-3 col-lg-7">
                                <div
                                  className="d-flex flex-column"
                                  dangerouslySetInnerHTML={{
                                    __html: t(
                                      "home.project.textFloorCommerce",
                                      {
                                        num_floor: 30,
                                        num_garage: 3,
                                        num_tmdv: 1,
                                      }
                                    ),
                                  }}
                                ></div>
                              </div>
                            </div>
                            <div className="mt-3 show-scale-2">
                              <div>
                                <img
                                  src={bg_logo}
                                  alt="img"
                                  width="150px"
                                  style={{
                                    position: "relative",
                                    minWidth: "200px"
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="page-information__img d-none d-lg-block">
                          <img src={bg_mock} alt="img" />
                        </div>
                      </div>
                    </div>
                    <div className="section">
                      <SectionDescription />
                    </div>
                    <RelateInfo
                      showPhone={false}
                      customClass="location location-home"
                    />
                  </ReactFullpage.Wrapper>
                );
              }}
            />
          )}
          {window.innerWidth < 992 && (
            <>
              <div className="page-information">
                <div className="page-information__text">
                  <p className="home-index mt-0">
                    <span>01</span>
                    <span className="text-devider"></span>
                    <span>{t("office.heading")}</span>
                  </p>
                  <h4
                    dangerouslySetInnerHTML={{
                      __html: t("office.title"),
                    }}
                  />
                  <div>
                    <div className="">
                      <div className=" block-devider mr-lg-3"></div>
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t("home.project.nameIntroBlockCommerce", {
                          commerce: "B",
                        }),
                      }}
                    />
                    <div className="row mt-3">
                      <div
                        className="col-5 col-md-6 col-lg-5 home-project-detail-item"
                        dangerouslySetInnerHTML={{
                          __html: t("home.project.totalOffice", {
                            num: 245,
                          }),
                        }}
                      ></div>
                      <div className="col-7 col-md-6 pl-md-0 pl-lg-3 col-lg-7">
                        <div
                          className="d-flex flex-column"
                          dangerouslySetInnerHTML={{
                            __html: t("home.project.textFloorCommerce", {
                              num_floor: 30,
                              num_garage: 3,
                              num_tmdv: 1,
                            }),
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="mt-3">
                      <div>
                        <img
                          src={bg_logo}
                          alt="img"
                          width="150px"
                          style={{
                            position: "relative",
                            left: "-20px",
                          }}
                        />
                      </div>
                      <p className="normal">{t("page_information.manage")}</p>
                    </div>
                  </div>
                </div>
                <div className="page-information__img d-none d-lg-block">
                  <img src={bg_mock} alt="img" />
                </div>
              </div>
              <SectionDescription />
              <RelateInfo customClass="location location-home" />
              <Footer />
            </>
          )}
        </div>
      )}
      {location.pathname.includes("apartment") && <PageInformation />}
    </>
  );
};

export default PageIntro;
