import requester from "../requester";
import { POST_CONTACT, GET_CONTACT, GET_CONTACT_INFO } from "../../constants";

const Contact = {
  postContact: () => requester.post(POST_CONTACT),
  getContact: () => requester.get(GET_CONTACT),
  getContactInfo: () => requester.get(GET_CONTACT_INFO)
};

export default Contact;
