import React, { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  useGetLocation,
  useGetContentLocationOffice,
} from "../../hooks/location";
import { nl2ToBr, transformPoint } from "../../utils/helper";
import LocationPoint from "./LocationPoint";

const LocationIntroduce = ({ data }) => {
  const { t } = useTranslation();
  const locationRouter = useLocation();
  const [location, loading] = useGetLocation("apartment");
  const page = location?.page;
  const container = document.getElementById("location-introduce");
  const [content, loadingContent] = useGetContentLocationOffice(
    locationRouter.pathname
  );
  const transformListPoint = useCallback(
    (points) => points.map((o) => transformPoint(o, container)),
    [container]
  );

  return (
    <>
      <div className="location-introduce" id="location-introduce">
        {loading || loadingContent ? (
          <div className="page-loading">{t("loading")}</div>
        ) : (
          <>
            <img
              src={page.image}
              alt={page.title}
              className="location-introduce-background"
            />
            <div className="location-introduce-points">
              {location.location &&
                transformListPoint(location.location).map((o) => (
                  <LocationPoint point={o} container={container} key={o.id} />
                ))}
            </div>
            <div className="location-introduce-content">
              <h5
                className="show-title"
                dangerouslySetInnerHTML={{
                  __html: nl2ToBr(
                    locationRouter.pathname.includes("office") &&
                      content?.length
                      ? content[0].title
                      : page.title
                  ),
                }}
              ></h5>
              <div className="line go-width-animate show-line"></div>
              <div className="show-description">
                <p
                  dangerouslySetInnerHTML={{
                    __html: nl2ToBr(
                      locationRouter.pathname.includes("office") &&
                        content?.length
                        ? content[0].description
                        : page.description
                    ),
                  }}
                  className=""
                ></p>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default LocationIntroduce;
