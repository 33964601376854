import React from "react";
import { useTranslation } from "react-i18next";

const STATUS = {
  SUCCESS: "success",
  ERROR: "error",
};
const Toast = ({ visible, success }) => {
  const { t } = useTranslation();
  const classNameToastStatus = success ? STATUS.SUCCESS : STATUS.ERROR;
  const classNameToast = visible
    ? `form-toast show  ${classNameToastStatus}`
    : `form-toast ${classNameToastStatus}`;
  return (
    <div className={classNameToast}>
      <span>
        {success
          ? t("contactForm.successSubmit")
          : t("contactForm.errorSubmit")}
      </span>
    </div>
  );
};

export default Toast;
