import React, { useEffect, useState } from "react";
import CompareItem from "./CompareItem";
import CompareRight from "./CompareRight";

import product from "../../assets/images/3.svg";
const listCompares = [
  {
    id: 1,
    image: product,
    favourited: false,
    floor: "801",
    info: [
      {
        name: "bedroom",
        amount: 1,
      },
      {
        name: "bathroom",
        amount: 2,
      },
      {
        name: "views",
        amount: "E",
      },
    ],
    acreage: {
      unit: "SQ.FT",
      value: 805,
    },
  },
  {
    id: 2,
    image: product,
    favourited: false,
    floor: "802",
    info: [
      {
        name: "bedroom",
        amount: 2,
      },
      {
        name: "bathroom",
        amount: 2,
      },
      {
        name: "views",
        amount: "E",
      },
    ],
    acreage: {
      unit: "SQ.FT",
      value: 805,
    },
  },
  {
    id: 3,
    image: product,
    favourited: false,
    floor: "803",
    info: [
      {
        name: "bedroom",
        amount: 2,
      },
      {
        name: "bathroom",
        amount: 2,
      },
      {
        name: "views",
        amount: "E",
      },
    ],
    acreage: {
      unit: "SQ.FT",
      value: 805,
    },
  },
  {
    id: 4,
    image: product,
    favourited: false,
    floor: "804",
    info: [
      {
        name: "bedroom",
        amount: 2,
      },
      {
        name: "bathroom",
        amount: 2,
      },
      {
        name: "views",
        amount: "E",
      },
    ],
    acreage: {
      unit: "SQ.FT",
      value: 805,
    },
  },
  {
    id: 5,
    image: product,
    favourited: false,
    floor: "805",
    info: [
      {
        name: "bedroom",
        amount: 2,
      },
      {
        name: "bathroom",
        amount: 2,
      },
      {
        name: "views",
        amount: "E",
      },
    ],
    acreage: {
      unit: "SQ.FT",
      value: 805,
    },
  },
  {
    id: 6,
    image: product,
    favourited: false,
    floor: "806",
    info: [
      {
        name: "bedroom",
        amount: 2,
      },
      {
        name: "bathroom",
        amount: 2,
      },
      {
        name: "views",
        amount: "E",
      },
    ],
    acreage: {
      unit: "SQ.FT",
      value: 805,
    },
  },
  {
    id: 7,
    image: product,
    favourited: false,
    floor: "807",
    info: [
      {
        name: "bedroom",
        amount: 2,
      },
      {
        name: "bathroom",
        amount: 2,
      },
      {
        name: "views",
        amount: "E",
      },
    ],
    acreage: {
      unit: "SQ.FT",
      value: 805,
    },
  },
  {
    id: 8,
    image: product,
    favourited: false,
    floor: "808",
    info: [
      {
        name: "bedroom",
        amount: 2,
      },
      {
        name: "bathroom",
        amount: 2,
      },
      {
        name: "views",
        amount: "E",
      },
    ],
    acreage: {
      unit: "SQ.FT",
      value: 805,
    },
  },
  {
    id: 9,
    image: product,
    favourited: false,
    floor: "809",
    info: [
      {
        name: "bedroom",
        amount: 2,
      },
      {
        name: "bathroom",
        amount: 2,
      },
      {
        name: "views",
        amount: "E",
      },
    ],
    acreage: {
      unit: "SQ.FT",
      value: 805,
    },
  },
  {
    id: 10,
    image: product,
    favourited: false,
    floor: "810",
    info: [
      {
        name: "bedroom",
        amount: 2,
      },
      {
        name: "bathroom",
        amount: 2,
      },
      {
        name: "views",
        amount: "E",
      },
    ],
    acreage: {
      unit: "SQ.FT",
      value: 805,
    },
  },
];

const Compare = (props) => {
  // const {} = props;
  const [data, setData] = useState(listCompares);
  const [dataCompares, setDataCompares] = useState({
    step: 1,
    data: [],
  });

  const changeStep = (step) => {
    [1, 2, 3].map((item) => {
      if (step === item) {
        document.getElementById(`step${step}`).style.display = "block";
      } else {
        document.getElementById(`step${item}`).style.display = "none";
      }
    });
  };
  const handleFavourite = (id) => {
    setData(
      data.map((item) => {
        if (item.id === id) {
          return { ...item, favourited: !item.favourited };
        }
        return item;
      })
    );
    if (dataCompares.data.length !== 0) {
      const idComparesData = dataCompares.data.map((item) => item.id);
      if (idComparesData.indexOf(id) !== -1) {
        setDataCompares({
          ...dataCompares,
          data: dataCompares.data.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                favourited: !item.favourited,
              };
            }
            return item;
          }),
        });
      }
    }
  };
  const handleToCompare = (step, product) => {
    setDataCompares({
      step,
      data: [...dataCompares.data, product],
    });
    setData(data.filter((item) => item.id !== product.id));
  };
  const selectCompare = (step, product) => {
    setDataCompares({
      step,
      data: dataCompares.data.filter((compare) => compare.id !== product.id),
    });
    setData([...data, product]);
  };
  useEffect(() => {
    changeStep(dataCompares.step);
  }, [dataCompares.step]);

  return (
    <>
      <section className="left-choose" id="step1">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6  left-choose-list">
              {data.length !== 0 &&
                data.map((item) => (
                  <CompareItem
                    handleFavourite={handleFavourite}
                    key={item.id}
                    col={true}
                    handleToCompare={handleToCompare}
                    step={dataCompares.step}
                    productData={item}
                  />
                ))}
            </div>

            <CompareRight
              text={dataCompares.data.length === 0}
              step={dataCompares.step}
              selectCompare={selectCompare}
              handleFavourite={handleFavourite}
              productData={
                dataCompares.data.length !== 0 && dataCompares.data[0]
              }
            />
          </div>
        </div>
      </section>
      <section className="left-choose" id="step2">
        <div className="container-fluid">
          <div className="row">
            <CompareRight
              text={dataCompares.data.length === 0}
              step={dataCompares.step}
              selectCompare={selectCompare}
              handleFavourite={handleFavourite}
              productData={
                dataCompares.data.length !== 0 && dataCompares.data[0]
              }
            />
            <div className="col-md-6 left-choose-list">
              {data.length !== 0 &&
                data.map((item) => (
                  <CompareItem
                    handleFavourite={handleFavourite}
                    key={item.id}
                    col={true}
                    handleToCompare={handleToCompare}
                    step={dataCompares.step}
                    productData={item}
                  />
                ))}
            </div>
          </div>
        </div>
      </section>

      <section className="left-choose" id="step3">
        <div className="container-fluid">
          <div className="row">
            {dataCompares.data.length > 1 &&
              dataCompares.data.map((item) => (
                <CompareRight
                  key={item.id}
                  step={dataCompares.step}
                  setStep={selectCompare}
                  productData={item}
                  selectCompare={selectCompare}
                  handleFavourite={handleFavourite}
                />
              ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Compare;
