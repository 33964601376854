import React, { useCallback, createRef, useEffect } from "react";
import { initPostionPoint } from "../../utils/functions";
import mainImg  from "../../assets/images/logo_epics.png";

const NotesMaps = ({ data, icon }) => {
  const container = document.getElementById("maps_overview");
  let hotSpot;
  if (container) {
    hotSpot = initPostionPoint(data, container);
  }
  let noteRef = createRef(null);

  const getStyleNoteMaps = (position) => {
    return position ? { top: `${position.y - 10 }px`, left: `${position.x - 15 }px` } : {};
  };
  const setPoint = useCallback(() => {
    if (container && noteRef.current) {
      hotSpot = initPostionPoint(data, container);
      noteRef.current.style.left = `${hotSpot.x -10}px`;
      noteRef.current.style.top = `${hotSpot.y - 15}px`;
    }
  }, [hotSpot, noteRef, data, container]);

  useEffect(() => {
    setPoint();
    window.addEventListener("resize", setPoint);

    return () => window.removeEventListener("resize", setPoint);
  }, [setPoint]);

  let customClass= '';
  if(data.main) {
    icon = mainImg;
    customClass = 'note-maps-main'
  }
  return (
    <div ref={noteRef} className={`note-maps animate__animated animate__bounceInDown ${customClass}`} style={{ ...getStyleNoteMaps }}>
      <img src={icon} alt="img" className={`map-icon main-icon-${data.main}`} />
      <p className={`d-inline-block text-align-${data.text_align}`}>{data.name}</p>
    </div>
  );
};

export default NotesMaps;
