import React, { useCallback, useEffect, useRef } from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import ReactFullpage from "@fullpage/react-fullpage";
import { useDispatch, useSelector } from "react-redux";
import { setIndexAmenity, setSizeAmenityList } from "../../actions/amenity";
import { useTranslation } from "react-i18next";
import PerfectScrollbar from "react-perfect-scrollbar";
import { REACT_FULLPAGE_KEY } from "../../constants";
import { addListTagToParagraph } from "../../utils/helper";
import { translateVertical } from "../../utils/helper";
import SliderAmenities from "./SliderAmenities";
// import IconLoading from "../../assets/images/icons/loading.gif";

const AmenitiesImage = ({ data }) => {
  const { pathname } = useLocation();
  const fullpageRef = useRef();
  const { t } = useTranslation();
  const { amenity } = data;
  const dispatch = useDispatch();
  const amenityIndex = useSelector((state) => state.amenity.indexAmenity);
  const ps = useRef();
  const listItem = amenity
    ? _.map(
      _.reduce(
        _.keys(
          pathname.includes("office")
            ? {
              firstFloor: amenity?.firstFloor,
              secondFloor: amenity?.secondFloor,
              rooftop: amenity?.rooftop,
              safeEnv: amenity?.safeEnv,
              security: amenity?.security,
            }
            : amenity
        ),
        (result, key) =>
          _.concat(
            result,
            _.map(amenity[key], (item) => ({ ...item, part: key }))
          ),
        []
      ),
      (item, index) => ({ ...item, index })
    )
    : [];

  if (dispatch) {
    dispatch(setSizeAmenityList(listItem.length));
  }

  // const lazyLoadImage = (elements) => {
  //   if (elements.hasAttribute("data-src")) {
  //     elements.setAttribute("src", elements.dataset.src);
  //     elements.removeAttribute("data-scr");
  //   }
  // };

  const onSlideChanged = useCallback((origin, destination, direction) => {
    changeSlideIndex(destination.index);

    // Animation change slide
    let destinationItem = destination.item;
    let originItem = origin.item;

    if (originItem.classList.contains("changing")) {
      return false;
    }

    // let destinationImg = destinationItem.querySelector(".amentity-image");
    // if (destinationImg) {
    //   lazyLoadImage(destinationImg);
    // }

    destinationItem.classList.add("changing");
    originItem.classList.add("show-text", "changing");
    originItem.style.removeProperty("z-index");
    destinationItem.style.zIndex = 10;
    originItem.classList.remove("show-info");
    destinationItem.classList.add("show-info");
    translateVertical(function (value) {
      let trans = direction === "down" ? 100 - value : -(100 - value);
      destinationItem.style.top = `${trans}%`;

      originItem.classList.remove("show-info");
      if (value === 100) {
        destinationItem.style.top = `0`;
        destinationItem.classList.remove("changing");
        originItem.classList.remove("show-text", "changing");
        destinationItem.classList.add("show-text", "show-info");
        setTimeout(() => {
          originItem.style.top = `100%`;
        }, 100);
      }
    });
  }, []);

  useEffect(() => {
    if (fullpageRef.current) {
      fullpageRef.current.moveTo(amenityIndex + 1);
    }
  }, [fullpageRef.current, amenityIndex]);

  const changeSlideIndex = useCallback((id) => {
    dispatch(setIndexAmenity(id));
  }, []);

  useEffect(() => {
    const curr = ps.current;
    if (curr && amenityIndex > 7) {
      curr.scrollTop = window.innerHeight;
    } else {
      curr.scrollTop = 0;
    }
  }, [amenityIndex]);
  const onAfterLoad = (origin, destination, direction) => {
    const { item } = origin;
    item.classList.add("show-info");
  };
  return (
    <div className="d-flex flex-grow-1 flex-shrink-1">
      <div className="flex-grow-1 flex-shrink-1 overflow-hidden image-slider">
        {listItem.length && (
          <ReactFullpage
            licenseKey={REACT_FULLPAGE_KEY}
            scrollingSpeed={500}
            scrollOverflowOptions={{ scrollOverflow: false }}
            onLeave={onSlideChanged}
            afterLoad={onAfterLoad}
            lockAnchors={false}
            normalScrollElements=".amenity-tree, .section-header, .header, .location"
            render={(
              (listItem, amenityIndex) =>
                ({ state, fullpageApi }) => {
                  fullpageRef.current = fullpageApi;
                  return (
                    <ReactFullpage.Wrapper>
                      {_.map(listItem, (value, index) => (
                        <div
                          key={index}
                          style={{ "--animate-duration": `1s` }}
                          className="image-slider-item section text-white animate__animated animate__fadeInLeft"
                        >
                          <SliderAmenities data={value['amenity_images']} />
                        </div>
                      ))}
                      <div className="section"></div>
                    </ReactFullpage.Wrapper>
                  );
                }
            )(listItem, amenityIndex)}
          />
        )}
      </div>
      <PerfectScrollbar
        className="amenity-tree"
        containerRef={(el) => (ps.current = el)}
      >
        <div>
          {amenity
            ? _.map(
              _.keys(
                pathname.includes("office")
                  ? {
                    firstFloor: amenity?.firstFloor,
                    secondFloor: amenity?.secondFloor,
                    rooftop: amenity?.rooftop,
                    safeEnv: amenity?.safeEnv,
                    security: amenity?.security,
                  }
                  : amenity
              ),
              (key, index) => (
                <div
                  key={index}
                  style={{ "--animate-duration": `${index / 2 + 1}s` }}
                  className={`amenity-item ${key ===
                    _.get(
                      listItem.find(
                        (item) => item["index"] === amenityIndex
                      ),
                      "part"
                    ) && "active"
                    }`}
                >
                  <div className="d-flex align-items-center text-uppercase font-weight-bold parent size-20">
                    <div className="item-number d-flex justify-content-center justify-content-center size-15 font-iCielGamine-bold text-white mr-3">
                      {index + 1}
                    </div>
                    {
                      <div className="font-iCielGamine-bold">
                        {pathname.includes("office") &&
                          key.toString() === "security"
                          ? t("amenities.security-office")
                          : t(`amenities.${key}`)}
                      </div>
                    }
                  </div>
                  <div className="amenity-item-child mt-3">
                    {_.map(amenity[key], (value, i) => (
                      <div
                        key={i}
                        className={`d-flex size-14 mt-2 child ${amenityIndex ===
                          _.get(
                            listItem.find(
                              (item) => item["id"] === value["id"]
                            ),
                            "index"
                          ) && "active"
                          }`}
                        onClick={() =>
                          changeSlideIndex(
                            _.get(
                              listItem.find(
                                (item) => item["id"] === value["id"]
                              ),
                              "index"
                            )
                          )
                        }
                      >
                        <div className="tick" />
                        <div className="text flex-shrink-1 flex-grow-1">
                          {value["name"]}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )
            )
            : ""}
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default AmenitiesImage;
