import React from "react";
// import slugify from "slugify";
import Bg_Green from "../../assets/images/location/bg-xanh-2-copy.png";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Bg_Gray from "../../assets/images/location/bg-xam.png";
import GetUrl from "../../components/common/GetUrl";
import { nl2ToBr } from "../../utils/helper";

const LocationItem = ({ data }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const renderName = (initName) => {
    // const nameSlug = slugify(initName, {
    //   replacement: "-",
    //   remove: undefined,
    //   lower: true,
    //   strict: false,
    //   locale: "vi",
    // });
    // if (nameSlug.includes("can-ho")) {
    //   return "Giới thiệu";
    // }
    return initName;
  };
  return (
    <>
      <div className="location-card">
        <div className="d-flex justify-content-between">
          {data &&
            data.map((item, index) => {
              const background = index !== 1 ? Bg_Gray : Bg_Green;
              const url = GetUrl(item.id);
              return (
                <div
                  key={index}
                  className={`location-card-item col-md-4 p-0 location-card-${
                    index + 1
                  }`}
                  id={`location-card-${index + 1}`}
                  style={{ backgroundImage: `url(${background})` }}
                >
                  <img src={item.image} alt="img" />
                  <div className="location-card-item-tag row "></div>
                  <h2>{renderName(item.name)}</h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: nl2ToBr(item.short_description),
                    }}
                  ></p>
                  <div className="row  justify-content-center form-submit-btn">
                    <a
                      href={
                        item.id === 6
                          ? url
                          : location.pathname.includes("office")
                          ? "/office" + url
                          : "/apartment" + url
                      }
                    >
                      <button className="btn btn submit">
                        {t("common.seeMore")}
                      </button>
                    </a>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default LocationItem;
