import React, { createRef, memo, useEffect, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { FLOOR_TYPE_NORMAL, FLOOR_TYPE_TUM } from "../../constants";
import ReactTooltip from "react-tooltip";
const SCALE_RESIZE = window.innerWidth > 1020 ? 20 : 15;
const initHostPost = (point, container) => {
  const containerWidth = container.clientWidth;
  const containerHeight = container.clientHeight;

  let { scale, position } = point;
  let offset = position.split(",");
  let origin = scale.split(",");

  let startX = parseFloat(offset[0]);
  let startY = parseFloat(offset[1]);
  let oldWidth = parseFloat(origin[0]) || 1;
  let oldHeight = parseFloat(origin[1]) || 1;

  startX = startX * (containerWidth / oldWidth);
  startY = startY * (containerHeight / oldHeight);

  point.x = startX - SCALE_RESIZE;
  point.y = startY - SCALE_RESIZE;
  return point;
};

const FloorHostSpot = (props) => {
  const { data } = props;
  const container = document.getElementById("floor-main-view");
  let dataPoint = useRef(initHostPost(data, container));
  let hotSpot = dataPoint.current;
  let hotRef = createRef();

  const getStyleHotspot = (position) => {
    return position ? { top: `${position.y}px`, left: `${position.x}px` } : {};
  };

  const setPoint = useCallback(() => {
    dataPoint.current = initHostPost(data, container);
    hotRef.current.style.left = `${dataPoint.current.x}px`;
    hotRef.current.style.top = `${dataPoint.current.y}px`;
  }, [dataPoint, hotRef, data, container]);
  const onClickApartment = () => {
    props.onClick();
  };
  useEffect(() => {
    setPoint();
    window.addEventListener("resize", setPoint);

    return () => window.removeEventListener("resize", setPoint);
  }, [setPoint]);

  const getClassNameHotspot = (position) => {
    const containerWidth = container.clientWidth;
    return position && position.x < containerWidth / 2 ? "flip" : "";
  };

  return (
    <>
      <Link
        to="#"
        onClick={onClickApartment}
        ref={hotRef}
        className={`hotspot ${getClassNameHotspot(hotSpot)}`}
        style={{ ...getStyleHotspot(hotSpot) }}
        data-tip={`${hotSpot.code} <br/> ${hotSpot.area}m<sup>2</sup>`}
        data-for={`main_${hotSpot.code}`}
      >
        <span>{hotSpot.code}</span>
      </Link>
      <ReactTooltip id={`main_${hotSpot.code}`} place="bottom" html={true} />
    </>
  );
};

export default memo(FloorHostSpot);
