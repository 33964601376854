export const TYPE = {
  SET_PAGE_INFO_DATA: "SET_PAGE_INFO_DATA",
};
export const setPageInfoData = (data) => {
  return {
    type: TYPE.SET_PAGE_INFO_DATA,
    payload: data,
  };
};

export const setPageInfo = (data) => (dispatch) => {
    dispatch(setPageInfoData(data));
};
