import axios from "axios";
import { BASE_URL } from "../utils/constants";

axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["locale"] = localStorage.getItem("i18nextLng")
  ? localStorage.getItem("i18nextLng")
  : "vi";

export function setupAxios() {
  const token = localStorage.getItem("authorizedToken");
  if (token) {
    axios.defaults.headers.common.Authorization = "Bearer " + token;
  }
}

export function setAxiosToken(token) {
  localStorage.removeItem("authorizedToken");
  localStorage.setItem("authorizedToken", token);
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

export function removeAxiosToken() {
  axios.defaults.headers.common["Authorization"] = "";
}

export function removeAxiosBranch() {
  axios.defaults.headers.common["Branch"] = "";
}

const processError = (err) => {
  throw err;
};

const responseBody = (res) => res.data;

const requester = {
  get: (url, params = {}) =>
    axios.get(url, { params: params }).then(responseBody).catch(processError),
  post: (url, data) =>
    axios.post(url, data).then(responseBody).catch(processError),
  put: (url, data) =>
    axios.put(url, data).then(responseBody).catch(processError),
  delete: (url) => axios.delete(url).then(responseBody).catch(processError),
  postWithFile: (url, data) =>
    axios
      .post(url, data, { headers: { "Content-Type": "multipart/form-data" } })
      .then(responseBody),
};

export default requester;
