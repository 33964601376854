import { TYPE } from '../actions/user'

const defaultState = null;

const user = (state = defaultState, action) => {
  switch (action.type) {
    case TYPE.SET_USER: {
      return action.payload;
    }
    default:
      return state
  }
}

export default user