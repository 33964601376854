import React, { memo, useState } from "react";
import TopHeader from "../../components/TopHeader";
import { useTranslation } from "react-i18next";
import gradient from "../../assets/images/gradient.svg";
const SectionA4 = ({ data = {}, onMouseUp, onMouseDown, isDrag }) => {
  const { t } = useTranslation();

  return (
    <div
      className="section-wrapper slide"
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    >
      {/* <TopHeader showPhone={false} customClass="container contact" /> */}

      <section className="home bg-section-mobile">
        <div className="home-a4 h-100">
          <div className="row">
            <div className="col-md-12 bg-section-mobile-image">
              <h5>{data.title}</h5>
              <img src={data.image} alt={data.title} />
            </div>
            <div className="col-md-12">
              <div className="home-content relative">
                <div
                  className="home-content-body"
                  id={`home-content-${data.id}`}
                >
                  <p className="mb-0 mt-4">{data.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="home bg-section-desktop bg-section-a4"
        style={{ background: `url(${data.image})` }}
      >
        <div className="home-a4 h-100">
          {/* <Menu fullpageApi={fullpageApi} activeIndex={SECTION_EPIC} /> */}
          {isDrag && (
            <div className="home-culture">
              <div className="home-culture-body" id={`home-content-${data.id}`}>
                <h5 className="">{data.title}</h5>
                <div className="divider"></div>
                <p className="mb-0 mt-4 show-p">{data.description}</p>
              </div>
            </div>
          )}
        </div>
        <div className="bgr-overlay"></div>
      </section>
    </div>
  );
};

export default memo(SectionA4);
