import React from "react";
import "../../../assets/styles/icons/icons.scss";

export const StarIcon = ({ color, nameClass, onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.016"
      height="19.983"
      viewBox="0 0 20.016 19.983"
      className={`icons_star ${nameClass}`}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <path
        fill={"none"}
        stroke="#012e50"
        d="M4.412 12.168l-1 6.148a.594.594 0 0 0 .873.615L9.5 16.049l5.217 2.884a.594.594 0 0 0 .873-.615l-1-6.148 4.239-4.35a.594.594 0 0 0-.335-1l-5.84-.892L10.036.351a.618.618 0 0 0-1.074 0L6.345 5.926.5 6.818a.593.593 0 0 0-.335 1z"
        transform="translate(.509 .461)"
      />
      <path
        id="star"
        d="M4.412,12.168l-1,6.148a.594.594,0,0,0,.873.615L9.5,16.049l5.217,2.884a.594.594,0,0,0,.873-.615l-1-6.148L18.829,7.82a.594.594,0,0,0-.335-1l-5.84-.892L10.036.351a.618.618,0,0,0-1.074,0L6.345,5.926.5,6.818a.593.593,0,0,0-.335,1Z"
        transform="translate(0.509 0.461)"
        fill={"none"}
        stroke="#012e50"
        strokeWidth={1}
      />
    </svg>
  );
};
