import React from "react";
import product from "../../../assets/images/product.png";
import CardProduct from "./CardProduct";
import { Row, Container } from "react-bootstrap";

const ProductView = (props) => {
  const listAvailabel = [
    {
      id: 232223211,
      image: product,
      favorited: false,
      floor: "805",
      info: [
        {
          name: "bedroom",
          amout: 1,
        },
        {
          name: "bathroom",
          amout: 2,
        },
        {
          name: "views",
          amout: "E",
        },
      ],
      acreage: {
        unit: "SQ.FT",
        value: 805,
      },
    },
    {
      id: 232223211,
      image: product,
      favorited: false,
      floor: "805",
      info: [
        {
          name: "bedroom",
          amout: 2,
        },
        {
          name: "bathroom",
          amout: 2,
        },
        {
          name: "views",
          amout: "E",
        },
      ],
      acreage: {
        unit: "SQ.FT",
        value: 805,
      },
    },
    {
      id: 232223211,
      image: product,
      favorited: false,
      floor: "805",
      info: [
        {
          name: "bedroom",
          amout: 2,
        },
        {
          name: "bathroom",
          amout: 2,
        },
        {
          name: "views",
          amout: "E",
        },
      ],
      acreage: {
        unit: "SQ.FT",
        value: 805,
      },
    },
    {
      id: 232223211,
      image: product,
      favorited: false,
      floor: "805",
      info: [
        {
          name: "bedroom",
          amout: 2,
        },
        {
          name: "bathroom",
          amout: 2,
        },
        {
          name: "views",
          amout: "E",
        },
      ],
      acreage: {
        unit: "SQ.FT",
        value: 805,
      },
    },
    {
      id: 232223211,
      image: product,
      favorited: false,
      floor: "805",
      info: [
        {
          name: "bedroom",
          amout: 2,
        },
        {
          name: "bathroom",
          amout: 2,
        },
        {
          name: "views",
          amout: "E",
        },
      ],
      acreage: {
        unit: "SQ.FT",
        value: 805,
      },
    },
    {
      id: 232223211,
      image: product,
      favorited: false,
      floor: "805",
      info: [
        {
          name: "bedroom",
          amout: 2,
        },
        {
          name: "bathroom",
          amout: 2,
        },
        {
          name: "views",
          amout: "E",
        },
      ],
      acreage: {
        unit: "SQ.FT",
        value: 805,
      },
    },
    {
      id: 232223211,
      image: product,
      favorited: false,
      floor: "805",
      info: [
        {
          name: "bedroom",
          amout: 2,
        },
        {
          name: "bathroom",
          amout: 2,
        },
        {
          name: "views",
          amout: "E",
        },
      ],
      acreage: {
        unit: "SQ.FT",
        value: 805,
      },
    },
    {
      id: 232223211,
      image: product,
      favorited: false,
      floor: "805",
      info: [
        {
          name: "bedroom",
          amout: 2,
        },
        {
          name: "bathroom",
          amout: 2,
        },
        {
          name: "views",
          amout: "E",
        },
      ],
      acreage: {
        unit: "SQ.FT",
        value: 805,
      },
    },
    {
      id: 232223211,
      image: product,
      favorited: false,
      floor: "805",
      info: [
        {
          name: "bedroom",
          amout: 2,
        },
        {
          name: "bathroom",
          amout: 2,
        },
        {
          name: "views",
          amout: "E",
        },
      ],
      acreage: {
        unit: "SQ.FT",
        value: 805,
      },
    },
    {
      id: 232223211,
      image: product,
      favorited: false,
      floor: "805",
      info: [
        {
          name: "bedroom",
          amout: 2,
        },
        {
          name: "bathroom",
          amout: 2,
        },
        {
          name: "views",
          amout: "E",
        },
      ],
      acreage: {
        unit: "SQ.FT",
        value: 805,
      },
    },
  ];
  return (
    <>
      <section className="product_view-availability">
        <Container className="flex-start">
          <Row>
            {listAvailabel &&
              listAvailabel.map((item, index) => (
                <CardProduct product={item} key={index.toString()} />
              ))}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ProductView;
