import React from "react";
import { Link } from "react-router-dom";

import BackArrowIcon from "../../assets/images/icons/back_arrow.svg";
import "../../assets/styles/floor/view2D.scss";
import ChevronDown from "../../assets/images/icons/chevron_white.svg";

const ViewFloor2D = () => {
  const dataFloors = [
    // {
    //     floor: 1,
    //     image:
    // }
  ];
  const handleMenuFloors = () => {
    const eleMenuSingle = document.getElementsByClassName("floorplans_view2d-menu-single")[0];
    eleMenuSingle.classList.toggle("open");
    const eleMenu = document.getElementsByClassName(
      "floorplans_view2d-menu-list"
    )[0];
    const eleMenuItem = document.querySelectorAll(".floorplans_view2d li");
    if (eleMenu.classList.contains("open")) {
      eleMenu.style.height = "0px";
    } else {
      let heightMenu = 0;
      for (let i = 0; i < eleMenuItem.length; i++) {
        heightMenu += eleMenuItem[i].clientHeight;
      }
      eleMenu.style.height = heightMenu + "px";
    }
    eleMenu.classList.toggle("open");
  };
  const closeMenuFloors = () => {
      const eleMenu = document.getElementsByClassName(
      "floorplans_view2d-menu-list"
    )[0];
     if (eleMenu.classList.contains("open")) {
      eleMenu.style.height = "0px";
      eleMenu.classList.remove("open");
    }
  }
  const handleChangeFloorView = () => {
    handleMenuFloors();
  };
  
  return (
    <div className="floorplans_view2d" onClick={(e) => {
        e.stopPropagation();
          closeMenuFloors();
    }}>
      <Link to="/floorplans"> 
        <img src={BackArrowIcon} alt="" />
        3D model
      </Link>
      <div className="floorplans_view2d-menu">
        <div
          className="floorplans_view2d-menu-single"
          onClick={ (e) => {
            e.stopPropagation()  
            handleMenuFloors()}}

        >
          Floors <img src={ChevronDown} alt="" />
        </div>
        <ul className="floorplans_view2d-menu-list">
          <li onClick={handleChangeFloorView}>Floor 14</li>
          <li onClick={handleChangeFloorView}>Floor 13</li>
          <li onClick={handleChangeFloorView}>Floor 12</li>
          <li onClick={handleChangeFloorView}>Floor 11</li>
        </ul>
      </div>
      <div className="floorplans_view2d-image_view">
          
      </div>
    </div>
  );
};

export default ViewFloor2D;
