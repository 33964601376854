import React, { useState } from "react";
import icon from "../../assets/images/desgin/group-12.svg";
import bg from "../../assets/images/desgin/bgDetails.png";
import img from "../../assets/images/desgin/pic-copy-3.png";
import Masonry from "react-responsive-masonry";
import { useTranslation } from "react-i18next";
import Category from "../../services/category";
import { nl2ToBr } from "../../utils/helper";

const CategoryItem = ({ data, index }) => {
  const { image, title, description } = data;
  const classOverlay =
    index > 2 ? "category_item--overlay top" : "category_item--overlay";
  return (
    <div className="category_item">
      <img key={index} src={image} alt="" />
      <div className={classOverlay}>
        <div className="overlay_tog"></div>
        {/* <h5>{nl2ToBr(title)}</h5> */}
        <div dangerouslySetInnerHTML={{ __html: nl2ToBr(description) }}></div>
      </div>
    </div>
  );
};

const DetailsCategory = ({ data }) => {
  const { t } = useTranslation();
  const { artdecor, indochine } = data;
  return (
    <div className="details-category">
      <div className="details-category-block">
        <div className="details-category-block-title">
          <img src={icon} alt="" />
          <span>{t("design.category.artdecor")}</span>
        </div>
        <Masonry className="gallery" columnsCount={3} gutter="10px">
          {artdecor &&
            artdecor.map((item, index) => (
              <CategoryItem data={item} index={index} key={index} />
            ))}
        </Masonry>
      </div>
      <div className="details-category-block">
        <div className="details-category-block-title">
          <img src={icon} alt="" />
          <span>{t("design.category.indochine")}</span>
        </div>
        <Masonry className="gallery" columnsCount={3} gutter="10px">
          {indochine &&
            indochine.map((item, index) => (
              <CategoryItem data={item} index={index} key={index} />
            ))}
        </Masonry>
      </div>
    </div>
  );
};

export default DetailsCategory;
