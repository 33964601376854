import React from "react";

const ImageBanner = ({ bannerImage }) => {
  if (!bannerImage) return null;
  return (
    <div className="news_detail_page--banner">
      <img alt="img" src={bannerImage} />
    </div>
  );
};

export default ImageBanner;
