import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "../routes/index";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";

function App() {
  const title = useSelector((state) => state.page.title);
  const description = useSelector((state) => state.page.description);

  useEffect(() => {
    document.title = title;
    document.getElementsByTagName("META")[3].content= description;
  }, [title, description]);

  return (
    <Router>
      <Routes />
    </Router>
  );
}

export default App;
