import React, { useCallback, useRef, useState, memo, useEffect } from "react";
import PlayIcon from "../../assets/images/home/play_button.svg";
import { useHomeVideo } from "../../hooks/home";
import { showTitle2, showTitle } from "../../utils/helper";
const SectionVideo = ({ language }) => {
  const [data, loading] = useHomeVideo();
  const videoRef = useRef();
  const [play, setPlay] = useState(false);

  const onPlayVideo = useCallback(
    (e) => {
      videoRef.current.play();
      setPlay(true);
    },
    [videoRef]
  );

  const onPauseVideo = useCallback(
    (e) => {
      videoRef.current.pause();
      setPlay(false);
    },
    [videoRef]
  );

  return (
    <section className="home section home-content-video">
      {loading ? (
        <div className="page-loading">{language("loading")}</div>
      ) : (
        <div className="home-video">
          <video
            ref={videoRef}
            onClick={onPauseVideo}
            loop
            playsInline
            preload="metadata"
          >
            <source src={`${data.video}#t=0.5`} type="video/mp4"></source>
            Your browser does not support HTML5 video.
          </video>

          <div
            className={`home-video-action animate__animated ${
              play ? "animate__fadeOut d-none" : "animate__fadeIn"
            }`}
          >
            <img src={PlayIcon} alt="play video" onClick={onPlayVideo} />
          </div>

          <div
            className={`home-title text-title show-title ${
              play ? "animate__fadeOut d-none" : ""
            }`}
          >
            <h5
              dangerouslySetInnerHTML={{
                __html: language("home.video.title"),
              }}
            ></h5>
            <span className="d-none d-lg-flex home-index show-sub-title">
              <span>01</span>
              <span className="text-devider"></span>
              <span>{language("home.video.welcome")}</span>
            </span>
          </div>
        </div>
      )}
    </section>
  );
};

export default memo(SectionVideo);
