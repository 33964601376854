import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { GET_NEWS_LIST } from "../../constants";
import NewsBlock from "./NewsBlock";
import TagHead from "../../components/TagHead";
import Button from "../../components/Button";
import Filters from "./Filters";
import { getDataByParams } from "../../utils";
import Footer from "../../components/Footer";

const PER_PAGE_NEWS = 10;

const NewsPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [selectedCategory, setSelectedCategory] = useState(id | 0);
  const [newsData, setNewsData] = useState({
    loading: true,
    data: null,
    paginate: null,
  });
  const showMoreButton = () => {
    fetchDataNewsPage({
      per_page: PER_PAGE_NEWS,
      page: newsData.paginate.next,
      news_category_id: selectedCategory,
    }).then((res) => {
      const { data, paginate } = res.data;
      setNewsData({
        loading: false,
        data: [...newsData.data, ...data],
        paginate,
      });
    });
  };

  const fetchDataNewsPage = (params) => {
    return getDataByParams(GET_NEWS_LIST, params);
  };
  const fetchDataNews = (id) => {
    if (!newsData.loading) {
      setNewsData((prev) => ({ ...prev, loading: true }));
    }
    fetchDataNewsPage({
      per_page: PER_PAGE_NEWS,
      news_category_id: id | 0,
    }).then((res) => {
      const { data, paginate } = res.data;
      setNewsData({
        loading: false,
        data,
        paginate,
      });
    });
  };
  const onFilterCategory = (idCategory) => {
    setSelectedCategory(idCategory);
    fetchDataNews(idCategory);
  };
  useEffect(() => {
    fetchDataNews(id);
  }, []);

  return (
    <div className="news_page">
      <div className="news_page-container">
        <Filters id={selectedCategory} setFilter={onFilterCategory} />
      </div>
      <div className="news_page_desktop">
        {/* Fixed Element  */}
        <TagHead title={t("news.title")} />
        {/* content  */}
        {newsData.loading ? null : (
          <div className="news_page--content_wrapper show-info">
            <div className="news_page--list">
              {newsData.data &&
                newsData.data.map((news, index) => (
                  <NewsBlock data={news} key={index} />
                ))}
            </div>
          </div>
        )}

        {newsData.paginate && newsData.paginate.next && (
          <Button
            classNameWrapper={"news_page-btn_loadmore"}
            onClick={showMoreButton}
            title={t("common.seeMore")}
          />
        )}
      </div>
      {!newsData.loading && <Footer />}
    </div>
  );
};
export default NewsPage;
