import { combineReducers } from 'redux';
import user from './user';
import amenity from './amenity';
import relateInformation from "./relate-information";
import page from "./page";
import design from "./design";



const reducers = combineReducers({
  user,
  amenity,
  relateInformation,
  page,
  design
})

export default reducers