import React from "react";
import { Parallax } from "react-parallax";

import { useTranslation } from "react-i18next";
import LocationItem from "./LocationItem";
import bgLocation from "../../assets/images/bgLocation.png";
import FormContact from "../../components/FormContact";
import { useSelector } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
// import { PHONE_CONTACT } from "../../constants";
// import PhoneIcon from "../../assets/images/icons/path.svg";
import TopHeader from "../../components/TopHeader";
const RelateInfo = ({ customClass, showPhone, showLine }) => {
  const data = useSelector((state) => state.relateInformation.dataList);
  const { t } = useTranslation();
  return (
    <>
      <section className=" relate-component section">
        <Parallax
          renderLayer={(percentage) => (
            <>
              <div
                className={`location-body d-flex align-items-center ${customClass} relation-info-animation`}
                style={{ backgroundImage: `url(${bgLocation})` }}
              >
                <div className="location-body-content w-100 d-flex justify-content-center">
                  <PerfectScrollbar className="location-body-group" scrollingThreshold={0}>
                    <div className="container">
                      <TopHeader
                        tag={t("location.tag")}
                        // title={t("location.titleInfomation")}
                        showPhone={showPhone}
                      />
                      <div className="location-body-content-main">
                        <LocationItem data={data} />
                      </div>
                      <div className="location-body-content-contact">
                        <FormContact />
                      </div>
                    </div>
                  </PerfectScrollbar>
                </div>
              </div>
            </>
          )}
        ></Parallax>
      </section>
    </>
  );
};

export default RelateInfo;
