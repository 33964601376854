import { useFetch } from "./index";
import services from "../services";

export const useGetLocation = (typeBlock) => {
  return useFetch(
    typeBlock?.includes("apartment")
      ? services.Location.getLocation
      : services.Location.getLocationOffice
  );
};

export const useGetContentLocationOffice = () => {
  return useFetch(services.Location.getContentLocationOffice
  );
};
