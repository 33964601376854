import React from "react";

import TagImage from "../assets/images/components/tag_yellow.svg";

const TagTitle = ({ title }) => {
  return (
    <div className="component_tag_title">
      <img alt="" src={TagImage} />
      <span>{title}</span>
    </div>
  );
};

export default TagTitle;
