import { useGetRelateInfo } from "../hooks/relate-info";
import _ from "lodash";

export const TYPE = {
  SET_RELATE_INFORMATION: "SET_RELATE_INFORMATION",
}

export const setRelateInformation = (data) => {
  return {
    type: TYPE.SET_RELATE_INFORMATION,
    payload: data,
  }
}

export const getRelateInfo = (listId) => (dispatch) => {
  dispatch(setRelateInformation(_.first(useGetRelateInfo(listId))))
}
