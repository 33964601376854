import { useFetch } from "./index";
import services from "../services";

export const useGetPageInfo = (pageId) => {
  return useFetch(
    pageId?.includes("office")
      ? services.Design.getDescOffice
      : services.Page.getPageInfo,
    pageId?.includes("office") ? null : pageId
  );
};
