import { TYPE } from "../actions/designs";

const defaultState = {
  data: {}
};

const design = (state = defaultState, action) => {
  switch (action.type) {
    case TYPE.SET_DESIGN_INFO_DATA: {
      return { ...state, 
        data: action.payload
      };
    }
    default:
      return { ...state };
  }
};

export default design;
