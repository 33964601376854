import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { useHomeScale } from "../../hooks/home";
// import bg_mock from "../../assets/images/mock-intro.jpeg";
import bg_logo from "../../assets/images/mock-icon-info.png";
import "./info.scss";

const SectionInformation = () => {
  const [data, loading] = useHomeScale();
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <div>
      {loading ? (
        <div className="page-loading">{t("loading")}</div>
      ) : (
        <div className="page-info">
          <div className="page-info__text">
            <p className="home-index show-sub-title mt-2 mt-lg-0 mb-2 mb-lg-3">
              <span>01</span>
              <span className="text-devider"></span>
              <span>{t("apartment.heading")}</span>
            </p>
            <h4
              className="show-title"
              dangerouslySetInnerHTML={{ __html: t("apartment.title") }}
            />
            <div>
              <div className="">
                <div className=" block-devider show-line mr-lg-3"></div>
              </div>
              {location.pathname.includes("apartment") && (
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t("home.project.nameIntroBlockOffice", {
                        apartment: data.name_block_apartment,
                      }),
                    }}
                  />
                  <div className="row mt-3 show-scale-2">
                    <div className="col-6 col-lg-7">
                      <div className="row">
                        <div
                          className="col-12 col-md-5 home-project-detail-item"
                          dangerouslySetInnerHTML={{
                            __html: t("home.project.totalApartment", {
                              num: data.num_apartment,
                            }),
                          }}
                        ></div>
                        <div className="col-12 col-md-7 mt-3 mt-md-0">
                          <div
                            className="d-flex flex-column"
                            dangerouslySetInnerHTML={{
                              __html: t("home.project.textFloor", {
                                num_floor: data.num_floor,
                                num_garage: data.num_garage,
                              }),
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-lg-5">
                      <div
                        className="d-flex flex-column"
                        dangerouslySetInnerHTML={{
                          __html: t("home.project.areaApartment", {
                            from: data.area_apartment_from,
                            to: data.area_apartment_to,
                          }),
                        }}
                      ></div>
                    </div>
                  </div>
                  {/* <div className="mt-3 show-scale-2">
                    <div>
                      <img
                        src={bg_logo}
                        alt="img"
                        width="150px"
                        style={{ position: "relative", left: "-20px" }}
                      />
                    </div>
                    <p className="normal">{t("page_information.manage")}</p>
                  </div> */}
                </>
              )}
            </div>
          </div>
          <div className="page-info__img">
            <img src={data.image} alt="img" />
          </div>
        </div>
      )}
    </div>
  );
};

export default SectionInformation;
