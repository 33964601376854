import React from "react";

const Button = ({
  title,
  onClick,
  type = "button",
  classNameButton,
  classNameWrapper,
}) => {
  const classNameWrapperBtn = `epic_button_wrapper ${
    classNameWrapper ? classNameWrapper : ""
  }`;
  const classNameBtn = `epic_button ${classNameButton ? classNameButton : ""}`;
  return (
    <div className={classNameWrapperBtn}>
      <button className={classNameBtn} onClick={onClick} type={type}>
        {title}
      </button>
    </div>
  );
};

export default Button;
