import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import NewsBlock from "../NewsBlock";
import LazyLoad from "react-lazyload";
import Slider from "react-slick";
import Button from "../../../components/Button";
import TagTitle from "../../../components/TagTitle";

const NewsRelated = ({ data, idCategory }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    centerPadding: "50px",
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnDotsHover: true,
    pauseOnFocus: true,
    pauseOnHover: true,
  };
  const moveToAllCategory = () => {
    history.push(`/news/${idCategory}`);
  };
  if (data.length === 0) return null;
  return (
    <>
      <div className="news_detail_page--related desktop container">
        <div className="wrapper">
          <div className="location-body-header">
            <div className="tag">
              <span>{t("location.tag")}</span>
            </div>
            <h3>{t("news.related")}</h3>
          </div>
          <LazyLoad height={551} offset={-151} once>
            <div className="news_page--list">
              {data.map((news, index) => (
                <NewsBlock data={news} key={index} />
              ))}
            </div>
          </LazyLoad>
        </div>
      </div>
      <div className="news_detail_page--related mobie show-info">
        <TagTitle title={t("news.related")} />

        <Slider {...settings}>
          {data.map((news, index) => (
            <NewsBlock data={news} key={index} />
          ))}
        </Slider>
        <Button
          classNameWrapper={"mt-5"}
          onClick={moveToAllCategory}
          title={t("common.seeAll")}
        />
      </div>
    </>
  );
};

export default NewsRelated;
