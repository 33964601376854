import React, { useEffect, useCallback, memo } from "react";
import { transformPoint, getRandomNumber } from "../../utils/helper";
const NAME_OFFSET_TOP = window.innerWidth > 1024 ? 100 : 30;
const ARROW_HEIGHT = window.innerWidth > 1024 ? 6 : 3;
const LocationPoint = ({ point, container }) => {
  const textPosition = [0, 0, 20, 40, 60];
  const changePoint = useCallback(() => {
    let newPoint = transformPoint(point, container);
    let pointEl = document.getElementById(`location-point-${newPoint.id}`);
    let pointNameEl = pointEl.querySelector(".location-introduce-point-name");
    let pointArrowEl = pointEl.querySelector(".location-introduce-point-arrow");
    let offsetTop =
      NAME_OFFSET_TOP + textPosition[parseInt(point.text_position)];
    if (pointEl) {
      pointEl.style.left = `${newPoint.x}px`;
      pointEl.style.top = `${newPoint.y}px`;
      let offsetTopName = newPoint.y - offsetTop + pointNameEl.offsetHeight;
      let arrowHeight = newPoint.y - offsetTop;

      // --- point name position ---
      pointNameEl.style.transform = `translate(-51px, -${offsetTopName}px)`;

      // --- point arrow position ---
      pointArrowEl.style.height = `${arrowHeight}px`;
      pointArrowEl.style.top = `-${arrowHeight - ARROW_HEIGHT}px`;
      pointArrowEl.style.left = `5px`;
    }
  }, [point, container]);

  useEffect(() => {
    let offsetTop =
      NAME_OFFSET_TOP + textPosition[parseInt(point.text_position)];
    let pointEl = document.getElementById(`location-point-${point.id}`);
    let pointNameEl = pointEl.querySelector(".location-introduce-point-name");
    let pointArrowEl = pointEl.querySelector(".location-introduce-point-arrow");
    let offsetTopName = point.y - offsetTop + pointNameEl.offsetHeight;
    let arrowHeight = point.y - offsetTop;
    // --- point name position ---
    pointNameEl.style.transform = `translate(-51px, -${offsetTopName}px)`;

    // --- point arrow position ---
    pointArrowEl.style.height = `${arrowHeight}px`;
    pointArrowEl.style.top = `-${arrowHeight - ARROW_HEIGHT}px`;
    pointArrowEl.style.left = `5px`;
  }, [point, container]);

  useEffect(() => {
    window.addEventListener("resize", changePoint);

    return () => window.removeEventListener("resize", changePoint);
  }, [changePoint]);

  useEffect(() => {
    if (window.innerWidth < 1024) {
      window.dispatchEvent(new Event("resize"));
    }
  }, [container]);
  if (point.main) {
    return (
      <div
        className={`location-introduce-point points`}
        id={`location-point-${point.id}`}
        style={{
          top: point.y,
          left: point.x,
        }}
      >
        <span className="location-introduce-point-arrow"></span>
        <span className="location-introduce-point-name point-main"></span>
      </div>
    );
  } else {
    return (
      <div
        className={`location-introduce-point points `}
        id={`location-point-${point.id}`}
        style={{
          top: point.y,
          left: point.x,
        }}
      >
        <span className="location-introduce-point-name">{point.name}</span>
        <span className="location-introduce-point-arrow"></span>
      </div>
    );
  }
};

export default memo(LocationPoint);
