import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import HotSpot from "./HotSpot";
import {useDesignMaterial} from "../../hooks/materials";

const MaterialMobile = () => {
  const {t} = useTranslation();
  const [designs, loading] = useDesignMaterial();
  const [activeDesign, setActiveDesign] = useState(null);

  useEffect(() => {
    if (!loading && designs && designs.length > 0) {
      let active = designs[0];
      setActiveDesign(active);
    }
  }, [designs, loading]);

  const onChangeActiveDesign = useCallback((e, item) => {
    setActiveDesign(item);
  }, [setActiveDesign]);

  return (
    <div className="material-mobile">
      <div className="design design-custom container">
        {
          loading
            ? <div className="page-loading">{t("loading")}</div>
            : <>
              {
                activeDesign && activeDesign.design_materials && <div className="design-content w-100" style={{ background: `url(${activeDesign.image}) no-repeat center center` }}>
                  {
                    activeDesign.design_materials.map((item) => <HotSpot data={item} key={item.id} />)
                  }
                </div>
              }
            </>
        }
      </div>
      <div className="d-flex justify-content-center">
        {
          designs && designs.map((item) => {
            return <div className={`design-nav-item ${(item.id === activeDesign?.id) && 'active'}`} key={item.id} onClick={(e) => onChangeActiveDesign(e, item)}>
              <img src={item.image} alt={item.description} />
            </div>
          })
        }
      </div>
    </div>
  )
}

export default MaterialMobile;
