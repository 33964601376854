import React from "react";
import IconClock from "../../../assets/images/news/clock.svg";
import Breadcrumb from "../../../components/Breadcrumb";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { formatTime } from "../../../utils/functions";

const NewsContent = ({ data }) => {
  const { t } = useTranslation();
  const {
    category,
    title,
    content,
    created_at,
    description,
    created,
    created_by,
  } = data;
  const breadcrumbs = [
    {
      title: t("news.title"),
      url: "/news",
    },
    {
      title: data.category?.name,
      url: `/news/${data.category?.id}`,
    },
  ];

  return (
    <div className="news_detail_page--content container">
      <div className="wrapper">
        <Breadcrumb data={breadcrumbs} />
        <div className="news_detail_page--content--head">
          <h3>{title}</h3>
          <div className="time_news">
            {created_by && (
              <>
                <span className="news-source">{created_by}</span>
                <span className="px-1">|</span>
              </>
            )}
            <span className="news-created-at">
              {formatTime(created, "dddd, D/M/Y HH:mm (Z)", i18next.language)}
            </span>
          </div>
          <span className="description">"{description}"</span>
        </div>
        <div className="news_detail_page--content--body">
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
      </div>
    </div>
  );
};

export default NewsContent;
