import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import bg from "../../assets/images/desgin/bgDetails.png";
import Category from "./DetailsCategory";
import { useGetDesign } from "../../hooks/design";
import bgContent from "../../assets/images/desgin/bg-content.png";
import icon from "../../assets/images/desgin/group-22.svg";
import { nl2ToBr } from "../../utils/helper";
import TopHeader from "../../components/TopHeader";
import { useDispatch } from "react-redux";
import { setDesignInfo } from "../../actions/designs";

const Details = ({ active }) => {
  const { t } = useTranslation();
  const [data, loading] = useGetDesign();
  const dispatch = useDispatch();
  useEffect(() => {
    if (active) {
      dispatch(setDesignInfo(data));
    }
  }, [data, loading, active]);

  if (loading) return <div className="page-loading">{t("loading")}</div>;

  return (
    <div className="details" style={{ backgroundImage: `url(${data.image})` }}>
      <div className="row">
        {/* <TopHeader showPhone={false} customClass="container contact-detail"/> */}

        <div className="details-item">
          {/* <div
            className="details-desc"
            style={{ backgroundImage: `url(${bgContent})` }}
          >
            <div className="details-desc-content container">
              <div className="title d-flex">
                <div className="d-flex mr-3">
                  <img src={icon} alt="" />
                </div>
                <div>
                  <h3
                    dangerouslySetInnerHTML={{ __html: nl2ToBr(data.title) }}
                  />
                </div>
              </div>
              <div
                className="details-content"
                dangerouslySetInnerHTML={{ __html: nl2ToBr(data.description) }}
              />
            </div>
          </div> */}
        </div>
        <div className="col detail-block">
          <Category data={data} />
        </div>
      </div>
    </div>
  );
};

export default Details;
