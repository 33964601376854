import React, { memo, createRef, useEffect, useCallback, useRef } from "react";
const SCALE_RESIZE = window.innerWidth > 1024 ? 7 : 7;
const initHostPost = (point, container) => {
  const containerWidth = container.width;
  const containerHeight = container.height;
  let scale = point.thumb_scale;
  let position = point.thumb_position;
  let offset = position.split(",");
  let origin = scale.split(",");
  let startX = parseFloat(offset[0]);
  let startY = parseFloat(offset[1]);
  let oldWidth = parseFloat(origin[0]) || 1;
  let oldHeight = parseFloat(origin[1]) || 1;
  if (containerHeight == 0) {
    startX = (startX * containerWidth) / oldWidth;
    startY = (startY * containerWidth) / oldWidth;
  } else {
    startY = (startY * containerHeight) / oldHeight;
    startX = (startX * containerHeight) / oldHeight;
  }
  point.x = startX - SCALE_RESIZE;
  point.y = startY - SCALE_RESIZE;
  return point;
};
const ThumbPoint = (props) => {
  const { data, height, width, active } = props;
  let dataPoint = useRef(initHostPost(data, { width, height }));
  let item = dataPoint.current;
  let hotRef = createRef();

  const setPoint = useCallback(() => {
    dataPoint.current = initHostPost(data, { width, height });
    hotRef.current.style.left = `${dataPoint.current.x}px`;
    hotRef.current.style.top = `${dataPoint.current.y}px`;
  }, [data, hotRef, height, width]);

  useEffect(() => {
    setPoint();
    window.addEventListener("resize", setPoint);

    return () => window.removeEventListener("resize", setPoint);
  }, [data, hotRef, height, width]);

  const getStyle = (position) => {
    return position ? { top: `${position.y}px`, left: `${position.x}px` } : {};
  };

  const showApartment = (item) => {
    props.showApartment(item);
  };
  return (
    <a
      className={`thumb__link ${active ? "active" : ""}`}
      style={{ ...getStyle(item) }}
      ref={hotRef}
      onClick={() => showApartment(data)}
    >
      {data.code}
    </a>
  );
};
export default memo(ThumbPoint);
