import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import SectionVideo from "./SectionVideo";
// import SectionLocation from "./SectionLocation";
// import SectionStory from "./SectionStory";
import SectionProject from "./SectionProject";
// import SectionA4 from "./SectionA4";
import { useTranslation } from "react-i18next";
import { useHomeCulture } from "../../hooks/home";
// import RelateInfo from "../Location/RelateInfo";
import { useDispatch } from "react-redux";
import { getRelateInfo } from "../../actions/relate-information";
// import PhoneFixed from "../../components/PhoneFixed";
import Footer from "../../components/Footer";
import ScrollToTop from "../../components/common/ScrollToTop";
import {
  REACT_FULLPAGE_KEY,
  REACT_FULLPAGE_RESPONSIVE_WIDTH,
} from "../../constants";
import ReactFullpage, { f } from "@fullpage/react-fullpage";
import { setPageInfo } from "../../actions/page";
import { PageType } from "../../enums/index";
import { useGetPageInfo } from "../../hooks/page";
// import Slider from "react-slick";
// import Menu from "./Menu";
import { translateVertical } from "../../utils/helper";

const Homepage = (props) => {
  let fullPageRef = useRef();
  const [activeIndex, setActiveIndex] = useState(0);
  // const [isDrag, setIsDrag] = useState(true);
  const { t } = useTranslation();
  const [data, loading] = useHomeCulture();
  const dispatch = useDispatch();
  const windownWidth = window.innerWidth;
  // const [currentIndexSlider, setCurrentIndexSlider] = useState(0);
  let sliderRef = useRef();
  const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 3500,
    pauseOnHover: false,
    speed: 1000,
    slidesToShow: 1,
    centerPadding: "50px",
    slidesToScroll: 1,
    arrows: true,
  };

  const [page, pageLoading] = useGetPageInfo(PageType.Home);
  if (dispatch) {
    dispatch(getRelateInfo("2,3,6"));
  }
  const totalSlide = data && data.images.length;
  useEffect(() => {
    if (!pageLoading) {
      dispatch(setPageInfo(page));
    }
  }, [page, pageLoading]);

  const onLeaveSlide = (origin, destination, direction) => {
    let { item, index } = destination;
    let originItem = origin.item;

    if (originItem.classList.contains("changing")) {
      return false;
    }

    // Clear style of old section
    item.classList.add("changing");
    originItem.classList.add("show-text", "changing");
    originItem.style.removeProperty("z-index");

    // Add style style for destination section
    item.style.zIndex = 10;
    if (direction == "down") {
      item.style.transform = `translate(0, -100%) translate3d(0px, 0px, 0px)`;
    }

    translateVertical(function (value) {
      let trans = direction === "down" ? 100 - value : -(100 - value);
      item.style.transform = `translate(0, ${trans}%) translate3d(0px, 0px, 0px)`;
      originItem.classList.remove("show-info");
      if (value === 100) {
        item.style.transform = "matrix(1, 0, 0, 1, 0, 0)";
        item.classList.remove("changing");
        originItem.classList.remove("show-text", "changing");
        item.classList.add("show-text");
        item.classList.add("show-info");
        setTimeout(() => {
          originItem.style.transform =
            "translate(0%, 100%) matrix(1, 0, 0, 1, 0, 0)";
        }, 100);
      }
    });

    // const slickCurrent = item.querySelector(".slick-current");
    // const homeCulture =
    //   slickCurrent && slickCurrent.querySelector(".home-culture");
    // const originSlickCurrent = originItem.querySelector(".slick-current");
    // const originHomeCulture =
    //   originSlickCurrent && originSlickCurrent.querySelector(".home-culture");

    // originHomeCulture &&
    //   originHomeCulture.classList.remove(
    //     "animate__animated",
    //     "animate__fadeInUp",
    //     "animate__delay-1s",
    //     "animate__duration_1s"
    //   );
    // homeCulture &&
    //   homeCulture.classList.add(
    //     "animate__animated",
    //     "animate__fadeInUp",
    //     "animate__delay-1s",
    //     "animate__duration_1s"
    //   );
  };

  const onLoadSlide = (origin, destination, direction) => {
    if (fullPageRef.current) {
      let activeSlide = fullPageRef.current.getActiveSection();
      let anchor = parseInt(activeSlide.anchor);
      setActiveIndex(anchor);
    }
    const { item } = destination;
    item.classList.add("show-info");
  };
  // const afterChangeSlider = (currentIndex) => {
  //   setCurrentIndexSlider(currentIndex);
  // };

  // const beforeChangeSlider = (prev, next) => {
  //   const prevSlideElement = sliderRef.current.innerSlider.list.querySelector(
  //     `[data-index="${prev}"]`
  //   );
  //   const nextSlideElement = sliderRef.current.innerSlider.list.querySelector(
  //     `[data-index="${next}"]`
  //   );
  //   const nextItem = nextSlideElement.querySelector(".home-culture");
  //   const prevItem = prevSlideElement.querySelector(".home-culture");

  //   prevItem.classList.remove(
  //     "animate__animated",
  //     "animate__fadeInUp",
  //     "animate__delay-1s",
  //     "animate__duration_1s"
  //   );
  //   nextItem.classList.add(
  //     "animate__animated",
  //     "animate__fadeInUp",
  //     "animate__delay-1s",
  //     "animate__duration_1s"
  //   );
  // };

  // let mousedownTimer;

  // const handleMouseUp = () => {
  //   if (mousedownTimer) {
  //     clearTimeout(mousedownTimer);
  //   }
  //   setIsDrag(true);
  // };

  // const handleMouseDown = () => {
  //   mousedownTimer = setTimeout(() => {
  //     setIsDrag(false);
  //   }, 250);
  // };

  return (
    <div className="homepage">
      <ScrollToTop />
      {/* <Menu fullpageApi={fullPageRef.current} activeIndex={activeIndex} /> */}
      {windownWidth > REACT_FULLPAGE_RESPONSIVE_WIDTH ? (
        <ReactFullpage
          licenseKey={REACT_FULLPAGE_KEY}
          scrollingSpeed={500}
          onLeave={onLeaveSlide}
          afterLoad={onLoadSlide}
          lockAnchors={false}
          responsiveWidth={REACT_FULLPAGE_RESPONSIVE_WIDTH}
          scrollHorizontally={true}
          anchors={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]}
          arrowNavigation={true} // use arrow keys
          navigation={false} // use dots navigatio
          loopTop={false}
          scrollBar={false} // use the browser default scrollbar
          render={({ state, fullpageApi }) => {
            fullPageRef.current = fullpageApi;
            return (
              <ReactFullpage.Wrapper>
                <SectionVideo language={t} />
                <SectionProject language={t} />
                {/* <SectionLocation fullpageApi={fullpageApi} />
                <SectionStory fullpageApi={fullpageApi} />
                <div className="section section-slider">
                  <Slider
                    {...settings}
                    // afterChange={afterChangeSlider}
                    ref={sliderRef}
                    // beforeChange={beforeChangeSlider}
                  >
                    {!loading &&
                      data &&
                      data.images.map((item) => (
                        <SectionA4
                          data={item}
                          key={item.id}
                          onMouseUp={handleMouseUp}
                          onMouseDown={handleMouseDown}
                          isDrag={isDrag}
                        />
                      ))}
                  </Slider>
                </div>
                <RelateInfo
                  showPhone={false}
                  customClass="location location-home"
                /> */}
              </ReactFullpage.Wrapper>
            );
          }}
        />
      ) : (
        <div className="fullpage">
          <SectionVideo language={t} />
          <SectionProject language={t} />
          {/* <SectionLocation />
          <SectionStory />
          {!loading &&
            data &&
            data.images.map((item) => <SectionA4 data={item} key={item.id} />)}

          <RelateInfo customClass="location location-home" /> */}
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Homepage;
