import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { useGetContact } from "../hooks/contact";
import InputNumberPhone from "react-phone-number-input";
import _ from "lodash";

import Toast from "./form/Toast";
import { getAPI_BACKEND_URL } from "../utils";
import bgFormContact from "../assets/images/bgFormContact/bg-den.png";

export default function Contact({ haveContent }) {
  const { t } = useTranslation();
  const [statusContact, setStatusContact] = useState({
    errors: false,
    loading: false,
  });
  const [dataContact, loading] = useGetContact();
  const [errorNumberphone, setErrorNumberphone] = useState(false);
  const [toast, setToast] = useState({
    visible: false,
    success: false,
  });

  const objContactValidate = {
    name: yup.string().required(t("contactForm.errorName")),
    phone: yup.string().min(9),
    email: yup.string().email(t("contactForm.errorEnter")),
  };

  if (haveContent) {
    objContactValidate["content"] = yup.string();
  }

  const contactSchemaValidate = yup.object().shape(objContactValidate);
  const showToast = (success) => {
    setToast({
      visible: true,
      success,
    });
    setTimeout(() => {
      setToast({
        visible: false,
        success,
      });
    }, 3000);
  };
  const submitContact = (values, { resetForm }) => {
    const url = "contacts/create";

    if (values.phone.trim() === "") {
      setErrorNumberphone(true);
    } else {
      setStatusContact({
        errors: false,
        loading: true,
      });
      axios
        .post(getAPI_BACKEND_URL(url), { ...values })
        .then((res) => {
          showToast(true);
          setStatusContact({
            errors: false,
            loading: false,
          });
          resetForm({});
        })
        .catch((error) => {
          showToast(false);
          setStatusContact({
            error: true,
            loading: false,
          });
        });
    }
  };

  if (loading) return null;
  return (
    <Formik
      validationSchema={contactSchemaValidate}
      initialValues={{
        name: "",
        phone: "",
        email: "",
        content: "",
      }}
      onSubmit={submitContact}
    >
      {(formik) => {
        const { errors, touched, setFieldValue, values, handleSubmit } = formik;
        return (
          <div
            className={`contact-form-submit ${
              haveContent ?? "contact-form-submit-wrapper"
            }`}
            style={{ backgroundImage: `url(${bgFormContact})` }}
          >
            <div className="contact-form-wrapper pl-3 pr-3">
              <Toast visible={toast.visible} success={toast.success} />

              <div className="contact-form-submit-tag row m-auto"></div>
              <div className="contact-form-submit-title">
                {dataContact.title}
              </div>
              <div className="contact-form-submit-desc mt-1">
                <div className="content">{dataContact.description}</div>
              </div>
              <Form>
                <div className="contact-form-submit-group row m-auto w-100">
                  <div className={`control_form ${!haveContent && "col"}`}>
                    <div
                      className={
                        errors.name && touched.name
                          ? "input-error input-form"
                          : " input-form"
                      }
                    >
                      <Field
                        placeholder={t("contactForm.name")}
                        type="name"
                        name="name"
                        id="name"
                        className={
                          errors.name && touched.name ? "input-error" : null
                        }
                      />
                    </div>

                    <ErrorMessage
                      name="name"
                      component="span"
                      className="error"
                    />
                  </div>
                  <div className={`control_form ${!haveContent && "col"}`}>
                    <div
                      className={
                        errorNumberphone
                          ? " input-error input-form"
                          : "input-form"
                      }
                    >
                      <InputNumberPhone
                        placeholder={t("contactForm.phone")}
                        country={"vn"}
                        defaultCountry="VN"
                        value={values.phone}
                        required={true}
                        maxLength={17}
                        minLength={7}
                        onBlur={(e) => {
                          if (e.target.value === "") {
                            setErrorNumberphone(true);
                          } else if (e.target.value.length < 8) {
                            setErrorNumberphone(true);
                          }
                        }}
                        onChange={(value) => {
                          if (errorNumberphone) setErrorNumberphone(false);
                          setFieldValue("phone", value ? value : "");
                        }}
                      />
                    </div>
                    {errorNumberphone && (
                      <span className="error">
                        {t("contactForm.errorNumberPhone")}
                      </span>
                    )}
                  </div>
                  <div className={`control_form ${!haveContent && "col"}`}>
                    <div
                      className={
                        errors.email && touched.email
                          ? "input-error input-form"
                          : " input-form"
                      }
                    >
                      <Field
                        placeholder="Email"
                        type="email"
                        name="email"
                        id="email"
                      />
                    </div>
                    <ErrorMessage
                      name="email"
                      component="span"
                      className="error"
                    />
                  </div>
                  {haveContent && (
                    <div className="control_form text-left">
                      <div
                        className={
                          errors.content && touched.content
                            ? "input-error input-form"
                            : " input-form"
                        }
                      >
                        <Field
                          placeholder={t("contactForm.content")}
                          type="text"
                          name="content"
                        />
                      </div>
                      <ErrorMessage
                        name="content"
                        component="span"
                        className="error"
                      />
                    </div>
                  )}
                </div>

                <div className="d-flex mt-4 mb-4 justify-content-center contact-form-submit-btn">
                  <button
                    type="submit"
                    disabled={toast.visible}
                    className="btn btn-submit"
                    onClick={handleSubmit}
                  >
                    {statusContact.loading ? (
                      <Spinner
                        animation="border"
                        size="sm"
                        variant="secondary"
                      />
                    ) : (
                      t("header.submit")
                    )}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        );
      }}
    </Formik>
  );
}
