import moment from "moment";
import './vi.min';

const initPostionPoint = (point, container) => {
  const containerWidth = container.clientWidth;
  const containerHeight = container.clientHeight;

  let { scale, position } = point;
  let offset = position.split(",");
  let origin = scale.split(",");

  let startX = parseFloat(offset[0]);
  let startY = parseFloat(offset[1]);
  let oldWidth = parseFloat(origin[0]) || 1;
  let oldHeight = parseFloat(origin[1]) || 1;

  startX = (startX * containerWidth) / oldWidth;
  startY = (startY * containerHeight) / oldHeight;

  point.x = startX;
  point.y = startY;
  return point;
};

const formatTime = (time, formatType = "DD/MM/YYYY", locate = "en") => {
  return moment(time).locale(locate).format(formatType);
};

export { initPostionPoint, formatTime };
