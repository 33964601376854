import React, { useRef, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import Slider from "react-slick";

const FloorBottom = ({ data }) => {
  let history = useHistory();

  let slider = useRef();

  const next = () => {
    slider.slickNext();
  };

  const previous = () => {
    slider.slickPrev();
  };

  const onCloseFloor = useCallback(
    function (e) {
      e.preventDefault();
      history.push("/apartment/information");
      // history.push('/floorplans');
    },
    [history]
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    centerPadding: "50px",
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
    appendDots: (dots) => (
      <div className="slick-dots">
        <div
          className="slick-button slick-button-prev"
          onClick={previous}
        ></div>
        <ul style={{ margin: "0px" }}> {dots} </ul>
        <div className="slick-button slick-button-next" onClick={next}></div>
      </div>
    ),
  };

  return (
    <div className="floor-main floor-bottom overlay relative">
      <div className="floor-slider">
        <span className="floor-close" onClick={onCloseFloor}>
          <span className="lnr lnr-cross"></span>
        </span>
        <Slider {...settings} ref={(c) => (slider = c)}>
          {data.image_data.map((item, index) => (
            <div className="h-100" key={index}>
              <div
                className="h-100"
                style={{
                  backgroundImage: `url(${item})`,
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  backgroundRepeat: 'no-repeat'
                }}
              />
              {/* <img
                style={{
                  display: "block",
                  height: "100%",
                  width: "100%",
                  margin: "auto",
                  objectFit: "cover",
                  backgroundColor: "#fff",
                }}
                src={item}
                alt={item}
              /> */}
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default FloorBottom;
