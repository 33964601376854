import requester from "../requester";
import { GET_DESIGN } from "../../constants";
import { GET_DESIGN_LIST } from "../../constants";
import { GET_DESIGN_INTRODUCE } from "../../constants";
import {
  GET_DESIGN_COMPARE,
  GET_DESIGN_VIDEO,
  GET_DESIGN_RELATION,
  GET_DESIGN_OFFICE,
} from "../../constants";
const Design = {
  getDesign: () => requester.get(GET_DESIGN),
  getDesignList: () => requester.get(GET_DESIGN_LIST),
  getDesignIntroduce: () => requester.get(GET_DESIGN_INTRODUCE),
  getDesignCompare: () => requester.get(GET_DESIGN_COMPARE),
  getDesignVideo: () => requester.get(GET_DESIGN_VIDEO),
  getRelation: () => requester.get(GET_DESIGN_RELATION),
  getDescOffice: () => requester.get(GET_DESIGN_OFFICE),
};
export default Design;
