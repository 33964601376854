export const transformPoint = (point, container) => {
  const innerDimension = innerDimensions(container);
  let containerWidth = innerDimension.width;
  let containerHeight = innerDimension.height;

  let { scale, position } = point;
  let offset = position.split(",");
  let origin = scale.split(",");

  let startX = parseFloat(offset[0]);
  let startY = parseFloat(offset[1]);
  let oldWidth = parseFloat(origin[0]) || 1;
  let oldHeight = parseFloat(origin[1]) || 1;

  startX = (startX * containerWidth) / oldWidth;
  startY = (startY * containerHeight) / oldHeight;

  point.x = startX;
  point.y = startY;
  return point;
};

export const innerDimensions = (node) => {
  if (!node) {
    return {};
  }

  var computedStyle = getComputedStyle(node);

  let width = node.clientWidth; // width with padding
  let height = node.clientHeight; // height with padding

  height -=
    parseFloat(computedStyle.paddingTop) +
    parseFloat(computedStyle.paddingBottom);
  width -=
    parseFloat(computedStyle.paddingLeft) +
    parseFloat(computedStyle.paddingRight);
  return { height, width };
};

export const nl2ToBr = (data) => {
  return data ? data.replace(/(?:\r\n|\r|\n)/g, "<br />") : "";
};
export const nl2ToDoubleBr = (data) => {
  return data
    ? data
        .replace(/(?:\r\n|\r|\n)/g, "<br /><br />")
        .replace(/Epic Tower/g, "<strong>Epic Tower</strong>")
    : "";
};

export const addListTagToParagraph = (data) => {
  let str = "";
  if (!data) return "";
  data.split(/(?:\r\n|\r|\n)/g).filter((value) => {
    return (str += "<li>" + value + "</li>");
  });
  return str ? "<ul>" + str + "</ul>" : "";
};

export const translateVertical = (cb, start = 0, end = 100, duration = 5) => {
  setTimeout(() => {
    cb(start);
    start++;
    if (start <= end) {
      translateVertical(cb, start, end, duration);
    }
  }, duration);
};

export const showTitle = (text) => {
  if (!text) {
    return "";
  }
  let ret = "";
  text.split(/(?:\r\n|\r|\n)|<br\/>/g).filter((value) => {
    let newContent = "";
    for (let i = 0; i < value.length; i++) {
      var substring = value.substr(i, 1);
      if (substring != " ") {
        newContent += "<span>" + substring + "</span>";
      } else {
        newContent += " ";
      }
    }
    newContent += "<br/>";
    return (ret += newContent);
  });
  return ret;
};

export const showTitle2 = (text) => {
  let ret = "";
  text.split(/(?:\r\n|\r|\n)|<br\/>/g).filter((value) => {
    let newContent = "";
    for (let i = 0; i < value.length; i++) {
      var substring = value.substr(i, 1);
      if (substring != " ") {
        newContent += "<span>" + substring + "</span>";
      } else {
        newContent += "&nbsp;";
      }
    }
    newContent += "<br/>";
    return (ret += newContent);
  });
  return ret;
};

export const getRandomNumber = (min, max) => {
  return (Math.random() * (max - min) + min).toFixed(4);
};
