import React from "react";
import _ from "lodash";
import Slider from "react-slick";
import { nl2ToBr } from "../../utils/helper";
const SliderComponent = ({ data }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    centerPadding: "50px",
    slidesToScroll: 1,
    arrows: true,
  };

  return (
    <Slider {...settings} className="position-relative">
      {_.map(data, (item, iItem) => (
        <div className="slider-item position-relative">
          <div className="img-wrapper">
            <div
              style={{ backgroundImage: `url(${_.get(item, "image")})` }}
              className="img"
            />
            <div className="slick-dots">
              {_.map(data, (child, index) => (
                <li className={index === iItem ? "slick-active" : ""}>
                  <button>{index}</button>
                </li>
              ))}
            </div>
          </div>
          {
            <div className="slider-item-des position-relative mt-2 p-3">
              {/* <div className="position-relative mb-4 name text-white text-white">
                {_.get(item, "title")}
              </div> */}
              <div
                className="des text-white"
                dangerouslySetInnerHTML={{ __html: nl2ToBr(item.description) }}
              />
              <div className="pin position-absolute" />
            </div>
          }
          <div className="clear-float">.</div>
        </div>
      ))}
    </Slider>
  );
};

export default SliderComponent;
