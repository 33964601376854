import React from "react";

const GetUrl = (id) => {
  switch (id) {
    case 1:
      return "/introduction";
    case 2:
      return "/location";
    case 3:
      return "/design";
    case 4:
      return "/information";
    case 5:
      return "/amenities";
    case 6:
      return "/news";
    case 7:
      return "/virtual";
    case 8:
      return "/contact";
    case 12:
      return "/introduction";
    case 10:
      return "/location";
    case 9:
      return "/amenities";
    case 11:
      return "/design";
    case 14:
      return "/information";
    default:
      return "/";
  }
};

export default GetUrl;
