export const localSto_user = "landcaster::user";
export const REACT_FULLPAGE_KEY = "1B0C42AA-0AB84DFA-B6B2901B-3F9CF551";
export const REACT_FULLPAGE_RESPONSIVE_WIDTH = 1024;
export const REACT_IPAD_RESPONSIVE_WIDTH = 768;
export const MAX_HEIGHT_SHOW_MORE = 300;
export const FLOOR_TYPE_BOTTOM = 2;
export const FLOOR_TYPE_OFFICE = 1;
export const FLOOR_TYPE_NORMAL = 0;
export const DEFAULT_LANG = "vi";
export const FLOOR_TYPE_TUM = 2;

export const SECTION_BOTIQUE = 2;
export const SECTION_STORY = 3;
export const SECTION_EPIC = 4;
export const SECTION_SHOW_MENU = [2, 3, 4];

export const DEFAULT_PROJECT = 1;
export const GET_PROJECTS_URL = `/projects/${DEFAULT_PROJECT}`;

export const GET_HOME_VIDEO = "/homes/video";
export const GET_HOME_ART_BOUTIQUE = "/homes/art-boutique";
export const GET_HOME_TRADEMARK = "/homes/trademark";
export const GET_HOME_CULTURE = "/homes/culture";
export const GET_HOME_SCALE = "/homes/scale";

export const GET_LOCATION = "/pages/location";
export const GET_LOCATION_OFFICE = "/pages/location-office";
export const GET_CONTENT_LOCATION_OFFICE = "/pages?page_type=10";
export const GET_CATEGORY = "/news_categories";
export const POST_CONTACT = "/contacts/create";
export const GET_CONTACT = "/pages/contact";
export const GET_CONTACT_INFO = "/pages/contact-info";

export const GET_AMENITIES = "/pages/amenity";
export const GET_AMENITIES_OFFICE = "/pages/amenity-office";

export const GET_RELATE_INFO = "/pages";

export const GET_DESIGN_MATERIAL_URL = `/designs/material`;
export const GET_FLOORS_URL = (id) => `/floors/${id}`;
export const GET_DEPARTMENT_URL = (id) => `/apartments/${id}`;

export const GET_NEWS_CATEGORY = "/news_categories";
export const GET_NEWS_LIST = "/news";
export const GET_NEWS_DETAIL = (id) => `/news/${id}`;
export const GET_DESIGN = "/designs/artdecor-indochine";
export const GET_DESIGN_LIST = "/designs";
export const GET_DESIGN_OFFICE = "/pages/design-office";
export const GET_DESIGN_INTRODUCE = "/designs/1";
export const GET_DESIGN_COMPARE = "/designs/compare";
export const GET_DESIGN_VIDEO = "/designs/detail";
export const GET_DESIGN_RELATION = "/designs/6";
export const PHONE_CONTACT = "1900 6542";

export const GET_PAGE_INFO_URL = (id) => `/pages/${id}`;

export const FLOOR_BLOCK_TYPE = 3;

export const ORDINAL_NUMBER = ["st", "rd", "th", "nd"];

export const GET_INTRODUCTION_OFFICE = "/pages/intro-office";
export const GET_INTRODUCTION_APARTMENT = "/pages/intro-apartment";
