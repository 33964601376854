import React from "react";
import { useLocation } from "react-router-dom";
import IntroduceMobile from "./IntroduceMobile";
// import VideoDetailMobile from "./VideoDetailMobile";
import DetailsMobile from "./DetailsMobile";
import DetailsCompare from "./DetailsCompare";
import MaterialMobile from "./MaterialMobile";
import RelateInfo from "../Location/RelateInfo";
import Footer from "../../components/Footer";
import { useTranslation } from "react-i18next";

const DesignMobile = () => {
  const location = useLocation();
  const { t } = useTranslation();
  return (
    <div className="design-mobile">
      <div className="backdrop"></div>
      <IntroduceMobile />

      {location.pathname.includes("apartment") && (
        <>
          <DetailsMobile />
          <div className="mt-0">
            <div className="title-custom d-flex align-items-center pb-4 pt-4 position-relative">
              <div className="box" />
              <div className="title ml-3">
                {t("design.material_introduction")}
              </div>
            </div>
            <MaterialMobile />
          </div>
          {/* <div className="mt-5">
            <VideoDetailMobile />
          </div> */}
          <div className="mt-3">
            <div className="title-custom d-flex align-items-center pb-4 pt-4 mb-3 position-relative">
              <div className="box" />
              <div className="title ml-3">{t("design.compare")}</div>
            </div>
            <DetailsCompare />
          </div>
        </>
      )}
      <div
        className={`${location.pathname.includes("apartment") ? "pt-5" : ""}`}
      >
        <RelateInfo />
      </div>
      <Footer />
    </div>
  );
};

export default DesignMobile;
