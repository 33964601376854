import React from "react";
import IconTower from "../../../assets/images/location/icons/tower_icon.svg";
const MapsOverviewContent = ({ page }) => {
  const { title, description } = page;
  return (
    <>
      <div className="maps_overview--menu--title pb-2 d-flex">
        <img src={IconTower} alt="img" />
        <span>{title}</span>
      </div>
      <div className="maps_overview--menu--desc">
        <div dangerouslySetInnerHTML={{ __html: description.replace(/(?:\r\n|\r|\n)/g, '<br />') }}></div>
      </div>
    </>
  );
};

export default MapsOverviewContent;
