import React, { useState, useEffect, useCallback } from 'react';
import { transformPoint } from '../utils/helper';

function withViewPort(WrapperComponent, containerClass, hotSpotClass){
    return (props) => {
        let { data, ...newProps} = props;
        const container = document.querySelector(containerClass);
        const [point, setPoint] = useState(transformPoint(data, container));
        
        const changePoint = useCallback(() => {
            let newPoint = transformPoint(data, container);
            const hotSpot = document.querySelector(hotSpotClass);
            if(hotSpot){
                hotSpot.style.left = `${newPoint.x}px`;
                hotSpot.style.top = `${newPoint.y}px`;
                setPoint(newPoint);
            }
        }, [data, container]);
    
        useEffect(() => {
            window.addEventListener('resize', changePoint);
            
            return () => window.removeEventListener('resize', changePoint);
        }, [changePoint]);
        
        return <WrapperComponent data={point} container={container} {...newProps} />;
    }
}

export default withViewPort;