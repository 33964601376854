import React, { memo } from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({data}) => {
    return (
        <div className="breadcrumb">
            {
                data && data.map((item, index) => <div key={index} className="breadcrumb-item">
                    {
                        item.url
                        ? <Link to={item.url}>{item.title}</Link>
                        : <span>{item.title}</span>
                    }
                </div>)
            }
        </div>
    );
}

export default memo(Breadcrumb);