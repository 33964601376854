import React, { useEffect, useState } from "react";
import iconArrow from '../../assets/images/iconArrows/ic_arrow_up.svg'
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

const ScrollToTop = () => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 300) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 300) {
      setShowScroll(false);
    }
  };
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  window.addEventListener("scroll", checkScrollTop);
  const classButton = `button_scroll_top ${showScroll ? "show" : "hide"}`;
  return (
    <div className={classButton} id="scroll-to-top" onClick={scrollTop}>
      <img src={iconArrow} alt="" />
    </div>
  );
};

export default ScrollToTop;
