import React from "react";
import { useLocation } from "react-router-dom";
import { useGetDesignIntroduce } from "../../hooks/design";
import icon from "../../assets/images/desgin/group-22.svg";
import _ from "lodash";
import ShowMore from "../../components/ShowMore";
import { MAX_HEIGHT_SHOW_MORE } from "../../constants";
import { nl2ToBr } from "../../utils/helper";
import Slider from "react-slick";

const IntroduceMobile = () => {
  const location = useLocation();
  const [data] = useGetDesignIntroduce(location.pathname);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    // centerPadding: "0px",
    slidesToScroll: 3,
    arrows: false,
    autoplay: true,
  };
  return (
    <div
      className={`introduce ${
        location.pathname.includes("apartment") ? "pb-5" : "pb-0"
      }`}
    >
      <div>
        <img src={_.get(data, "image")} className="w-100" />
      </div>
      <div className="container-fluid">
        <div className="w-100 d-flex justify-content-center">
          <div className="pin" />
        </div>
        <div className="title d-flex mt-3">
          <img src={icon} className="mr-3" />
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: _.get(data, "title") }}
          />
        </div>
        <div className="mt-3">
          <ShowMore maxHeight={MAX_HEIGHT_SHOW_MORE + 15}>
            <div
              className="text-white text-justify"
              dangerouslySetInnerHTML={{ __html: _.get(data, "description") }}
            />
          </ShowMore>
        </div>
        <div className="mt-4 align-items-center list-logo">
          <Slider {...settings}>
            {_.map(_.get(data, "logo"), (logo) => (
              <div className="logo-wrapper">
                <img className="logo" src={_.get(logo, "image")} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default IntroduceMobile;
