import { useFetch } from "./index";
import services from "../services";

export const useGetDesign = () => {
  return useFetch(services.Design.getDesign);
};

export const useGetDesignList = () => {
  return useFetch(services.Design.getDesignList);
};

export const useGetDesignIntroduce = (typeBlock) => {
  return useFetch(
    typeBlock?.includes("office")
      ? services.Design.getDescOffice
      : services.Design.getDesignIntroduce
  );
};

export const useGetDesignCompare = () => {
  return useFetch(services.Design.getDesignCompare);
};

export const useGetDesignVideo = () => {
  return useFetch(services.Design.getDesignVideo);
};

export const useDesignRelation = () => {
  return useFetch(services.Design.getRelation);
};
