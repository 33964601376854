import Project from "./projects";
import Location from "./location";
import Category from "./category";
import Contact from "./contact";
import Home from "./home";
import Amenities from "./amenities";
import DesignMaterial from "./designs/material";
import Design from "./design";
import RelateInfo from "./relate-info";
import Page from "./page";
import Intro from "./introduction";

export default {
  Project,
  Location,
  Category,
  Contact,
  DesignMaterial,
  Home,
  Amenities,
  Design,
  RelateInfo,
  Page,
  Intro,
};
