import React, { useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import i18n from "i18next";

import Logo from "./../assets/images/logo.png";
import IconMenuSidebar from "./../assets/images/icon_menu_sidebar.png";
import { useTranslation } from "react-i18next";
import smoothscroll from "smoothscroll-polyfill";
import PerfectScrollbar from "react-perfect-scrollbar";

smoothscroll.polyfill();

const Menu = () => {
  const [menu, setMenu] = useState(false);
  const [language, setLanguage] = useState(i18n.language);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const menus = [
    {
      title: t("header.home"),
      url: "/",
      exact: true,
    },
    {
      title: t("header.amenities_apartment"),
      url: "/apartment/introduction",
      exact: true,
      isHide:
        location.pathname.includes("office") ||
        location.pathname.includes("apartment"),
    },
    {
      title: t("header.amenities_office"),
      url: "/office/introduction",
      exact: true,
      isHide:
        location.pathname.includes("office") ||
        location.pathname.includes("apartment"),
    },
    {
      title: location.pathname.includes("office")
        ? t("header.intro-office")
        : t("header.intro-apartment"),
      url: location.pathname.includes("office")
        ? "/office/introduction"
        : "/apartment/introduction",
      exact: false,
      isHide:
        !location.pathname.includes("office") &&
        !location.pathname.includes("apartment"),
    },
    {
      title: t("header.location"),
      url: location.pathname.includes("office")
        ? "/office/location"
        : "/apartment/location",
      exact: true,
      isHide:
        !location.pathname.includes("office") &&
        !location.pathname.includes("apartment"),
    },
    {
      title: t("header.design"),
      url: location.pathname.includes("office")
        ? "/office/design"
        : "/apartment/design",
      exact: false,
      isHide:
        !location.pathname.includes("office") &&
        !location.pathname.includes("apartment"),
    },
    {
      title: t("header.info-apartment"),
      url: "/apartment/information",
      exact: false,
      isHide: !location.pathname.includes("apartment"),
    },
    {
      title: t("header.info-office"),
      url: "/office/information",
      exact: false,
      isHide: !location.pathname.includes("office"),
    },
    // {
    //   title: t("header.design"),
    //   url: "/design",
    //   exact: true,
    //   isHide: !location.pathname.includes("amenities"),
    // },
    // {
    //   title: t("header.amenities"),
    //   url: "/amenities",
    //   exact: true,
    //   child: [
    //     {
    //       title: t("header.amenities_apartment"),
    //       url: "/amenities/apartment",
    //       exact: true,
    //     },
    //     {
    //       title: t("header.amenities_office"),
    //       url: "/amenities/office",
    //       exact: true,
    //     },
    //   ],
    // },
    {
      title: t("header.amenities"),
      url: location.pathname.includes("office")
        ? "/office/amenities"
        : "/apartment/amenities",
      exact: true,
      isHide:
        !location.pathname.includes("office") &&
        !location.pathname.includes("apartment"),
    },
    {
      title: t("header.news"),
      url: "/apartment/news",
      exact: false,
      isHide:
        location.pathname.includes("office") ||
        location.pathname.includes("apartment"),
    },
    {
      title: t("header.contact"),
      url:
        !location.pathname.includes("office") &&
        !location.pathname.includes("apartment")
          ? "/contact"
          : location.pathname.includes("office")
          ? "/office/contact"
          : "/apartment/contact",
      exact: true,
    },
  ];
  const showMenu = () => setMenu(!menu);
  const selectLanguage = (lang) => {
    if (language !== lang) {
      setLanguage(lang);
      i18n.changeLanguage(lang);
      window.location.reload();
    }
  };
  const redirectToHome = () => {
    const { location } = history;
    if (location.pathname !== "/") {
      history.push("/");
    } else {
      window.location.reload();
    }
  };
  const classNameToggleMenu = menu ? "toggle-menu open" : "toggle-menu";
  const classNameNavMenu = menu
    ? "section-header-nav open"
    : "section-header-nav";
  const classNameBackdrop = menu ? "backdrop_menu show" : "backdrop_menu";
  return (
    <>
      <section className="section-header">
        <div className="section-header-logo" onClick={redirectToHome}>
          <img src={Logo} alt="Logo" />
        </div>
        <div className="section-header-toggle">
          <div className={classNameToggleMenu} onClick={showMenu}>
            <div className="toggle-menu_line-1 fadeIn"></div>
            <div className="toggle-menu_line-2 fadeIn"></div>
            <div className="toggle-menu_line-3 fadeIn"></div>
          </div>
        </div>
        <div className="section-header-lang ">
          <span
            className={language === "vi" ? "current-lang" : " "}
            onClick={() => selectLanguage("vi")}
          >
            VI
          </span>{" "}
          /{" "}
          <span
            className={language === "en" ? "current-lang" : " "}
            onClick={() => selectLanguage("en")}
          >
            EN
          </span>
        </div>
        <div className={classNameNavMenu}>
          <div className="row ">
            <div className="header-nav">
              <div className="header-nav-menu" id="nav-menu">
                <PerfectScrollbar className="menu-content">
                  <ul>
                    {menus.map((menu, index) => {
                      if (menu.isHide) {
                        return "";
                      } else if (!menu.child)
                        return (
                          <li key={index.toString()}>
                            <NavLink
                              exact={menu.exact}
                              to={menu.url}
                              activeClassName="menu_active "
                              onClick={showMenu}
                            >
                              {menu.title}
                            </NavLink>
                          </li>
                        );
                      return (
                        <li key={index.toString()} className="sub-menu-wrapper">
                          <div>
                            <input
                              id={menu.url}
                              type="checkbox"
                              hidden
                              defaultChecked={location.pathname.includes(
                                menu.url
                              )}
                            />
                            <label
                              htmlFor={menu.url}
                              className={
                                location.pathname.includes(menu.url) && "active"
                              }
                            >
                              {menu.title}
                              <p className="mb-0" />
                            </label>

                            <ul className="sub-menu">
                              {menu.child.map((item) => (
                                <li key={item.url}>
                                  <NavLink
                                    exact={item.exact}
                                    to={item.url}
                                    activeClassName="menu_active"
                                    onClick={showMenu}
                                  >
                                    {item.title}
                                  </NavLink>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </PerfectScrollbar>
              </div>
            </div>
          </div>
          <div className="section-header-nav--logo" onClick={showMenu}>
            <img src={IconMenuSidebar} alt="img" />
          </div>
        </div>
        <div className={classNameBackdrop} onClick={showMenu}></div>
      </section>
    </>
  );
};

export default Menu;
