import { useFetch } from "./index";
import services from "../services";

export const useGetAmenities = (pathName) => {
  return useFetch(
    pathName?.includes("office")
      ? services.Amenities.getAmenitiesOffice
      : services.Amenities.getAmenities
  );
};
