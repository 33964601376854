import React, { memo } from "react";
import { useHistory } from "react-router-dom";
// import HomeProjectIcon from "../../assets/images/icons/home_project.png";
import { useHomeScale } from "../../hooks/home";
import { useTranslation } from "react-i18next";
import TopHeader from "../../components/TopHeader";
// import { showTitle2 } from "../../utils/helper";
import bg_intro_office from "../../assets/images/img-office-new.jpg";
import bg_intro_apartment from "../../assets/images/HomeCardApartment.png";
import "./sectionProject.scss";

const SectionProject = ({ language }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [data, loading] = useHomeScale();
  const windownWidth = window.innerWidth;

  return (
    <section className="home section bg-home home-project-wrapper">
      <TopHeader showPhone={false} customClass="container contact" />
      {loading ? (
        <div className="page-loading">{t("loading")}</div>
      ) : (
        <div className="home-project h-100">
          <div className="row scale h-100">
            <div className="col-lg-6">
              <div className="home-project-content home-animation-content">
                <span className="home-index mt-0 mt-lg-0 mb-2 mb-lg-3 show-sub-title">
                  <span>02</span>
                  <span className="text-devider"></span>
                  <span>{language("home.project.scale")}</span>
                </span>
                {windownWidth <= 1024 && (
                  <h5
                    className="show-title"
                    dangerouslySetInnerHTML={{
                      __html: language("home.project.title"),
                    }}
                  ></h5>
                )}

                {windownWidth > 1024 && (
                  <h5 class="show-title mb-3">
                    {language("home.project.scale")}
                  </h5>
                )}

                <div className="home-project-detail mt-0">
                  <div className="row home-project-detail-row show-scale-1">
                    <div
                      className="col-lg-4 home-project-detail-item"
                      dangerouslySetInnerHTML={{
                        __html: language("home.project.totalAcreage", {
                          num: data.area,
                        }),
                      }}
                    ></div>

                    <div
                      className="col-lg-4 home-project-detail-item"
                      dangerouslySetInnerHTML={{
                        __html: language("home.project.totalAcreageBuilding", {
                          num: data.built_area,
                        }),
                      }}
                    ></div>

                    <div
                      className="col-lg-4 home-project-detail-item"
                      dangerouslySetInnerHTML={{
                        __html: language("home.project.buildingDensity", {
                          num: data.building_density,
                        }),
                      }}
                    ></div>

                    {/* <div
                      className="col-lg-3 home-project-detail-item"
                      dangerouslySetInnerHTML={{
                        __html: language("home.project.totalApartment", {
                          num: data.num_apartment,
                        }),
                      }}
                    ></div> */}
                  </div>
                  <div className="row col-lg-12 pr-lg-0">
                    <div className=" block-devider show-line"></div>
                  </div>
                  <div className="row show-scale-2 pr-lg-3">
                    <div className="col-12 col-md-7 pl-0 pr-0 pl-lg-3">
                      <img
                        src={bg_intro_apartment}
                        style={{
                          objectFit: "cover",
                          maxHeight: 165,
                          width: "100%",
                        }}
                        alt="img"
                      />
                    </div>
                    <div className="col-12 pl-0 pr-0 pr-lg-3 col-md-5">
                      <div className="home-project-block-name">
                        <div class="contact-form-submit-tag row ml-auto mr-auto" />
                        <div
                          className="mt-3"
                          dangerouslySetInnerHTML={{
                            __html: language("home.project.nameBlock", {
                              apartment: data.name_block_apartment,
                            }),
                          }}
                        />
                        <button
                          type="submit"
                          class="btn btn-submit"
                          onClick={() => history.push("/apartment/introduction")}
                        >
                          {language("contact_more")}
                        </button>
                      </div>
                    </div>

                    {/* <div className="col-6 col-lg-7">
                      <div className="row">
                        <div
                          className="col-12 mb-2 mb-md-0 col-md-5 col-lg-5 home-project-detail-item"
                          dangerouslySetInnerHTML={{
                            __html: language("home.project.totalApartment", {
                              num: data.num_apartment,
                            }),
                          }}
                        ></div>
                        <div className="col-12 col-md-7 col-lg-7 pl-0 pl-lg-3">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: language("home.project.textFloor", {
                                num_floor: data.num_floor,
                                num_garage: data.num_garage,
                              }),
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-lg-5">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: language("home.project.areaApartment", {
                            from: data.area_apartment_from,
                            to: data.area_apartment_to,
                          }),
                        }}
                      ></div>
                    </div>
                   */}
                  </div>
                  <div className="row col-lg-12 pr-lg-0">
                    <div className=" block-devider show-line"></div>
                  </div>
                  <div className="row show-scale-2 pr-lg-3">
                    <div className="col-12 col-md-7 pl-0 pr-0 pl-lg-3">
                      <img
                        src={bg_intro_office}
                        style={{
                          objectFit: "cover",
                          maxHeight: 165,
                          width: "100%",
                        }}
                        alt="img"
                      />
                    </div>
                    <div className="col-12 pl-0 pr-0 pr-lg-3 col-md-5">
                      <div className="home-project-block-name">
                        <div class="contact-form-submit-tag row ml-auto mr-auto" />
                        <div
                          className="mt-3"
                          dangerouslySetInnerHTML={{
                            __html: language("home.project.nameBlockCommerce", {
                              commerce: data.name_block_commerce,
                            }),
                          }}
                        />
                        <button
                          type="submit"
                          class="btn btn-submit"
                          onClick={() => history.push("/office/introduction")}
                        >
                          {language("contact_more")}
                        </button>
                      </div>
                    </div>

                    {/* <div
                      className="col-5 col-md-6 col-lg-3 home-project-detail-item"
                      dangerouslySetInnerHTML={{
                        __html: language("home.project.totalApartment", {
                          num: 503,
                        }),
                      }}
                    ></div>
                    <div className="col-7 col-md-6 pl-md-0 pl-lg-3 col-lg-9">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: language("home.project.textFloorCommerce", {
                            num_floor: 31,
                            num_garage: 3,
                            num_tmdv: 1,
                          }),
                        }}
                      ></div>
                    </div> */}
                  </div>
                  <div className="row show-scale-3 pr-lg-3">
                    <div className="col-lg-6">
                      {/* <div
                        dangerouslySetInnerHTML={{
                          __html: language("home.project.numBlockCommerce", {
                            num: data.name_block_commerce,
                          }),
                        }}
                      ></div> */}
                    </div>

                    {/* <div className="col-lg-6">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: language("home.project.numFloor", {
                            num: data.num_floor,
                          }),
                        }}
                      ></div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: language("home.project.numGarage", {
                            num: data.num_garage,
                          }),
                        }}
                      ></div>
                    </div> */}
                  </div>
                  {/* <div className="row scale-mobile show-scale-3">
                    <div className="col-lg-5 order show-scale-3">
                      <span className="normal nohighlight">
                        {language("home.project.scale")}
                      </span>
                    </div>

                    <div className="col-lg-4">
                      <span className="normal nohighlight">
                        {language("home.project.floor")}
                      </span>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

            <div
              className="col-lg-6 scale-image home-animation-image"
              style={{
                background: `url(${data.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <img src={data.image} alt={data.title} className="image" />
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default memo(SectionProject);
