import { useFetch } from "./index";
import services from "../services";

export const useHomeVideo = () => {
    return useFetch(services.Home.getVideo);
};

export const useHomeArtBoutique = () => {
    return useFetch(services.Home.getArtBoutique);
};

export const useHomeTradeMark = () => {
    return useFetch(services.Home.getTradeMark);
};

export const useHomeCulture = () => {
    return useFetch(services.Home.getCulture);
};

export const useHomeScale = () => {
    return useFetch(services.Home.getScale);
};