import React from 'react'
import { useTranslation } from 'react-i18next'
import { useGetLocation } from '../../../hooks/location';

const MapsOverviewNote = ({ data, categoryActive, changeCategoryInMap }) => {
    const handleMenuCategory = (id) => {
        changeCategoryInMap(id);
    };
    const { t } = useTranslation()

    return (
        <div className="maps_overview--menu--note">
            <h2>{t("location.note")}</h2>
            <ul>
                <li
                    className={categoryActive === -1 ? "active" : ""}
                    onClick={() => handleMenuCategory(-1)}
                >
                    {t("common.all")}
                </li>
                {data &&
                    data.map((cate) => {
                        const classNameCategory =
                            cate.id === categoryActive ? "active" : "";
                        return (
                            <li
                                key={cate.id}
                                className={classNameCategory}
                                onClick={() => handleMenuCategory(cate.id)}
                            >
                                <img src={cate.icon} alt="img" /> {cate.name}
                            </li>
                        );
                    })}
            </ul>
        </div>
    )
}

export default MapsOverviewNote
