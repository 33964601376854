import React from "react";
import { useGetDesign } from "../../hooks/design";
import icon from "../../assets/images/desgin/group-22.svg";
import _ from "lodash";
import SliderComponent from "./SliderComponent";
import iconTitle from "../../assets/images/icons/icon-title.png";
import { useTranslation } from "react-i18next";
import { nl2ToBr } from "../../utils/helper";
const DetailsMobile = () => {
  const [data] = useGetDesign();
  const { t } = useTranslation();

  return (
    <div className="pb-5 details-mobile">
      <div className="container-fluid">
        <div className="w-100 d-flex justify-content-between">
          <div className="title d-flex">
            <img src={icon} className="mr-3" />
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: _.get(data, "title") }}
            />
          </div>
          <div className="ml-3">
            <div className="pin position-relative" />
          </div>
        </div>
        <div
          className="mt-4 text-white text-justify"
          dangerouslySetInnerHTML={{ __html: _.get(data, "description") }}
        />
        <div className="details-mobile-session mt-5">
          <div>
            <div className="d-flex align-items-center details-mobile-session-title mb-3">
              <img src={iconTitle} />
              <h4 className="text-uppercase mb-0 ml-2">
                {t("design.category.artdecor")}
              </h4>
            </div>
            <SliderComponent data={_.get(data, "artdecor")} />
          </div>
        </div>
        <div className="details-mobile-session mt-5">
          <div>
            <div className="d-flex align-items-center details-mobile-session-title mb-3">
              <img src={iconTitle} />
              <h4 className="text-uppercase mb-0 ml-2">
                {t("design.category.indochine")}
              </h4>
            </div>
            <SliderComponent data={_.get(data, "indochine")} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsMobile;
