import requester from "../requester";
import {
  GET_LOCATION,
  GET_LOCATION_OFFICE,
  GET_CONTENT_LOCATION_OFFICE,
} from "../../constants";

const Location = {
  getLocation: () => requester.get(GET_LOCATION),
  getLocationOffice: () => requester.get(GET_LOCATION_OFFICE),
  getContentLocationOffice: () => requester.get(GET_CONTENT_LOCATION_OFFICE),
};

export default Location;
