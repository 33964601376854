import React, { useEffect, useState } from "react";
import { animateScroll } from "react-scroll";
import i18n from "i18next";

import { getAPI_ADMIN_URL, getTakenData } from "../../utils";
import Loading from "../../components/Loading";

import TeamItem from "./TeamItem";
import FirstContent from "../../components/common/FirstContent";

const Team = () => {
  const [scroll, setScroll] = useState(0);
  const [loading, setLoading] = useState(true);
  const [dataTeams, setDataTeams] = useState([]);
  const [isScroll, setIsScorll] = useState(true);
  var supportsPassive = false;
  let wheelOpt = supportsPassive ? { passive: false } : false;
  let wheelEvent =
    "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";
  let keys = { 37: 1, 38: 1, 39: 1, 40: 1 };
  const preventDefault = (e) => {
    e.preventDefault();
  };
  const preventDefaultForScrollKeys = (e) => {
    if (keys[e.keyCode]) {
      preventDefault(e);
      return false;
    }
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    var bodyRect = document.body.getBoundingClientRect();
    setScroll(parseInt(-bodyRect.top / window.innerHeight));
    window.addEventListener("DOMMouseScroll", preventDefault, {
      capture: false,
      passive: false,
    }); // older FF
    window.addEventListener(wheelEvent, preventDefault, {
      capture: wheelOpt,
      passive: wheelOpt,
    }); // modern desktop
    window.addEventListener("touchmove", preventDefault, {
      capture: wheelOpt,
      passive: wheelOpt,
    }); // mobile
    window.addEventListener("keydown", preventDefaultForScrollKeys, {
      capture: false,
      passive: false,
    });

    return () => {
      window.removeEventListener("DOMMouseScroll", preventDefault, {
        capture: false,
        passive: false,
      }); // older FF
      window.removeEventListener(wheelEvent, preventDefault, {
        capture: wheelOpt,
        passive: wheelOpt,
      }); // modern desktop
      window.removeEventListener("touchmove", preventDefault, {
        capture: wheelOpt,
        passive: wheelOpt,
      }); // mobile
      window.removeEventListener("keydown", preventDefaultForScrollKeys, {
        capture: false,
        passive: false,
      });
    };
  }, []);

  const mouseDown = (e) => {
    const h = window.innerHeight;
    if (isScroll) {
      setIsScorll(false);
      if (e.deltaY < 0) {
        if (scroll !== 0) {
          setScroll(scroll - 1);
          animateScroll.scrollTo(h * (scroll - 1), {
            duration: 1500,
            smooth: true,
            delay: 0,
          });
          Array.from(Array(dataTeams.length).keys()).map((item) => {
            if (parseInt(scroll - 1) === item) {
              const element = document.getElementById(`target-${item}`);
              const img = document.getElementById(`img-target-${item}`);
              if (img) {
                img.style.animationName = `mainText1`;
                img.style.animationDuration = "3s";
              }
              element.style.animationName = `mainText1`;
              element.style.animationDuration = "3s";
            } else {
              const element = document.getElementById(`target-${scroll}`);
              const img = document.getElementById(`img-target-${item}`);
              if (img) {
                img.style.animationName = "mainText2";
              }
              element.style.animationName = "mainText2";
            }
          });
        }
        setTimeout(() => {
          setIsScorll(true);
        }, 1500);
      } else if (e.deltaY > 0) {
        if (scroll !== 2) {
          setScroll(scroll + 1);
          Array.from(Array(dataTeams.length).keys()).map((item) => {
            if (parseInt(scroll + 1) === item) {
              const element = document.getElementById(`target-${item}`);
              const img = document.getElementById(`img-target-${item}`);
              if (img) {
                img.style.animationName = `mainText1`;
                img.style.animationDuration = "3s";
              }
              element.style.animationName = `mainText1`;
              element.style.animationDuration = "3s";
            } else {
              const element = document.getElementById(`target-${scroll}`);
              const img = document.getElementById(`img-target-${item}`);
              if (img) {
                img.style.animationName = "mainText2";
              }
              element.style.animationName = "mainText2";
            }
          });
          animateScroll.scrollTo(h * (scroll + 1), {
            duration: 1500,
            smooth: true,
            delay: 0,
          });
        }
        setTimeout(() => {
          setIsScorll(true);
        }, 1500);
      }
    }
  };

  const scrollTop = () => {
    if (isScroll) {
      setIsScorll(false);
      setScroll(0);
      animateScroll.scrollTo(0, {
        duration: 1500,
        smooth: true,
        delay: 0,
      });
    }
    setTimeout(() => {
      setIsScorll(true);
    }, 1500);
  };
  const getDataAPI = () => {
    const URL = "/api/v1/teams";
    getTakenData(getAPI_ADMIN_URL(URL), i18n.language)
      .then((res) => {
        setDataTeams(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        alert("Something went wrong! please try again later");
      });
  };
  React.useEffect(() => {
    if (!loading) setLoading(true);
    getDataAPI();
  }, [i18n.language]);
  if (loading || dataTeams.length === 0) return <Loading />;
  return (
    <>
      {dataTeams.length !== 0 &&
        dataTeams.map((team, index) => {
          if (index === 0) {
            return (
              <FirstContent
                key={team.id}
                bg={team.image}
                id={`target-${index}`}
                onWheel={mouseDown}
                scrollTop={scrollTop}
                title={team.title}
              />
            );
          } else {
            return (
              <section key={team.id} id={`target-${index}`} onWheel={mouseDown}>
                <TeamItem
                  background={team.image}
                  title={team.title}
                  header={team.name}
                  text={team.description}
                  textRight={index % 2 === 1}
                />
              </section>
            );
          }
        })}
    </>
  );
};
export default Team;
