import React from "react";
import img from "../../assets/images/3.svg";
import plus from "../../assets/images/plus.svg";
import icon from "../../assets/images/icon.svg";
import { withRouter } from "react-router-dom";

import { StarIcon } from "../../components/common/availability/Icons";
const CompareRight = (props) => {
  const {
    text,
    step,
    history,
    productData,
    handleFavourite,
    selectCompare,
  } = props;
  if (text) {
    return (
      <div className="col-md-6 left-choose-main">
        <div className="left-choose-main-text">
          <p>Select the first floorplan on left to compare</p>
        </div>
      </div>
    );
  } else {
    const { id, image, favourited, floor, info, acreage } = productData;
    const clickStarFavourite = () => {
      handleFavourite(id);
    };
    const clickSelectAnother = () => {
      selectCompare(step - 1, productData);
    };

    return (
      <>
        <div className="col-md-6 col-12  right-choose">
          <div className="right-choose-select" onClick={clickSelectAnother}>
            <img src={icon} alt="" title="Select again" />
            <p>SELECT</p>
          </div>
          <div className="right-choose-img">
            <img src={image} alt="" />
          </div>
          <div className="right-choose-main">
            <div className="right-choose-text">
              <div className="right-choose-text-header">
                <StarIcon
                  onClick={clickStarFavourite}
                  nameClass={favourited ? "favourited" : ""}
                />{" "}
                <span>{floor}</span>
              </div>
              {info &&
                info.map((x, index) => (
                  <p key={index.toString()} className="text">
                    {x.amount} <span>{x.name}</span>
                  </p>
                ))}
              {acreage && (
                <p className="text">
                  {acreage.value} <span> {acreage.unit}</span>
                </p>
              )}
            </div>
            <div className="right-choose-text-button">
              <button
                onClick={() => {
                  history.push("/compare/detail_product");
                }}
              >
                Floorplans detail
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default withRouter(CompareRight);
