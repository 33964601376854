import React, {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
  createRef,
} from "react";
import { useGetApparment } from "../../hooks/projects";
import bgBoxInfo from "../../assets/images/desgin/bg-xanh-2_2020-11-26.png";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { REACT_FULLPAGE_RESPONSIVE_WIDTH } from "../../constants";
import { useTranslation } from "react-i18next";
import ThumbPoint from "./ThumbPoint";
import { showTitle } from "../../utils/helper";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const InfoBox = ({ data, handleClose, floor }) => {
  const { t } = useTranslation();

  const infoBoxRef = useRef();
  const [apartment, setApartment] = useState(data);
  const handleClick = useCallback(
    (e) => {
      const { target } = e;
      if (!infoBoxRef.current.contains(target)) {
        handleClose();
      }
    },
    [infoBoxRef, handleClose]
  );

  const [thumbHeight, setThumbHeight] = useState(0);
  const [thumbWidth, setThumbWidth] = useState(0);

  const thumbRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  }, [handleClick]);

  useEffect(() => {
    if (thumbRef.current) {
      setThumbHeight(thumbRef.current.clientHeight);
      setThumbWidth(thumbRef.current.clientWidth);
    }
  }, [thumbRef]);

  const onGetApartment = (item) => {
    setApartment(item);
  };

  const onZoomChange = (e) => {
    let main = document.getElementById("main-zoom");
    if (e.scale == 1) {
      main.classList.add("reset");
    } else {
      main.classList.remove("reset");
    }
  };
  return (
    <div className="apartment-box animate__animated animate__fadeIn animate__faster">
      <div className="apartment-box-content" ref={infoBoxRef}>
        <span className="info-close" onClick={handleClose}>
          <span className="lnr lnr-cross"></span>
        </span>
        <div className="row m-auto">
          <div
            id="image_thumb"
            className="col-lg-8 d-flex-vertical-item apartment-box-image nopadding"
            style={{ background: `#fff` }}
          >
            <div className="partment-image">
              <div id="main-zoom" className="partment-image__main reset">
                <TransformWrapper
                  scale={1}
                  defaultScale={1}
                  pan={{
                    lockAxisX: true,
                    lockAxisY: true,
                  }}
                  wheel={{
                    disabled: true,
                  }}
                  onZoomChange={onZoomChange}
                >
                  {({ zoomIn, zoomOut, ...rest }) => (
                    <React.Fragment>
                      <TransformComponent>
                        <img
                          src={apartment["3d_image"]}
                          className="apartment-box-image3d"
                          alt={apartment.code}
                        />
                      </TransformComponent>
                      <div className="tools">
                        <button onClick={zoomIn}>+</button>
                        <button onClick={zoomOut}>-</button>
                      </div>
                    </React.Fragment>
                  )}
                </TransformWrapper>

                {/* <img
                    src={apartment["3d_image"]}
                    className="apartment-box-image3d"
                    alt={apartment.code}
                  /> */}
              </div>
              {floor["thumb"] && (
                <div className="partment-image__thumb thumb-image">
                  <div className="thumb-image__img thumb" ref={thumbRef}>
                    <img src={floor["thumb"]} />
                    {floor.apartments.map((item) => {
                      const active = item.id == apartment.id ? true : false;
                      return (
                        <ThumbPoint
                          data={item}
                          active={active}
                          height={thumbHeight}
                          width={thumbWidth}
                          showApartment={(i) => onGetApartment(i)}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-4 nopadding">
            <div
              className="apartment-box-info"
              style={{ backgroundImage: `url(${bgBoxInfo})` }}
            >
              <div className="apartment-box-header">
                <h5>{apartment.code}</h5>
                <span>
                  {apartment.area}m<sup>2</sup>
                </span>
              </div>

              <div className="apartment-box-body">
                <PerfectScrollbar wheelPropagation={true}>
                  {apartment.rooms &&
                    apartment.rooms.map((room, index) => {
                      return (
                        <div className="apartment-box-body-item" key={room.id}>
                          <span>
                            {room.name}{" "}
                            {room.description && `(${room.description})`}
                          </span>
                          <span>{room.area}</span>
                        </div>
                      );
                    })}
                </PerfectScrollbar>
              </div>
              <div className="row  justify-content-center form-submit-btn">
                <a href="/contact" className="btn-more" target="_blank">
                  {t("contact_more")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(InfoBox);
