import React, { memo } from "react";
import bgWomen from "../../assets/images/home/bg_women.png";
import bgWomenMobile from "../../assets/images/home/bg_women_mobile.png";
import { useHomeTradeMark } from "../../hooks/home";
import ShowMore from "../../components/ShowMore";
import {
  REACT_FULLPAGE_RESPONSIVE_WIDTH,
  MAX_HEIGHT_SHOW_MORE,
} from "../../constants";
import TopHeader from "../../components/TopHeader";
import { useTranslation } from "react-i18next";
import { showTitle } from "../../utils/helper";

const SectionStory = (props) => {
  const { t } = useTranslation();

  const [data, loading] = useHomeTradeMark();
  const windownWidth = window.innerWidth;
  const bgrImage = data && data.images ? data.images[0].image : bgWomen;
  return (
    <section className="home section bg-home">
      <TopHeader showPhone={false} customClass="container contact" />

      <div className="home-story h-100">
        {loading ? (
          <div className="page-loading">{t("loading")}</div>
        ) : (
          <div className="row h-100">
            <div className="col-lg-5 order-1 order-mobile-2 story-content home-animation-content ">
              <div className="home-location-content home-story-content ">
                <span className="home-index show-sub-title mt-3 mt-lg-0 mb-2 mb-lg-3">
                  <span>03</span>
                  <span className="text-devider"></span>
                  <span>{t("home.menu.story")}</span>
                </span>
                <h5
                  className="show-title mb-0"
                  dangerouslySetInnerHTML={{
                    __html: data.title,
                  }}
                ></h5>
                <ShowMore
                  maxHeight={
                    windownWidth > REACT_FULLPAGE_RESPONSIVE_WIDTH
                      ? null
                      : MAX_HEIGHT_SHOW_MORE
                  }
                >
                  <div
                    className="home-location-content-description show-description"
                    dangerouslySetInnerHTML={{ __html: data.description }}
                  ></div>
                </ShowMore>
              </div>
            </div>
            <div
              className="col-lg-7 bgWomen order-2 order-mobile-1 home-animation-image"
              style={{ background: `url(${bgrImage})` }}
            >
              <h5>{data.title}</h5>
              <img src={bgrImage} alt={data.title} className="bgWomen-image" />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default memo(SectionStory);
