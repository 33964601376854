import React from "react";
import Logo from "./../assets/images/logo.png";
import { useTranslation } from "react-i18next";
import { useGetContactInfo } from "../hooks/contact";
import NumberFormat from "react-number-format";

const Footer = (props) => {
  const { t } = useTranslation();
  const [data, loading] = useGetContactInfo();
  if (loading) return null;
  const { info } = data;
  return (
    <div className="footer">
      <div className="footer-body">
        <div className="footer-body-logo">
          <img src={Logo} alt="logo footer" />
          <p>{t("footer.logo_title")}</p>
        </div>
        <div className="footer-body-contact">
           <div className="footer-body-contact-item">
              <label>{t("footer.developer")}</label>
            <p>{t("footer.developer_name")}</p>
          </div>
          <div className="footer-body-contact-item">
            <label>{t("footer.label.address")}</label>
            <p dangerouslySetInnerHTML={{ __html: info.address }}></p>
          </div>
          <div className="footer-body-contact-item">
            <label>{t("footer.label.hotline")}</label>
            <p>
              <NumberFormat
                displayType="text"
                value={info.hotline}
                format={"#### ### ###"}
              />
            </p>
          </div>
          <div className="footer-body-contact-item">
            <label>{t("footer.label.email")}</label>
            <p>{info.email}</p>
          </div>
        </div>
      </div>

      <div className="footer-bottom">{t("footer.copy")}</div>
    </div>
  );
};

export default Footer;
