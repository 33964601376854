import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { GET_NEWS_CATEGORY, PHONE_CONTACT } from "../../constants";
import NewsBlock from "./NewsBlock";
import TagHead from "../../components/TagHead";
import PhoneFixed from "../../components/PhoneFixed";
import { getDataByParams } from "../../utils";
import Footer from "../../components/Footer";
import TagTitle from "../../components/TagTitle";
import FormContact from "../../components/FormContact";
import { setPageInfo } from "../../actions/page";
import { PageType } from "../../enums/index";
import { useGetPageInfo } from "../../hooks/page";
import { useDispatch } from "react-redux";
import ScrollToTop from "../../components/common/ScrollToTop";

const NewsPage = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [newsData, setNewsData] = useState({
    loading: true,
    data: null,
    paginate: null,
  });

  const [page, pageLoading] = useGetPageInfo(PageType.News);

  const fetchCategoriesNews = () => {
    return getDataByParams(GET_NEWS_CATEGORY);
  };
  const fetchDataFirstTime = () => {
    fetchCategoriesNews().then((res) => {
      const { data } = res.data;
      setNewsData({
        loading: false,
        data,
      });
    });
  };
  useEffect(() => {
    fetchDataFirstTime();
    if (!pageLoading) {
      dispatch(setPageInfo(page));
    }
  }, [page, pageLoading]);

  return (
    <>
      <div className="news_page category">
        <ScrollToTop />
        {newsData.loading ? null : (
          <div className="news_page_wrapper">
            {/* Fixed Element  */}
            <TagHead title={t("news.title")} />
            {/* content  */}
            <div
              className={`news_page--content_wrapper ${
                window.innerWidth >= 768 && "show-info"
              }`}
            >
              {/* <PhoneFixed number={PHONE_CONTACT} /> */}

              <div className="news_page--list">
                {newsData.data &&
                  newsData.data.map((news, index) => (
                    <div className="category_wrapper" key={index}>
                      <TagTitle title={news.name} />
                      <NewsBlock data={news} type="category" />
                    </div>
                  ))}
              </div>
              <FormContact />
            </div>
          </div>
        )}
        {!newsData.loading && <Footer />}
      </div>
    </>
  );
};
export default NewsPage;
