import { useGetAmenities } from "../hooks/amenities";
import _ from "lodash";

export const TYPE = {
  SET_INDEX_AMENITY: "SET_INDEX_AMENITY",
  SET_SIZE_AMENITY_LIST: "SET_SIZE_AMENITY_LIST",
  SET_AMENITY_DATA: "SET_AMENITY_DATA",
}

export const setIndexAmenity = (id) => {
  return {
    type: TYPE.SET_INDEX_AMENITY,
    payload: id
  }
}

export const setSizeAmenityList = (length) => {
  return {
    type: TYPE.SET_SIZE_AMENITY_LIST,
    payload: length
  }
}

export const setAmenityData = (data) => {
  return {
    type: TYPE.SET_AMENITY_DATA,
    payload: data
  }
}

export const getAmenityData = (pathName) => (dispatch) => {
  dispatch(setAmenityData(_.first(useGetAmenities(pathName))));
}
