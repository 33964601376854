import { useFetch } from './index';
import services from '../services';

export const useGetProjects = () =>{
    return useFetch(services.Project.getProjects);
}

export const useGetFloor = (id) =>{
    return useFetch(services.Project.getFloor, id);
}

export const useGetApparment = (id) =>{
    return useFetch(services.Project.getDepartment, id);
}
