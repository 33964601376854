import React, { useEffect, useState } from "react";
import Slider from "react-slick";
// import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import RelateInfo from "../Location/RelateInfo";
import Footer from "../../components/Footer";
import { useTranslation } from "react-i18next";
import ScrollToTop from "../../components/common/ScrollToTop";
import { addListTagToParagraph } from "../../utils/helper";

const AmenitiesMobile = ({ amenity }) => {
  const { t } = useTranslation();
  const [dataItem, setDataItem] = useState({});
  const { pathname } = useLocation();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    centerPadding: "50px",
    slidesToScroll: 1,
    arrows: true,
  };

  useEffect(() => {
    if (amenity?.amenity) {
      setDataItem(
        pathname.includes("office")
          ? {
              firstFloor: amenity.amenity?.firstFloor,
              secondFloor: amenity.amenity?.secondFloor,
              rooftop: amenity.amenity?.rooftop,
              safeEnv: amenity.amenity?.safeEnv,
              security: amenity.amenity?.security,
            }
          : amenity.amenity
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amenity]);

  return (
    <>
      <div className="amenities_mobie">
        <ScrollToTop />

        {dataItem
          ? _.map(_.keys(dataItem), (item) => {
              return (
                <div className="session-item">
                  <div className="session-title d-flex align-items-center pb-4 mb-3 position-relative">
                    <div className="box" />
                    <div className="title ml-3">
                      {item === "security" && pathname.includes("office")
                        ? t("amenities.security-office")
                        : t(`amenities.${item}`)}
                    </div>
                  </div>
                  <div className="mb-3 position-relative">
                    <Slider
                      {...settings}
                      className="position-relative"
                      dots={
                        item === "security" && pathname.includes("apartment")
                          ? false
                          : true
                      }
                    >
                      {_.map(dataItem[`${item}`], (child, iChild) => (
                        <div className="slider-item position-relative">
                          {_.map(child['amenity_images'], (cItem, ccItem) => (
                          <div className="position-relative">
                            <img
                              src={_.get(cItem, "image")}
                              className="w-100"
                              alt=""
                            />
                            {item === "security" ? (
                              <div className="slick-dots">
                                {_.map(
                                  _.get(amenity, ["amenity", item]),
                                  (child, index) => (
                                    <li
                                      className={
                                        index === iChild ? "slick-active" : ""
                                      }
                                    >
                                      <button>{index}</button>
                                    </li>
                                  )
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          ))}

                          {item === "security" ? (
                            <div className="slider-item-des position-relative mt-2 p-3">
                              <div className="position-relative mb-4 name text-white text-white">
                                {_.get(child, "name")}
                              </div>
                              <div
                                className="des text-white image-description"
                                dangerouslySetInnerHTML={{
                                  __html: addListTagToParagraph(
                                    _.get(child, [
                                      "amenity_images",
                                      0,
                                      "description",
                                    ])
                                  ),
                                }}
                              />
                              <div className="pin position-absolute" />
                            </div>
                          ) : (
                            <div className="slider-item-name w-100">
                              {_.get(child, "name")}
                            </div>
                          )}
                          <div className="clear-float">.</div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              );
            })
          : ""}
        <div>
          <RelateInfo />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AmenitiesMobile;
