import React, { memo } from "react";
import { useHomeArtBoutique } from "../../hooks/home";
import Slider from "react-slick";
import TopHeader from "../../components/TopHeader";
import { useTranslation } from "react-i18next";
import { showTitle } from "../../utils/helper";
const SectionLocation = (props) => {
  const [data, loading] = useHomeArtBoutique();
  const { t } = useTranslation();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    centerPadding: "50px",
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <section className="home section bg-home">
      <div className="home-location h-100">
        {loading ? (
          <div className="page-loading">{t("loading")}</div>
        ) : (
          <div className="row h-100">
            <div className="col-lg-5 order-mobile-1 story-content home-animation-content">
              {/* <div className="position-relative d-flex flex-column justify-content-between h-100 "> */}
              {/* <TopHeader showPhone={false} customClass="container contact" /> */}
              <div className="home-location-content">
                <span className="home-index show-sub-title mt-3 mt-lg-0 mb-2 mb-lg-3">
                  <span>02</span>
                  <span className="text-devider"></span>
                  <span>{t("home.menu.botique")}</span>
                </span>
                <h5
                  className="show-title mb-0"
                  dangerouslySetInnerHTML={{
                    __html: data.title,
                  }}
                ></h5>
                <div
                  className="home-location-content-description show-description"
                  dangerouslySetInnerHTML={{ __html: data.description }}
                ></div>
              </div>
              {/* </div> */}
            </div>
            <div className="col-lg-7 relative order-mobile-2 location-slider home-animation-image ">
              <div className="home-location-slider">
                <Slider {...settings}>
                  {data.images.map((item, index) => (
                    <img
                      style={{
                        display: "block",
                        height: "100%",
                        margin: "auto",
                        width: "100%",
                        objectFit: "cover",
                      }}
                      key={index}
                      src={item.image}
                      alt={item.title}
                    />
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default memo(SectionLocation);
