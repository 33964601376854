import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./notFound.scss";

const PageNotFound = () => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <div className="page-not-found">
      <h2>{t("page_not_found.title")}</h2>
      <button className="btn btn-submit" onClick={() => history.push("/")}>
        {t("page_not_found.back_home")}
      </button>
    </div>
  );
};

export default PageNotFound;
