import React, { memo, useCallback, useState, useMemo, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useGetFloor } from "../../hooks/projects";
import LoadingIcon from "../../assets/images/icons/loading.gif";
import InfoBox from "./InfoBox";
import { useTranslation } from "react-i18next";
import { FLOOR_TYPE_NORMAL } from "../../constants";
import FloorBottom from "./FloorBottom";
import FloorHostSpot from "./FloorHostSpot";

const Floor = (props) => {
  const location = useLocation();
  let { id } = useParams();
  let history = useHistory();
  const [listFloor, setListFloor] = useState([]);
  const { t } = useTranslation();
  const [curentAppartment, setCurrentApartment] = useState(null);
  let [floor, loading] = useGetFloor(id);
  let container = document.querySelector("#floor-main-view");
  const [svgWidth, setSvgWidth] = useState(0);
  const [svgHeight, setSvgHeight] = useState(0);
  const [loadedImage, setLoadedImage] = useState(false);
  const onLoadedImage = useCallback((e) => {
    setSvgWidth(e.target.clientWidth);
    setSvgHeight(e.target.clientHeight);
    setLoadedImage(true);
  }, []);

  // --- Draw polygon ---
  const parsePoint = ({ x, y }, scale, container) => {
    const containerWidth = container.offsetWidth;
    const containerHeight = container.offsetHeight;

    let newScale = scale ? scale.split(",") : [1, 1];
    let startX = parseFloat(x);
    let startY = parseFloat(y);
    let oldWidth = parseFloat(newScale[0]);
    let oldHeight = parseFloat(newScale[1]);

    startX = (startX * containerWidth) / oldWidth;
    startY = (startY * containerHeight) / oldHeight;

    return { x: startX, y: startY };
  };

  const parsePolygon = ({ scale, polygon }) => {
    return polygon
      .map((o) => {
        let point = parsePoint(o, scale, container);
        return `${point.x},${point.y}`;
      })
      .join(" ");
  };

  const parseToLine = ({ scale, polygon }) => {
    let points = polygon.map((o) => {
      return parsePoint(o, scale, container);
    });

    let paths = [];

    for (let index = 0; index < points.length; index++) {
      const el = points[index];

      let nextIndex = index + 1;
      let nextEl = nextIndex < points.length ? points[nextIndex] : points[0];
      let path = (
        <line x1={el.x} y1={el.y} x2={nextEl.x} y2={nextEl.y} key={index} />
      );
      paths.push(path);
    }

    return paths;
  };

  const parseText = ({ scale, polygon, code, area }) => {
    let points = polygon.map((o) => {
      return parsePoint(o, scale, container);
    });
    let x,
      y = 0;
    let length = points.length / 2;
    x = (points[0].x + points[length].x) / 2;
    y = (points[0].y + points[length].y) / 2;
    return (
      <text x={x} y={y} font-size="18" font-weight="bold">
        {code}
        <tspan class="em" dy="0.5cm" dx="-1.5cm">
          ({area}m2)
        </tspan>
      </text>
    );
  };
  // --- Draw polygon ---

  const onCloseFloor = useCallback(
    function (e) {
      e.preventDefault();
      // window.location.pathname = location.pathname.includes("apartment")
      //   ? "/apartment/information"
      //   : "/office/information";
      history.push(
        `${
          location.pathname.includes("apartment")
            ? "/apartment/information"
            : "/office/information"
        }`
      );
      // history.push("/floorplans");
    },
    [history]
  );

  // --- Event click polygon ---
  const onClickPolyGon = useCallback(function (e, apartment) {
    setCurrentApartment(apartment);
  }, []);

  const closeViewDepartment = useCallback(function (e) {
    setCurrentApartment(null);
  }, []);

  const apartmentBox = useMemo(
    () => (apartment, floor) =>
      apartment && (
        <InfoBox
          floor={floor}
          data={apartment}
          handleClose={closeViewDepartment}
        />
      ),
    [closeViewDepartment]
  );
  // --- Event click polygon ---
  const getStyleHotspot = (position) => {
    return position ? { top: `${position.y}px`, left: `${position.x}px` } : {};
  };

  useEffect(() => {
    if (floor) {
      setListFloor(
        floor.name
          .replace(/[^0-9]/g, "-")
          .split("-")
          .filter((item) => item.length)
      );
    }
  }, [floor]);

  return (
    <div
      className={`floor floor-mobile ${curentAppartment ? "popup-show" : ""} ${
        floor?.floor_type == FLOOR_TYPE_NORMAL ? "floor-normal" : "floor-office"
      }`}
    >
      {/* <h5 className="floor-mobile-title">{floor?.name}</h5> */}
      <span className="floor-mobile-close " onClick={onCloseFloor}>
        <span className="lnr lnr-cross"></span>
      </span>

      <div className="floor-overview">
        {loading ? (
          <div className="page-loading">{t("loading")}</div>
        ) : floor.floor_type !== FLOOR_TYPE_NORMAL ? (
          <FloorBottom data={floor} />
        ) : (
          <div
            className="floor-main"
            // style={{
            //   background: `url(${LoadingIcon}) no-repeat center center`,
            // }}
          >
            {apartmentBox(curentAppartment, floor)}

            {/* <div className="floor-main-title">
              <h5>{t("project.floor.title")}</h5>
            </div> */}
            <span className="floor-close" onClick={onCloseFloor}>
              <span className="lnr lnr-cross"></span>
            </span>
            <div
              className="floor-main-view-img"
              id="floor-main-view"
              style={{
                position: "relative",
                display: "inline-block",
                height: "100%",
                // margin: "0 auto",
              }}
            >
              <img
                src={floor.image}
                alt={floor.name}
                className="floor-main-image"
                onLoad={onLoadedImage}
              />
              <div>
                {loadedImage &&
                  floor.apartments.map(function (apartment, key) {
                    if (apartment.position) {
                      const contain =
                        document.getElementById("floor-main-view");
                      return (
                        <FloorHostSpot
                          data={apartment}
                          key={key}
                          onClick={(e) => onClickPolyGon(e, apartment)}
                          container={contain}
                        />
                      );
                    }
                  })}
              </div>
            </div>

            <div className="floor-main-content">
              {/* <div className="contact-form-submit-tag row ml-auto mr-auto"></div> */}
              {listFloor.length === 1 ? (
                <p className="mb-0 floor-main-content-num floor-main-content-num--bottom">
                  {listFloor[0]}
                </p>
              ) : (
                <>
                  <p className="mb-0 floor-main-content-num">{listFloor[0]}</p>
                  <p className="mb-0 floor-main-content-num floor-main-content-num--bottom">
                    {listFloor[1]}
                  </p>
                </>
              )}
              <h5 className="floor-main-content-title">{floor.name}</h5>
              {floor.type_of_real === 2 && (
                <p className="mb-0 floor-main-content-desc">
                  {floor.description}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(Floor);
