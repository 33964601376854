import React, { memo, useState, useEffect, useCallback } from "react";
import HotSpot from "./HotSpot";
import { useLocation } from "react-router-dom";
import { useDesignMaterial } from "../../hooks/materials";
// import { SectionsContainer, Section } from "react-fullpage";
import Introduce from "./Introduce";
import VideoDetail from "./VideoDetail";
import DetailsDescription from "./Details";
import Navigation from "../../components/Navigation";
import Compare from "../../container/Design/DetailsCompare";
import LastContent from "../Location/RelateInfo";
import TagHead from "../../components/TagHead";
import TopHeader from "../../components/TopHeader";
import { useTranslation } from "react-i18next";
import "./index.scss";
import { getRelateInfo } from "../../actions/relate-information";
import { useDispatch } from "react-redux";
import DesignMobile from "./DesignMobile";
import { setPageInfo } from "../../actions/page";
import { PageType } from "../../enums/index";
import { useGetPageInfo } from "../../hooks/page";
import ScrollToTop from "../../components/common/ScrollToTop";
import ReactFullpage from "@fullpage/react-fullpage";
import {
  REACT_FULLPAGE_KEY,
  REACT_FULLPAGE_RESPONSIVE_WIDTH,
} from "../../constants";
import DesignInfo from "./DesignInfo";
const Design = () => {
  const { t } = useTranslation();

  const [designs, loading] = useDesignMaterial();
  const [activeDesgin, setActiveDesgin] = useState(null);
  const isDesktop = window.innerWidth > 768;
  const location = useLocation();
  const [page, pageLoading] = useGetPageInfo(
    location.pathname.includes("office") ? location.pathname : PageType.Design
  );
  const [activeDetail, setActiveDetail] = useState(false);
  const [activeIntro, setActiveIntro] = useState(true);

  useEffect(() => {
    if (!loading && designs && designs.length > 0) {
      let active = designs[0];
      setActiveDesgin(active);
    }
    if (!pageLoading) {
      dispatch(setPageInfo(page));
    }
  }, [designs, loading, page, pageLoading]);

  const onChangeActiveĐesgin = useCallback(
    (e, item) => {
      setActiveDesgin(item);
    },
    [setActiveDesgin]
  );

  const dispatch = useDispatch();

  if (dispatch) {
    dispatch(
      getRelateInfo(location.pathname.includes("apartment") ? "4,5,1" : "14,9,12")
    );
  }

  const onLeave = (origin, destination, direction) => {
    const { item, index } = destination;
    const originItem = origin.item;
    const only = document.querySelector(".only-1-2");
    originItem.classList.remove("show-info");
    item.classList.add("show-info");
    only.classList.remove("show-info");
    if (location.pathname.includes("apartment")) {
      if (index > 2) {
        only.classList.add("only-disable");
      } else {
        if (index == 2) {
          setActiveIntro(false);
          setActiveDetail(true);
        } else {
          setActiveIntro(true);
          setActiveDetail(false);
        }
        only.classList.remove("only-disable");
        only.classList.add("show-info");
      }
    } else {
      if (index > 1) {
        only.classList.add("only-disable");
      } else {
        only.classList.remove("only-disable");
        only.classList.add("show-info");
      }
    }
  };

  const options = {
    activeClass: "active", // the class that is appended to the sections links
    anchors: ["1", "3", "5", "2", "4", "6"], // the anchors for each sections
    arrowNavigation: true, // use arrow keys
    className: "container_fullpage", // the class name for the section container
    delay: 1000, // the scroll animation speed
    navigation: false, // use dots navigatio
    touchNavigation: false,
    scrollBar: false, // use the browser default scrollbar
    sectionClassName: "Section", // the section class name
    sectionPaddingTop: "0", // the section top padding
    sectionPaddingBottom: "0", // the section bottom padding
    verticalAlign: false, // align the content of each section vertical,
  };
  if (loading) return <div className="page-loading">{t("loading")}</div>;

  return (
    <div className="design-wrapper">
      <TagHead title={t("design.title")} />

      {isDesktop ? (
        <div className="desktop-screen">
          <ReactFullpage
            licenseKey={REACT_FULLPAGE_KEY}
            scrollingSpeed={1000}
            onLeave={onLeave}
            menu="#myMenu"
            lockAnchors={true}
            arrowNavigation={location.pathname.includes("apartment")} // use arrow keys
            navigation={false} // use dots navigatio
            loopTop={false}
            scrollBar={false}
            // anchors={["1", "1", "3", "5", "2", "4", "6"]}
            anchors={["1", "1", "3", "5", "4", "6"]}
            responsiveWidth={REACT_FULLPAGE_RESPONSIVE_WIDTH}
            render={({ state, fullpageApi }) => {
              return (
                <ReactFullpage.Wrapper>
                  <div className="section Section">
                    <Introduce active={activeIntro} />
                  </div>
                  {location.pathname.includes("apartment") && (
                    <>
                      <div className="section">
                        <DetailsDescription active={activeDetail} />
                      </div>
                      <section className="section">
                        <div className="design">
                          <TopHeader
                            showPhone={false}
                            customClass="container contact"
                          />
                          {loading ? (
                            <div className="page-loading">{t("loading")}</div>
                          ) : (
                            <>
                              {activeDesgin && (
                                <div
                                  className="design-content"
                                  style={{
                                    background: `url(${activeDesgin.image}) no-repeat center center`,
                                  }}
                                >
                                  {activeDesgin.design_materials.map((item) => (
                                    <HotSpot data={item} key={item.id} />
                                  ))}
                                </div>
                              )}

                              <div className="design-nav">
                                {designs.map((item) => {
                                  return (
                                    <div
                                      className={`design-nav-item ${
                                        item.id === activeDesgin?.id && "active"
                                      }`}
                                      key={item.id}
                                      onClick={(e) =>
                                        onChangeActiveĐesgin(e, item)
                                      }
                                    >
                                      <img
                                        src={item.image}
                                        alt={item.description}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </>
                          )}
                        </div>
                      </section>
                      {/* <section className="section">
                        <VideoDetail />
                      </section> */}
                      <section className="section">
                        <Compare />
                      </section>
                    </>
                  )}
                  <LastContent showPhone={false} customClass="design-contact" />
                </ReactFullpage.Wrapper>
              );
            }}
          />
          {location.pathname.includes("apartment") && (
            <div className="position-absolute navigation-wrapper d-flex align-items-center">
              <Navigation />
            </div>
          )}
          <div className="only-1-2 show-info">
            <DesignInfo />
          </div>
        </div>
      ) : (
        <div className="mobile-screen">
          <ScrollToTop />
          <DesignMobile />
        </div>
      )}
    </div>
  );
};

export default Design;
