import { useFetch } from "./index";
import services from "../services";

const useGetContact = () => {
  return useFetch(services.Contact.getContact);
};

const useGetContactInfo = () => {
  return useFetch(services.Contact.getContactInfo);
}

export { useGetContact, useGetContactInfo };
