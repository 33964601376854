import React, { useEffect, useState } from "react";
import { animateScroll } from "react-scroll";

import Loading from "../../components/Loading";
import { getTakenData } from "../../utils";
import i18n from "i18next";
import ScrollTop from "../../components/common/ScrollTop";
import MainProject from "./MainProject";
import ProjectItem from "../../components/common/ProjectItem";

const Project = () => {
  const [scroll, setScroll] = useState(0);
  const [isScroll, setIsScorll] = useState(true);
  const [project, setProject] = useState({
    loading: true,
    data: [],
  });
  const timeDuration = 1200;
  var supportsPassive = false;

  let wheelOpt = supportsPassive ? { passive: false } : false;
  let wheelEvent =
    "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";
  let keys = { 37: 1, 38: 1, 39: 1, 40: 1 };
  const preventDefault = (e) => {
    e.preventDefault();
  };
  const preventDefaultForScrollKeys = (e) => {
    if (keys[e.keyCode]) {
      preventDefault(e);
      return false;
    }
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    var bodyRect = document.body.getBoundingClientRect();
    setScroll(parseInt(-bodyRect.top / window.innerHeight));
    window.addEventListener("DOMMouseScroll", preventDefault, {
      capture: false,
      passive: false,
    }); // older FF
    window.addEventListener(wheelEvent, preventDefault, {
      capture: wheelOpt,
      passive: wheelOpt,
    }); // modern desktop
    window.addEventListener("touchmove", preventDefault, {
      capture: wheelOpt,
      passive: wheelOpt,
    }); // mobile
    window.addEventListener("keydown", preventDefaultForScrollKeys, {
      capture: false,
      passive: false,
    });

    return () => {
      window.removeEventListener("DOMMouseScroll", preventDefault, {
        capture: false,
        passive: false,
      }); // older FF
      window.removeEventListener(wheelEvent, preventDefault, {
        capture: wheelOpt,
        passive: wheelOpt,
      }); // modern desktop
      window.removeEventListener("touchmove", preventDefault, {
        capture: wheelOpt,
        passive: wheelOpt,
      }); // mobile
      window.removeEventListener("keydown", preventDefaultForScrollKeys, {
        capture: false,
        passive: false,
      });
    };
  }, []);

  const displayBtnToTop = (page) => {
    const btnToTopElm = document.querySelector(".top-arrow");
    if (page == 0 && btnToTopElm) {
      btnToTopElm.style.transform = "scale(0)";
      return;
    }
    if (page > 0 && btnToTopElm) {
      btnToTopElm.style.transform = "scale(1)";
      return;
    }
    return;
  };

  const mouseDown = (e) => {
    const h = window.innerHeight;
    if (isScroll) {
      setIsScorll(false);
      if (e.deltaY < 0) {
        if (scroll !== 0) {
          setScroll(scroll - 1);
          displayBtnToTop(scroll - 1);
          animateScroll.scrollTo(h * (scroll - 1), {
            duration: timeDuration,
            smooth: true,
            delay: 0,
          });
          Array.from(Array(project.data.projects.length).keys()).map((item) => {
            if (parseInt(scroll - 1) === item) {
              const element = document.getElementById(`target-${item}`);
              const img = document.getElementById(`img-target-${item}`);
              if (img) {
                img.style.animationName = `mainText1`;
                img.style.animationDuration = "3s";
              }
              element.style.animationName = `mainText1`;
              element.style.animationDuration = "3s";
            } else {
              const element = document.getElementById(`target-${scroll}`);
              const img = document.getElementById(`img-target-${item}`);
              if (img) {
                img.style.animationName = "mainText2";
              }
              element.style.animationName = "mainText2";
            }
          });
        }
        setTimeout(() => {
          setIsScorll(true);
        }, timeDuration);
      } else if (e.deltaY > 0) {
        if (scroll !== 4) {
          setScroll(scroll + 1);
          displayBtnToTop(scroll + 1);
          Array.from(Array(project.data.projects.length)).map((item) => {
            if (parseInt(scroll + 1) === item) {
              const element = document.getElementById(`target-${item}`);
              const img = document.getElementById(`img-target-${item}`);
              if (img) {
                img.style.animationName = `mainText1`;
                img.style.animationDuration = "3s";
              }
              element.style.animationName = `mainText1`;
              element.style.animationDuration = "3s";
            } else {
              const element = document.getElementById(`target-${scroll}`);
              const img = document.getElementById(`img-target-${item}`);
              if (img) {
                img.style.animationName = "mainText2";
              }
              element.style.animationName = "mainText2";
            }
          });
          animateScroll.scrollTo(h * (scroll + 1), {
            duration: timeDuration,
            smooth: true,
            delay: 0,
          });
        }
        setTimeout(() => {
          setIsScorll(true);
        }, timeDuration);
      }
    }
  };

  const scrollTop = () => {
    if (isScroll) {
      setIsScorll(false);
      setScroll(0);
      displayBtnToTop(0);
      animateScroll.scrollTo(0, {
        duration: timeDuration,
        smooth: true,
        delay: 0,
      });
    }

    setTimeout(() => {
      setIsScorll(true);
    }, timeDuration);
  };

  useEffect(() => {
    //restart to top
    if (isScroll) {
      setIsScorll(false);
      setScroll(0);
      displayBtnToTop(0);
      animateScroll.scrollTo(0, {
        duration: 0,
        smooth: true,
        delay: 0,
      });
    }
    setTimeout(() => {
      setIsScorll(true);
    }, timeDuration);
  }, []);

  const getDataProject = () => {
    if (!project.loading)
      setProject({
        loading: true,
        ...project,
      });
    getTakenData("/api/v1/projects", i18n.language)
      .then((res) => {
        setProject({
          loading: false,
          data: res.data.data,
        });
      })
      .catch((error) => {
        alert("Something was wrong please try again!");
      });
  };
  useEffect(() => {
    getDataProject();
  }, [i18n.next]);
  if (project.loading || project.data.length === 0) return <Loading />;
  return (
    <>
      <ScrollTop scrollTop={scrollTop} />
      {project.data.projects &&
        project.data.projects.map((main, index) => (
          <MainProject
            key={index.toString()}
            bg={main.image}
            id={`target-${index}`}
            onWheel={mouseDown}
            bigTitle={main.title}
          />
        ))}
      <section className="section-project-tag" onWheel={mouseDown}>
        <div
          className="container"
          id={`target-${project.data.projects.length}`}
        >
          <div className="row">
            {project.data.teams &&
              project.data.teams.map((project, index) => (
                <div
                  key={index.toString()}
                  className="project_column col-lg-4 col-md-12"
                >
                  <ProjectItem
                    image={project.image}
                    title={project.title}
                    text={project.description}
                  />
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Project;
