import axios from "axios";
import { ENDPOINT_ADMIN, ENDPOINT_BACKEND, BASE_URL } from "./constants";

export const getAPI_ADMIN_URL = (url) => {
  return ENDPOINT_ADMIN + url;
};
export const getAPI_BACKEND_URL = (url) => {
  return ENDPOINT_BACKEND + url;
};

// CREATE AN INSTANCE OF AXIOS
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    locate: "en",
  },
  timeout: 10000,
});

const config = {
  headers: { "content-type": "multipart/form-data" },
};
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

axiosInstance.defaults.headers.common[
  "Authorization"
] = `Bearer ${localStorage.getItem("token")}`;

const getTakenData = async (url, lang = "US") => {
  const result = await axiosInstance.get(url, {
    headers: {
      locate: lang === "VN" ? "vi" : "en",
    },
  });
  return result;
};

const getDataByID = async (url, id) => {
  const result = await axiosInstance.get(`${url}/${id}`);
  return result;
};

const getDataByParams = async (url, params) => {
  const result = await axiosInstance.get(url, { params });
  return result;
};

const postData = async (url, data) => {
  const result = await axiosInstance.post(url, data);
  return result;
};

const postDataConfig = async (url, data) => {
  const result = await axiosInstance.post(url, data, config);
  return result;
};

const putDataUrl = async (url, data) => {
  const result = await axiosInstance.put(url, data);
  return result;
};

export {
  axiosInstance,
  getDataByID,
  getTakenData,
  postData,
  putDataUrl,
  getDataByParams,
  postDataConfig,
};
