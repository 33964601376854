import React, { createRef, memo, useEffect, useCallback, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import slugify from "slugify";
import {
  FLOOR_TYPE_NORMAL,
  FLOOR_TYPE_TUM,
  FLOOR_BLOCK_TYPE,
} from "../../constants";
const OFFSET_TOP = window.innerWidth > 1024 ? 80 : 30;
const OFFSET_PADDING = window.innerWidth > 1024 ? 6 : 2;

const mapObjReplace = {
  st: "<sup>st</sup>",
  rd: "<sup>rd</sup>",
  th: "<sup>th</sup>",
  nd: "<sup>nd</sup>",
};
const replaceOrdinal = (str, mapObj) => {
  var re = new RegExp(Object.keys(mapObj).join("|"), "gi");
  return str.replace(re, function (matched) {
    return mapObj[matched];
  });
};

const initHostPost = (point, container) => {
  const containerWidth = container.clientWidth;
  const containerHeight = container.clientHeight;
  // console.log(containerHeight);
  let { scale, position } = point;
  let offset = position.split(",");
  let origin = scale.split(",");

  let startX = parseFloat(offset[0]);
  let startY = parseFloat(offset[1]);
  let oldWidth = parseFloat(origin[0]) || 1;
  let oldHeight = parseFloat(origin[1]) || 1;
  startX = (startX * containerWidth) / oldWidth;
  if (containerHeight == 0) {
    startY = (startY * containerWidth) / oldWidth;
    point.x = startX;
    point.y = startY;
  } else {
    startY = (startY * containerHeight) / oldHeight;
    point.x = startX;
    point.y = startY;
  }

  return point;
};

// const convertOrdinalNumber = (data) => {

// }

const HostSpot = ({ data, numBlock }) => {
  const { i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const container = document.getElementById("appartment-view");
  let dataPoint = useRef(initHostPost(data, container));
  let hotSpot = dataPoint.current;
  let hotRef = createRef();

  const getStyleHotspot = (position) => {
    return position ? { top: `${position.y}px`, left: `${position.x}px` } : {};
  };

  const getStyleLineBlock = (position, numBlock) => {
    const offsetTop = numBlock % 2 == 0 ? 20 : 0;
    const y = position.y;
    const top = OFFSET_TOP - offsetTop;
    const height = y - top + OFFSET_PADDING;
    return position
      ? {
          top: `${top}px`,
          left: `${position.x + OFFSET_PADDING}px`,
          height: `${height}px`,
        }
      : {};
  };

  const getStyleNameBlock = (position, numBlock) => {
    const offsetTop = numBlock % 2 == 0 ? 20 : 0;
    const y = position.y;
    const top = OFFSET_TOP - offsetTop;
    return position ? { top: `${top}px`, left: `${position.x}px` } : {};
  };

  const setPoint = useCallback(() => {
    dataPoint.current = initHostPost(data, container);
    hotRef.current.style.left = `${dataPoint.current.x}px`;
    hotRef.current.style.top = `${dataPoint.current.y}px`;
  }, [dataPoint, hotRef, data, container]);

  useEffect(() => {
    setPoint();
    window.addEventListener("resize", setPoint);

    return () => window.removeEventListener("resize", setPoint);
  }, [setPoint]);

  const getClassNameHotspot = (position) => {
    const containerWidth = container.clientWidth;
    return position && position.x < containerWidth / 2 ? "flip" : "";
  };

  const handleToAmenities = (hotSpotName) => {
    const hotSpotNameSlug = slugify(hotSpotName, {
      replacement: "-",
      remove: undefined,
      lower: true,
      strict: false,
      locale: "vi",
    });
    if (
      ["can-ho", "apartment"].some((item) => hotSpotNameSlug.includes(item))
    ) {
      history.push("/apartment/introduction");
      return;
    }
    history.push("/office/introduction");
  };

  if (hotSpot.floor_type == FLOOR_BLOCK_TYPE) {
    return (
      <>
        <a
          ref={hotRef}
          className={`hotspot floor-type-${hotSpot.floor_type}`}
          style={{ ...getStyleHotspot(hotSpot) }}
        ></a>
        <span
          className="hotspot-block-line"
          style={{ ...getStyleLineBlock(hotSpot, numBlock) }}
        ></span>
        <span
          className="hotspot-block-name"
          style={{ ...getStyleNameBlock(hotSpot, numBlock), cursor: "pointer" }}
          onClick={() => {
            handleToAmenities(hotSpot.name);
          }}
        >
          {hotSpot.name}
        </span>
      </>
    );
  } else {
    let linkTo = "#";
    // let target = "";
    if (hotSpot.floor_type === FLOOR_TYPE_TUM) {
      linkTo = `/apartment/information/${hotSpot.id}`;
      // target = "_blank";
      return (
        <Link
          to={linkTo}
          ref={hotRef}
          // target={target}
          className={`hotspot ${getClassNameHotspot(hotSpot)} floor-type-${
            hotSpot.floor_type
          }`}
          style={{ ...getStyleHotspot(hotSpot) }}
        >
          <span
            className={
              hotSpot.floor_type !== FLOOR_TYPE_NORMAL ? "office" : "normal"
            }
          >
            <em>{hotSpot.name}</em>
          </span>
        </Link>
      );
    } else if (
      (hotSpot.floor_type !== FLOOR_TYPE_NORMAL &&
        hotSpot.image_data.length > 0) ||
      hotSpot.floor_type == FLOOR_TYPE_NORMAL
    ) {
      linkTo = `/${
        location.pathname.includes("apartment") ? "apartment" : "office"
      }/information/${hotSpot.id}`;
    }
    return (
      <Link
        to={linkTo}
        ref={hotRef}
        className={`hotspot ${getClassNameHotspot(hotSpot)} floor-type-${
          hotSpot.floor_type
        }`}
        style={{ ...getStyleHotspot(hotSpot) }}
      >
        <span
          className={
            hotSpot.floor_type !== FLOOR_TYPE_NORMAL ? "office" : "normal"
          }
        >
          {/* <em>
            {ORDINAL_NUMBER.some((item) => hotSpot.name.includes(item))
              ? replaceOrdinal(hotSpot.name, mapObjReplace)
              : hotSpot.name}
          </em> */}
          <em
            dangerouslySetInnerHTML={{
              __html:
                i18n.language === "en"
                  ? replaceOrdinal(hotSpot.name, mapObjReplace)
                  : hotSpot.name,
            }}
          />
        </span>
      </Link>
    );
  }
};

export default memo(HostSpot);
