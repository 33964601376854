import { useFetch } from "./index";
import services from "../services";

export const useIntroductionDetail = (typeBlock) => {
  return useFetch(
    typeBlock.includes("office")
      ? services.Intro.getIntroOffice
      : services.Intro.getIntroApartment
  );
};
