import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getTakenData } from "../../utils";
import { GET_NEWS_CATEGORY, PHONE_CONTACT } from "../../constants";
import PhoneFixed from "../../components/PhoneFixed";
const Filters = ({ setFilter, id }) => {
  const [categories, setCategories] = useState(null);
  const { t } = useTranslation();
  const getCategoryNews = () => {
    getTakenData(GET_NEWS_CATEGORY).then((res) => {
      setCategories(res.data.data);
    });
  };
  const changeFilter = (e) => {
    const idConvertInt = parseInt(e.target.value);
    setFilter(idConvertInt);
  };
  useEffect(() => {
    let mounted = true;
    if (mounted) getCategoryNews();
    return () => {
      mounted = false;
    };
  }, []);
  return (
    <div className="news_page_filter">
      {categories && (
        <>
          <div className="news_page_filter--wrapper">
            <span>{t("news.category")} : </span>
            <div className="select-filter">
              <select name="cars" id="cars" value={id} onChange={changeFilter}>
                <option value={0}>Tất cả</option>
                {categories.length &&
                  categories.map((cate) => (
                    <option key={cate.id} value={cate.id}>
                      {cate.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          {/* <PhoneFixed number={ PHONE_CONTACT } /> */}
        </>
      )}
    </div>
  );
};

export default Filters;
