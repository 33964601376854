import { TYPE } from '../actions/relate-information';

const defaultState = {
  dataList: [],
  sizeList: 0,
};

const relateInformation = (state = defaultState, action) => {
  switch (action.type) {
    case TYPE.SET_RELATE_INFORMATION: {
      return { ...state, dataList: action.payload };
    }
    default:
      return { ...state }
  }
}

export default relateInformation;
