import requester from '../requester';
import { GET_PROJECTS_URL, GET_FLOORS_URL, GET_DEPARTMENT_URL } from '../../constants';

const Project = {
  getProjects: () => requester.get(GET_PROJECTS_URL),
  getFloor: (id) => requester.get(GET_FLOORS_URL(id)),
  getDepartment: (id) => requester.get(GET_DEPARTMENT_URL(id)),
};

export default Project;
