import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Homepage from "../container/Home/index";
import Overview from "../container/Overview/overview";
import Project from "../container/Project/project";
import Login from "./../container/Login/index";
import Register from "./../container/Register/index";
import DetailProduct from "./../container/DetailProduct/index";
import Availability from "../container/Availability/availability";
import Location from "../container/Location";
import Team from "../container/Team/team";
import Contact from "../container/Contact/Contact";
import Compare from "./../container/Compare/compare";
import Amenities from "../container/Amenities";
import ScrollToTopWrapper from "../components/common/ScrollToTopWrapper";
import Layout from "../components/Layout";
import ViewFloor2D from "../container/Floor/ViewFloor2D";
import Apartment from "../container/Apartment";
import Design from "../container/Design";
import NewsPage from "../container/News";
import NewsDetailPage from "../container/News/Detail";
import NewCategory from "../container/News/NewsCategory";
import i18n from "../i18n/i18n";
import { DEFAULT_LANG } from "../constants";
import PageNotFound from "../container/NotFound";
import PageIntro from "../container/Intro";
// import PageInformation from "../container/Information";

class Routes extends Component {
  componentDidMount() {
    let i18nextLng = localStorage.getItem("i18nextLng");
    if (!i18nextLng) {
      localStorage.setItem("i18nextLng", DEFAULT_LANG);
    }

    i18n.changeLanguage(i18nextLng || DEFAULT_LANG);
  }

  render() {
    return (
      <>
        <ScrollToTopWrapper>
          <Layout>
            <Switch>
              <Route path="/" component={Homepage} exact />
              <Route path="/overview" component={Overview} exact />
              <Route path="/project" component={Project} exact />
              <Route path="/login" component={Login} exact />
              <Route path="/register" component={Register} exact />
              <Route path="/availability" component={Availability} exact />
              {/* <Route path="/location" component={Location} exact /> */}
              {/* <Route path="/floorplans*" component={Apartment} exact /> */}
              <Route path="/team" component={Team} exact />
              <Route path="/contact" component={Contact} exact />
              <Route path="/compare" component={Compare} exact />
              <Route
                path="/compare/detail_product"
                component={DetailProduct}
                exact
              />
              <Route path="/floorplans/2d" component={ViewFloor2D} exact />
              <Route path="/apartment/amenities" component={Amenities} exact />
              <Route path="/office/amenities" component={Amenities} exact />
              <Route path="/apartment/contact" component={Contact} exact />
              <Route path="/office/contact" component={Contact} exact />
              {/* <Route path="/news" exact component={NewsPage} /> */}
              <Route path="/apartment/news" exact component={NewsPage} />
              {/* <Route path="/news/detail/:id" exact component={NewsDetailPage} /> */}
              <Route path="/apartment/news/detail/:id" exact component={NewsDetailPage} />
              {/* <Route path="/news/:id" component={NewCategory} /> */}
              <Route path="/apartment/news/:id" component={NewCategory} />
              <Route path="/office/introduction" component={PageIntro} />
              <Route path="/apartment/introduction" component={PageIntro} />
              <Route path="/office/information" component={Apartment} />
              <Route path="/apartment/information" component={Apartment} />
              <Route path="/office/location" component={Location} />
              <Route path="/apartment/location" component={Location} />
              <Route path="/apartment/design" component={Design} exact />
              <Route path="/office/design" component={Design} exact />
              <Route component={PageNotFound} />
            </Switch>
          </Layout>
        </ScrollToTopWrapper>
      </>
    );
  }
}

export default Routes;
