import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import TagHead from "../../../components/TagHead";
import PhoneFixed from "../../../components/PhoneFixed";
import ImageBanner from "./ImageBanner";
import NewsRelated from "./NewsRelated";
import NewsContent from "./NewsContent";
import { getDataByParams } from "../../../utils";
import { GET_NEWS_DETAIL, PHONE_CONTACT } from "../../../constants";
import Footer from "../../../components/Footer";

const NewsDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [detail, setDetail] = useState({
    loading: true,
    data: null,
  });

  const fetchDataNewsDetail = () => {
    const url = GET_NEWS_DETAIL(id);
    getDataByParams(url, {
      limit: 3,
    }).then((res) => {
      setDetail({
        loading: false,
        data: res.data.data,
      });
    });
  };

  useEffect(() => {
    if (!detail.loading)
      setDetail({
        ...detail,
        loading: true,
      });
    fetchDataNewsDetail();
  }, [id]);

  return (
    <div className="news_detail_page">
      <TagHead title={t("news.title")} />
      {/* <PhoneFixed number={PHONE_CONTACT} /> */}
      {detail.loading ? (
        <Spinner animation="border" />
      ) : (
        <div className="fadeIn">
          <ImageBanner bannerImage={detail.data.news.thumbnail} />
          <NewsContent data={detail.data.news} />
          <NewsRelated
            data={detail.data.news_relations}
            idCategory={detail.data.news.category.id}
          />
        </div>
      )}
      {!detail.loading && <Footer />}
    </div>
  );
};

export default NewsDetail;
