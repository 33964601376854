export const TYPE = {
    SET_DESIGN_INFO_DATA: "SET_DESIGN_INFO_DATA",
  };
  export const setDesignInfoData = (data) => {
    return {
      type: TYPE.SET_DESIGN_INFO_DATA,
      payload: data,
    };
  };
  
  export const setDesignInfo = (data) => (dispatch) => {
      dispatch(setDesignInfoData(data));
  };