import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetLocation } from "../../hooks/location";
import MapsOverview from "./MapsOverview";
// import MenuMaps from "./MenuMaps";
import MapsOverviewMobile from "../Location/responsive/MapsOverviewContent";
import MapsNoteMobile from "../Location/responsive/MapsOverviewNote";
// import { PHONE_CONTACT } from "../../constants";
// import PhoneIcon from "../../assets/images/icons/path.svg";

const LocationOverview = () => {
  const { t } = useTranslation();
  const [location, loading] = useGetLocation(useLocation().pathname);
  const [categoryActive, setCategoryActive] = useState(-1);
  const isDesktop = window.innerWidth > 1024;

  const changeCategoryInMap = (id) => {
    setCategoryActive(id);
  };

  if (loading) return <div className="page-loading">{t("loading")} </div>;
  const { page, location: locationData, location_category } = location;
  return (
    <div className="location_overview">
      <div className="location_overview--content">
        {isDesktop && (
          <div className="desktop-screen">
            <div className="location-container">
              <div className="location-custom">
                <div className="maps_overview">
                  <MapsOverview
                    page={page}
                    data={location_category}
                    categoryActive={categoryActive}
                  />
                  {/* <MenuMaps
                    data={location_category}
                    dataText={page}
                    changeCategoryInMap={changeCategoryInMap}
                    categoryActive={categoryActive}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="mobile-desktop">
          <div className="maps_overview">
            <MapsOverviewMobile
              page={page}
              data={location_category}
              categoryActive={categoryActive}
            />
            <MapsOverview
              page={page}
              data={location_category}
              categoryActive={categoryActive}
            />
            <MapsNoteMobile
              data={location_category}
              categoryActive={categoryActive}
              changeCategoryInMap={changeCategoryInMap}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationOverview;
