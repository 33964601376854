import React from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useIntroductionDetail } from "../../hooks/introduction";
import { nl2ToDoubleBr } from "../../utils/helper";
// import bg_mock from "../../assets/images/mock-intro.jpeg";
import "./SectionDescription.scss";

const SectionDescription = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [data, loading] = useIntroductionDetail(location.pathname);

  if (loading) return <div className="page-loading">{t("loading")}</div>;

  return (
    <>
      {data?.title ? (
        <div className="page-intro">
          <div className="page-intro__text order-1 order-lg-0">
            {location.pathname.includes("office") && (
              <p className="home-index show-sub-title mt-2">
                <span>02</span>
                <span className="text-devider"></span>
                <span>{t("office.heading")}</span>
              </p>
            )}
            <h4 className="show-title" style={{ maxWidth: 435 }}>
              {data.title}
            </h4>
            <div
              className="show-scale-2"
              style={{ textAlign: "justify" }}
              dangerouslySetInnerHTML={{ __html: nl2ToDoubleBr(data.description) }}
            />
          </div>
          <div className="page-intro__img order-0 order-lg-1">
            <img src={data.image} alt="img" />
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default SectionDescription;
