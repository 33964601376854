import React from "react";
import HostSpot from "./HostSpot";
import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";
import Floor from "./Floor";
// import PhoneFixed from "../../components/PhoneFixed";
// import { PHONE_CONTACT } from "../../constants";
// import { showTitle } from "../../utils/helper";
import {
  // FLOOR_TYPE_NORMAL,
  // FLOOR_TYPE_TUM,
  FLOOR_BLOCK_TYPE,
} from "../../constants";
const ProjectInfo = (props) => {
  const { project } = props;
  const location = useLocation();
  const matchFloor = useRouteMatch(`${location.pathname}/:id`);
  // const matchFloor = useRouteMatch("/floorplans/:id");
  let numBlock = "1";
  const onImageLoad = () => {
    const elemt = document.querySelector(".apartment-infos");
    elemt.classList.add("show-info");
  };

  return (
    <>
      <section
        className={`relative  appartment-wrapper section apartment-infos`}
      >
        <div
          className="appartment"
          id={`${window.innerWidth >= 1025 && "appartment-view"}`}
        >
          <div id={`${window.innerWidth < 1025 && "appartment-view"}`}>
            {project && (
              <div
                className={`appartment-overview ${
                  matchFloor ? "floor-show" : ""
                }`}
                style={{
                  background: `url('${project.image}') no-repeat center center`,
                }}
              >
                {/* <PhoneFixed number={PHONE_CONTACT} /> */}
                <img
                  className="appartment-overview-image"
                  src={project.image}
                  alt={project.image}
                  onLoad={onImageLoad}
                />
                <div className="apartment-overview-content">
                  <div className="apartment-overview-full">
                    {location.pathname.includes("apartment")
                      ? project.floors
                          .filter((ele) => ele.type_of_real !== 2)
                          .map((item, index) => {
                            if (item.floor_type == FLOOR_BLOCK_TYPE) {
                              numBlock++;
                            }
                            return (
                              <HostSpot
                                data={item}
                                key={index}
                                numBlock={numBlock}
                              />
                            );
                          })
                      : project.floors
                          .filter(
                            (ele) =>
                              ele.type_of_real !== 1 || ele.floor_type !== 0
                          )
                          .filter((el) => el.id !== 8)
                          .filter((e) => e.id !== 14)
                          .map((item, index) => {
                            if (item.floor_type == FLOOR_BLOCK_TYPE) {
                              numBlock++;
                            }
                            return (
                              <HostSpot
                                data={item}
                                key={index}
                                numBlock={numBlock}
                              />
                            );
                          })}
                  </div>
                </div>
              </div>
            )}
          </div>
          <Switch>
            {/* <Route path="/floorplans/:id" component={Floor} exact /> */}
            <Route path="/apartment/information/:id" component={Floor} exact />
            <Route path="/office/information/:id" component={Floor} exact />
          </Switch>
        </div>
      </section>
    </>
  );
};

export default ProjectInfo;
