import React, { useState } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import NoteMaps from "./NotesMaps";
import NoteMapsAll from "./NoteMapsAll";
import ImgDefault from "../../assets/images/image-map-default.jpeg";

const ALL_CATEGORY = -1;
const ALL_LINES = [
  {
    id: 1,
    position:
      "638,5 638,20 624,40 616,58 612,91 605,146 588,204 579,251 575,314 572,358 566,391 558,433 554,458 554,476 558,485 564,496 751,706",
    scale: "1440,708",
  },
  {
    id: 2,
    position:
      "3,134 25,142 42,154 61,165 77,174 97,187 123,203 142,217 171,239 192,254 379,319 537,365 650,369 712,387 741,409 759,423 781,434 862,418 906,411 944,405 997,401 1007,402 1035,409 1077,420 1234,464 1262,461",
    scale: "1440,710",
  },
  {
    id: 3,
    position: "649,372 632,431 636,441 632,446 626,443 622,437 565,431",
    scale: "1440,707",
  },
  {
    id: 4,
    position: "638,409 571,402",
    scale: "1440,714",
  },
];

const MapsOverview = ({ page, data, categoryActive }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showSvg, setShowSvg] = useState(true);

  const { other_image } = page;
  const allItemNoteMaps = [];
  if (data) {
    if (categoryActive === ALL_CATEGORY) {
      data.map((note) => {
        note.places.map((item) => {
          allItemNoteMaps.push(
            <NoteMapsAll icon={note.icon} key={item.id} data={item} />
          );
        });
      });
    } else {
      data.map((note) => {
        if (note.id == "4") {
          note.places.map((item) => {
            if (item.main) {
              allItemNoteMaps.push(
                <NoteMaps icon={note.icon} key={item.id} data={item} />
              );
            }
          });
        }
        if (note.id === categoryActive) {
          note.places.map((item) => {
            allItemNoteMaps.push(
              <NoteMaps icon={note.icon} key={item.id} data={item} />
            );
          });
        }
      });
    }
  }

  const parsePoint = ({ x, y }, scale, container) => {
    const containerWidth = container?.offsetWidth || 0;
    const containerHeight = container?.offsetHeight || 0;

    let newScale = scale ? scale.split(",") : [1, 1];
    let startX = parseFloat(x);
    let startY = parseFloat(y);
    let oldWidth = parseFloat(newScale[0]);
    let oldHeight = parseFloat(newScale[1]);

    startX = (startX * containerWidth) / oldWidth;
    startY = (startY * containerHeight) / oldHeight;

    return { x: startX, y: startY };
  };

  const parsePolygon = ({ scale, position }) => {
    const container = document.getElementById("maps_overview");
    position = position.split(" ").map((o) => {
      let obj = o.split(",");
      return { x: obj[0], y: obj[1] };
    });

    return position
      .map((o) => {
        let point = parsePoint(o, scale, container);
        return `${point.x},${point.y}`;
      })
      .join(" ");
  };

  React.useEffect(() => {
    if (imageLoaded) {
      let mapContainer = document.getElementById("maps_overview");
      let svgContainer = document.getElementById("map-svg");
      svgContainer.setAttribute(
        "viewBox",
        `0 0 ${mapContainer?.offsetWidth} ${mapContainer?.offsetHeight}`
      );
    }
  }, [imageLoaded]);

  const onZoomChange = (e) => {
    let svgContainer = document.getElementById("map-svg");
    if (e.scale == 1) {
      svgContainer.classList.remove("d-none");
    } else {
      svgContainer.classList.add("d-none");
    }
  };

  return (
    <div className="maps_overview--maps">
      <div className="maps_overview--maps--wrapper">
        <TransformWrapper
          defaultScale={1}
          defaultPositionX={null}
          defaultPositionY={null}
          onZoomChange={onZoomChange}
          pan={{
            lockAxisX: true,
            lockAxisY: true,
          }}
          wheel={{
            disabled: true,
          }}
          doubleClick={{
            disabled: true,
          }}
        >
          {({ zoomIn, zoomOut, ...rest }) => (
            <React.Fragment>
              <div className="tools">
                <button onClick={zoomIn}>+</button>
                <button onClick={zoomOut}>-</button>
              </div>
              <TransformComponent>
                <div className="content" id="maps_overview">
                  <svg
                    className="map-svg"
                    id="map-svg"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 0 0"
                  >
                    {ALL_LINES.map((point) => (
                      <polyline
                        className="st-line"
                        points={parsePolygon(point)}
                      ></polyline>
                    ))}
                  </svg>
                  <img
                    src={other_image ? other_image : ImgDefault}
                    alt="test"
                    onLoad={() => setImageLoaded(true)}
                  />
                  {imageLoaded && (
                    <div className="maps_overview--maps_notes">
                      {allItemNoteMaps}
                    </div>
                  )}
                </div>
              </TransformComponent>
            </React.Fragment>
          )}
        </TransformWrapper>
      </div>
    </div>
  );
};

export default MapsOverview;
