import React from "react";
import Carousel from "react-multi-carousel";
import BeerSlide from "./BeerSlider";
import beforeImage from "../../assets/images/desgin/beforeImage.png";
import afterImage from "../../assets/images/desgin/afterImage.png";
import TopHeader from "../../components/TopHeader";

const DetailsCompare = (props) => {
  return (
    <div className="design-compare">
      <TopHeader showPhone={false} customClass="container contact" />
      <BeerSlide beforeImg={beforeImage} afterImage={afterImage} />
    </div>
  );
};

export default DetailsCompare;
