export const TYPE = {
    SET_USER: "SET_USER",
    CHECK_USER: "CHECK_USER"
}


export const setUser = (data) => {
    return{
        type: TYPE.SET_USER,
        payload: data
    }
}