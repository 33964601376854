import React, { useEffect, useState } from "react";
import i18n from "i18next";

import { getTakenData, getAPI_ADMIN_URL } from "../../utils";
import Loading from "../../components/Loading";

import { Events, animateScroll } from "react-scroll";
import MainOverview from ".//MainOverview";
import ScrollTop from "../../components/common/ScrollTop";
import FirstContent from "../../components/common/FirstContent";
import i18next from "i18next";

const Overview = () => {
  const [overview, setOverview] = useState({
    loading: true,
    data: [],
  });
  const [scroll, setScroll] = useState(0);
  const [isScroll, setIsScorll] = useState(true);
  const timeDuration = 1200;
  let wheelOpt = supportsPassive ? { passive: false } : false;
  let wheelEvent =
    "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";
  let keys = { 37: 1, 38: 1, 39: 1, 40: 1 };
  const preventDefault = (e) => {
    e.preventDefault();
  };
  const preventDefaultForScrollKeys = (e) => {
    if (keys[e.keyCode]) {
      preventDefault(e);
      return false;
    }
  };

  var supportsPassive = false;
  useEffect(() => {
    document.body.style.overflow = "hidden";
    var bodyRect = document.body.getBoundingClientRect();
    setScroll(parseInt(-bodyRect.top / window.innerHeight));
    window.addEventListener("DOMMouseScroll", preventDefault, {
      capture: false,
      passive: false,
    }); // older FF
    window.addEventListener(wheelEvent, preventDefault, {
      capture: wheelOpt,
      passive: wheelOpt,
    }); // modern desktop
    window.addEventListener("touchmove", preventDefault, {
      capture: wheelOpt,
      passive: wheelOpt,
    }); // mobile
    window.addEventListener("keydown", preventDefaultForScrollKeys, {
      capture: false,
      passive: false,
    });
    return () => {
      window.removeEventListener("DOMMouseScroll", preventDefault, {
        capture: false,
        passive: false,
      }); // older FF
      window.removeEventListener(wheelEvent, preventDefault, {
        capture: wheelOpt,
        passive: wheelOpt,
      }); // modern desktop
      window.removeEventListener("touchmove", preventDefault, {
        capture: wheelOpt,
        passive: wheelOpt,
      }); // mobile
      window.removeEventListener("keydown", preventDefaultForScrollKeys, {
        capture: false,
        passive: false,
      });
    };
  }, []);

  const displayBtnToTop = (page) => {
    const btnToTopElm = document.querySelector(".top-arrow");
    if (page == 0 && btnToTopElm) {
      btnToTopElm.style.transform = "scale(0)";
      return;
    }
    if (page > 0 && btnToTopElm) {
      btnToTopElm.style.transform = "scale(1)";
      return;
    }
    return;
  };

  const mouseDown = (e) => {
    const h = window.innerHeight;
    if (isScroll) {
      setIsScorll(false);
      if (e.deltaY < 0) {
        if (scroll !== 0) {
          setScroll(scroll - 1);
          displayBtnToTop(scroll - 1);
          animateScroll.scrollTo(h * (scroll - 1), {
            duration: timeDuration,
            smooth: true,
            delay: 0,
          });
          Array.from(Array(overview.data.length)).map((item) => {
            if (parseInt(scroll - 1) === item) {
              const element = document.getElementById(`target-${item}`);
              const img = document.getElementById(`img-target-${item}`);
              if (img) {
                img.style.animationName = `mainText1`;
                img.style.animationDuration = "3s";
              }
              element.style.animationName = `mainText1`;
              element.style.animationDuration = "3s";
            } else {
              const element = document.getElementById(`target-${scroll}`);
              const img = document.getElementById(`img-target-${item}`);
              if (img) {
                img.style.animationName = "mainText2";
              }
              element.style.animationName = "mainText2";
            }
          });
        }
        setTimeout(() => {
          setIsScorll(true);
        }, timeDuration);
      } else if (e.deltaY > 0) {
        if (scroll !== 4) {
          setScroll(scroll + 1);
          displayBtnToTop(scroll + 1);
          Array.from(Array(overview.data.length)).map((item) => {
            if (parseInt(scroll + 1) === item) {
              const element = document.getElementById(`target-${item}`);
              const img = document.getElementById(`img-target-${item}`);
              if (img) {
                img.style.animationName = `mainText1`;
                img.style.animationDuration = "3s";
              }
              element.style.animationName = `mainText1`;
              element.style.animationDuration = "3s";
            } else {
              const element = document.getElementById(`target-${scroll}`);
              const img = document.getElementById(`img-target-${item}`);
              if (img) {
                img.style.animationName = "mainText2";
              }
              element.style.animationName = "mainText2";
            }
          });
          animateScroll.scrollTo(h * (scroll + 1), {
            duration: timeDuration,
            smooth: true,
            delay: 0,
          });
        }
        setTimeout(() => {
          setIsScorll(true);
        }, timeDuration);
      }
    }
  };

  const scrollTop = () => {
    if (isScroll) {
      setIsScorll(false);
      setScroll(0);
      displayBtnToTop(0);
      animateScroll.scrollTo(0, {
        duration: timeDuration,
        smooth: true,
        delay: 0,
      });
    }

    setTimeout(() => {
      setIsScorll(true);
    }, timeDuration);
  };

  const getDataOverview = () => {
    if (!overview.loading)
      setOverview({
        ...overview,
        loading: true,
      });
    getTakenData(getAPI_ADMIN_URL("/api/v1/overviews"), i18next.language)
      .then((res) => {
        setOverview({
          loading: false,
          data: res.data.data,
        });
      })
      .catch((error) => {
        if (error) alert("something was wrong please try again later!");
      });
  };
  useEffect(() => {
    // get Data

    //restart to top
    if (isScroll) {
      setIsScorll(false);
      setScroll(0);
      displayBtnToTop(0);
      animateScroll.scrollTo(0, {
        duration: 0,
        smooth: true,
        delay: 0,
      });
    }
    setTimeout(() => {
      setIsScorll(true);
    }, timeDuration);
  }, []);

  useEffect(() => {
    getDataOverview();
  }, [i18n.language]);

  if (overview.loading || overview.data.length === 0) return <Loading />;
  return (
    <>
      {overview.data &&
        overview.data.map((view, index) => {
          if (index === 0) {
            return (
              <FirstContent
                bg={view.background}
                box={true}
                key={view.id}
                id={`target-${0}`}
                onWheel={mouseDown}
                scrollTop={scrollTop}
                title={view.title}
                text={view.description}
                button="REgister now"
                type="card"
              />
            );
          } else {
            return (
              <MainOverview
                bg={view.background}
                key={view.id}
                sliders={view.sliders.length !== 0 ? view.sliders : null}
                order={view.order ? view.order : index + 1}
                id={`target-${index}`}
                title={view.title}
                text={view.description}
                button={`view ${view.name}`}
                onWheel={mouseDown}
                link={"/"}
              />
            );
          }
        })}
      <ScrollTop scrollTop={scrollTop} />
    </>
  );
};

export default Overview;
