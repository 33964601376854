import React from "react";
import { PHONE_CONTACT } from "../constants";
import PhoneIcon from "../assets/images/icons/path.svg";
import { showTitle } from "../utils/helper";
const TopHeader = ({ tag, title, showPhone, customClass }) => {
  return (
    <div className={`top-header contact-phone ${customClass} show-title`}>
      {tag && (
        <div className="tag">
          <h3
            dangerouslySetInnerHTML={{
              __html: tag,
            }}
          ></h3>
        </div>
      )}
      {title && <h3>{title}</h3>}
      {showPhone && (
        <div className="phone">
          <a href={`tel:${PHONE_CONTACT.replace(/\s+/g, "")}`}>
            <img src={PhoneIcon} alt="Icon Phone" />
            <span>{PHONE_CONTACT}</span>
          </a>
        </div>
      )}
    </div>
  );
};

export default TopHeader;
