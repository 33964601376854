import { TYPE } from "../actions/page";

const defaultState = {
  title: 'Epic Tower',
  description: ''
};

const page = (state = defaultState, action) => {
  switch (action.type) {
    case TYPE.SET_PAGE_INFO_DATA: {
      return { ...state, 
        title: action.payload.title,
        description: action.payload.description
      };
    }
    default:
      return { ...state };
  }
};

export default page;
